import { FlexItemsCenter } from "style/preset";
import styled, { useTheme } from "styled-components";
import { ReactComponent as FeesSvg } from "assets/svgs/icons-misc-fees.svg";
import { ReactComponent as GasSvg } from "assets/svgs/icons-misc-gas.svg";
import StyledContainer from "style/styledContainer";
import { ReactNode } from "react";
import { ReactComponent as WarningSvg } from 'assets/svgs/icons-misc-warning.svg'
import { useSystemInfo } from "hooks/useSystemProvider";

interface Props {
  children?: ReactNode;
  isGas: boolean;
  value?: string;
  suffix?: string;
}

const labelList = ['Estimated gas', 'Estimated slippage']

const StatusBar = ({ children, isGas, value = '', suffix=''}: Props) => {
  const theme = useTheme()
  const { isMobile } = useSystemInfo()

  const checkSlippage = () => {
    const tempVal = +value
    if(tempVal > 5) {
      return true
    }
    return false
  }

  const getColor = () => {
    if(isGas || !checkSlippage()) {
      return ''
    } else {
      return '#ef233c'
    }
  }
  
  return (
    <FlexItemsCenter>
      <StatusBox flex1="1">
        <ImgBox color={getColor()}>
          {isGas ? (
            <GasSvg fill={theme.basicBlack}></GasSvg>
          ) : (
            <FeesSvg fill={ checkSlippage() ? '#ef233c' : theme.basicBlack}></FeesSvg>
          )}
        </ImgBox>
        <StyledContainer flex column flex1="1">
          <FlexItemsCenter justifyContent="flex-start !important">
            <Label color={getColor()}>{labelList[+(!isGas)]}</Label>
            {
              !isGas && !isMobile && checkSlippage() && <WarningSvg></WarningSvg>
            }
          </FlexItemsCenter>
          <Value color={getColor()}>
            <span>{`${value ? (value + suffix) : ''}` || 'N/A'}</span>
            {children}
          </Value>
        </StyledContainer>
      </StatusBox>
      {
        !isGas && isMobile && checkSlippage() && <WarningSvg></WarningSvg>
      }
    </FlexItemsCenter>
  );
};

const StatusBox = styled(StyledContainer)`
  display: flex;
  align-items: center;
`;

const ImgBox = styled.div<any>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.basicGrey};
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({color, theme}) => {
    if(color) {
      return `
        background-color: transparent;
        border: 1px solid ${color};
      `
    }
  }};
`;

const Label = styled.span<any>`
  color: ${({color, theme}) => color || theme.seniorGrey};
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
`;

const Value = styled.div<any>`
  color: ${({color, theme}) => color || theme.basicBlack};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

export default StatusBar;
