import { PopupWinBg } from "style/preset";
import styled, { useTheme } from "styled-components";

import { ReactComponent as AnyswapSvg } from "assets/svgs/chainge-symbol.svg";
import OrchidLogo from "assets/images/orchid_logo.png";
import StyledText from "style/styledText";
import { PulseLoader } from "react-spinners";
import { StyleProps } from "types/baseType";

type Props = {
  loading: boolean;
  text?: string;
} & StyleProps;

const LoadingWin = ({ loading, text = "Hold on, getting data", mt }: Props) => {
  const theme = useTheme()

  return (
    <>
      {loading && (
        <LoadingBox>
          {/* <AnyswapSvgWrapper stroke={theme.loadingColor} mt={mt}></AnyswapSvgWrapper> */}
          <LogoIcon src={OrchidLogo}></LogoIcon>
          <StyledText p="5px 0" fontSize="12px" interRegular color={theme.loadingColor}>
            Hold on, getting data
          </StyledText>
          {/* <StyledText p="5px 0" fontSize="12px" interRegular color={theme.loadingColor}>
            请耐心等待30秒，勿切换页面
          </StyledText> */}
          <StyledText p="5px 0" fontSize="12px" interRegular color={theme.loadingColor}>
            Please wait 30 seconds, do not switch the page
          </StyledText>
          <PulseLoader
            color={theme.loadingColor}
            cssOverride={{}}
            loading={true}
            margin={5}
            size={6}
            speedMultiplier={1}
          />
        </LoadingBox>
      )}
    </>
  );
};

const LoadingBox = styled(PopupWinBg)`
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({theme}) => theme.loadingBg};
  z-index: 1000;
`;

const AnyswapSvgWrapper = styled(AnyswapSvg)<StyleProps>`
  width: 52px;
  height: 56px;

  margin-top: ${({ mt }) => mt || null};
`;

const LogoIcon = styled.img`
  width: 52px;
`

export default LoadingWin;
