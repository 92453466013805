import { basicWhite, FlexCenter } from "style/preset";
import styled from "styled-components";

import { ReactComponent as DaySvg } from "assets/svgs/icons-misc-day_2.svg";
import { ReactComponent as NightSvg } from "assets/svgs/icons-misc-night.svg";
import { useSystemInfo } from "hooks/useSystemProvider";

const ThemeBtn = () => {
  const { updateTheme , theme } = useSystemInfo()
  return (
    <ThemeBtnBox onClick={updateTheme}>
      {
        theme === 'day' && <NightSvgWrapper></NightSvgWrapper>
      }
      {
        theme === 'dark' && <DaySvgWrapper fill={basicWhite}></DaySvgWrapper>
      }
    </ThemeBtnBox>
  );
};

const ThemeBtnBox = styled(FlexCenter)`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.basicWhite};
  cursor: pointer;
  margin-left: 12px;
  border: 0.5px solid transparent;
  &:hover {
    box-shadow: 3px 12px 30px 0 ${({theme}) => theme.cardBoxShadow};
    border: solid 0.5px ${({theme}) => theme.hoverBorderColor};
  }
`;

const svgStyled = `width: 18px; height: 18px;`;
const DaySvgWrapper = styled(DaySvg)`
  ${svgStyled}
`;
const NightSvgWrapper = styled(NightSvg)`
  ${svgStyled}
`;

export default ThemeBtn;
