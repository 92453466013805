import { BigNumber as BigNumberEther, ethers } from "ethers";
import BigNumber from "bignumber.js";
import b58 from "b58";
import { getCurProvider } from "./provider";

export const toLowerCase = (str: string) => {
  return str.toLowerCase();
};

export const shortStr = (str: string, sNum: number, eNum?: number) => {
  return str.substr(0, sNum) + "..." + str.substr(-(eNum || sNum));
};

export const getBigNumber = (number: any) => {
  try {
    return BigNumberEther.from(number);
  } catch (error) {
    return "";
  }
};

export const comparationBigNumber = (
  numA: any,
  numB: any,
  type: "gt" | "gte" | "eq" | "lt" | "lte"
) => {
  const numABig = new BigNumber(numA);
  const numBBig = new BigNumber(numB);
  if (!numABig || !numBBig) return new Error("error");
  if (type === "gt") {
    return numABig.gt(numBBig);
  }
  if (type === "gte") {
    return numABig.gte(numBBig);
  }
  if (type === "eq") {
    return numABig.eq(numBBig);
  }
  if (type === "lt") {
    return numABig.lt(numBBig);
  }
  if (type === "lte") {
    return numABig.lte(numBBig);
  }
};

export const b58ToBuffer = (b58Str: string) => {
  return b58.decode(b58Str);
};

export const b58ToHex = (b58Str: string) => {
  const bufferInfo = b58ToBuffer(b58Str);
  return bufTohex(bufferInfo);
};

export const bufTohex = (buffer: any) => {
  return [...(new Uint8Array(buffer) as any)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");
};

export const stringToBuffer = (string: string) => {
  let arrayBuffer = new ArrayBuffer(string.length * 1);
  let newUint = new Uint8Array(arrayBuffer);
  newUint.forEach((_, i) => {
    newUint[i] = string.charCodeAt(i);
  });
  return newUint;
};

export const signMsgByEvm = async (msg: string, curProvider: any) => {
  const provider = new ethers.providers.Web3Provider(curProvider)
  const signer = provider.getSigner()
  const result = await signer.signMessage(msg)
  return result
}

export const formatNum = (num: number | string, decimal?: number) => {
  num = +num as number
  const options = {maximumFractionDigits: decimal || 20};
  const formatNumStr = num.toLocaleString(undefined, options)
  return formatNumStr
}

export const reloadWin = () => {
  const info = (window as any).navigator.userAgent.toLocaleLowerCase()
  if(info.indexOf('metamaskmobile') > -1 || info.indexOf('tokenpocket') > -1) {
    (window as any).location.reload()
  }
}

export const getSearchParams = (key: string, params: string) => {
  params = params.replace('/?', '')
  const paramsList = params.split('&')
  const curForKey = paramsList.find((item: string) => item.includes(key))
  if(curForKey) {
    return curForKey.replace(`${key}=`, '')
  }
  return ''
}