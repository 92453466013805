import { BaseLabel, FlexCenter } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled from "styled-components"

import { ReactComponent as DownSvg } from 'assets/svgs/icons-arrows-top-01.svg'
import CImage from "components/CImage"
import { AssetTpye, DefObj } from "types/baseType"
import { Tooltip } from "react-tooltip"


type Props = {
    type: AssetTpye
    label: string
    name: string
    url: string
    meta?: DefObj<string, any>
    address?: string
    fullName?: string
    onClick?: (type: AssetTpye, meta?: DefObj<string, any>) => void
}

const CSelect = ({type, name, url, meta, fullName = '', address = '', label, onClick}: Props) => {
    return <>
        <SelectBox onClick={() =>  onClick && onClick(type, meta)} id={(type==='symbol'?'symbol':'') + address}>
            <StyledContainer flex1="1" flex alignItems="center">
                <BaseLabel>{label}:</BaseLabel>
                {
                    url ?  <CImage src={url} width="22px" height="22px" mr="10px"></CImage> : <StyledContainer width="22px" height="22px" mr="10px"></StyledContainer>
                }
                <StyledContainer fontWeight5 interMedium flex column justifyContent="center">
                    <StyledContainer fontSize="14px">{name || 'Please select'}</StyledContainer>
                </StyledContainer>
            </StyledContainer>
            <FlexCenter>
                {
                    type === 'symbol' && fullName && <StyledContainer fontSize="12px" mr="20px">{fullName}</StyledContainer>
                }
                <DownSvgWrapper></DownSvgWrapper>
            </FlexCenter>
        </SelectBox>
        {
            type === 'symbol' && address && <Tooltip place="bottom" anchorSelect={'#symbol'+address} clickable>
                {address}
            </Tooltip>
        }
        
    </>
}

const SelectBox = styled(StyledContainer).attrs(() => ({ tabIndex: 0 }))`
    width: 100%;
    height: 44px;
    padding: 12px;
    padding-right: 15px;
    background-color: ${({theme}) => theme.basicGrey};
    border-radius: 9px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    box-sizing: border-box;

    flex: 1;

    &:hover {
        background-color: ${({theme}) => theme.lightGrey};
    }
`

const DownSvgWrapper = styled(DownSvg)`
    width: 12px;
    height: 12px;
    fill: ${({theme}) => theme.basicBlack};
    transform: rotate(180deg);
`

export default CSelect