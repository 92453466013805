import PcRouter from './pc_router'
import MobileRouter from './mobile_router'
import { useEffect } from 'react'
import { useSystemInfo } from 'hooks/useSystemProvider'

const Routers = () => {
    const { isMobile, updateMobile } = useSystemInfo()
    useEffect(() => {
        const handleResize = () => {
            const innerWidth = window.innerWidth
            if(innerWidth <= 800) {
                updateMobile(true)
            } else {
                updateMobile(false)
            }
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [updateMobile])

    return isMobile ? <MobileRouter></MobileRouter> : <PcRouter></PcRouter>
}

export default Routers