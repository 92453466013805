import { ReactNode, useCallback, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { FlexItemsCenter } from "style/preset";
import StyledContainer from "style/styledContainer";
import { StyleProps } from "types/baseType";
import { ReactComponent as WarningSvg } from 'assets/svgs/icons-misc-warning.svg'
import { comparationBigNumber } from "utils";
import styled from "styled-components";
import { useSystemInfo } from "hooks/useSystemProvider";

type Props = {
  type: string;
  value?: string;
  symbol?: string;
  fromSymbol?: string;
  toSymbol?: string;
  fromAmount?: string;
  toAmount?: string;
  loading?: boolean;
  bgColor?: string;
  maxClick?: () => void;
} & StyleProps;

const BalanceRate = ({
  value = "",
  type = "balance",
  fromSymbol,
  toSymbol,
  fromAmount,
  toAmount,
  loading = false,
  bgColor,
  mt,
  p,
  maxClick,
}: Props) => {
  const [rateStr, setRateStr] = useState<ReactNode | null>(null);
  const [direction, setDirection] = useState("from");
  const { isMobile } = useSystemInfo()

  const fnRateSwitch = useCallback(() => {
    const tempFromAmount = +(fromAmount || 0);
    const tempToAmount = +(toAmount || 0);
    setRateStr(<>N/A</>);
    if (!tempFromAmount || !tempToAmount || !fromSymbol || !toSymbol) return;
    let tempRateStr = <></>;
    if (direction === "from") {
      let fromToRate = 0;
      if (tempFromAmount > 1) {
        fromToRate = tempToAmount / tempFromAmount;
      } else {
        fromToRate = (1 / tempFromAmount) * tempToAmount;
      }
      tempRateStr = (
        <>
          1 {fromSymbol} ={" "}
          <NumericFormat
            displayType="text"
            value={fromToRate}
            thousandSeparator=","
          />{" "}
          {toSymbol}
        </>
      );
      if(['USDT.e', 'USDT'].includes(fromSymbol) && ['USDT.e', 'USDT'].includes(toSymbol)) {
        tempRateStr = <>1 USDT.e = 1 USDT</>
      }
    } else {
      let toFromRate = 0;
      if (tempToAmount > 1) {
        toFromRate = tempFromAmount / tempToAmount;
      } else {
        toFromRate = (1 / tempToAmount) * tempFromAmount;
      }
      tempRateStr = (
        <>
          1 {toSymbol} ={" "}
          <NumericFormat
            displayType="text"
            value={toFromRate}
            thousandSeparator=","
          />{" "}
          {fromSymbol}
        </>
      );
      if(['USDT.e', 'USDT'].includes(fromSymbol) && ['USDT.e', 'USDT'].includes(toSymbol)) {
        tempRateStr = <>1 USDT = 1 USDT.e</>
      }
    }
    if(fromSymbol === toSymbol) {
      return
    }
    setRateStr(tempRateStr);
  }, [fromAmount, toAmount, direction, fromSymbol, toSymbol]);

  const fnSwitchDirection = () => {
    if (isBalance()) return;
    if (direction === "from") {
      setDirection("to");
    } else {
      setDirection("from");
    }
  };

  useEffect(() => {
    fnRateSwitch();
  }, [direction, fnRateSwitch]);

  const isBalance = () => {
    return type === "balance";
  };

  const checkBalance = () => {
    if(comparationBigNumber(fromAmount, value, "gt")) {
      return true
    }
    return false
  }

  return (
    <FlexItemsCenterWrapper
      p={p || "10px"}
      borderRadius="6px"
      mt={mt || "15px"}
      backgroundColor={`${bgColor} !important` || ''}
      justifyContent="space-between"
      onClick={fnSwitchDirection}
    >
      <StyledContainer
        fontSize="12px"
        flex
        alignItems="center"
        pointer={!isBalance()}
      >
        <StyledContainer interSemiBold fontWeight6>
          {isBalance() ? "Balance:" : "Rate:"}
        </StyledContainer>
        <StyledContainer interSemiBold={!isMobile} interRegular={isMobile} ml="5px">
          {!loading && (
            <>
              {isBalance() &&
                (value && fromSymbol ? (
                  <>
                    <NumericFormat
                      displayType="text"
                      value={value}
                      thousandSeparator=","
                    />{" "}
                    {fromSymbol}
                  </>
                ) : (
                  "N/A"
                ))}
              {!isBalance() && (rateStr ? rateStr : "N/A")}
            </>
          )}
          {
            loading && "loading..."
          }
        </StyledContainer>

        {
          isBalance() && checkBalance() && <WarningSvg style={{marginLeft: '8px'}}></WarningSvg>
        }
      </StyledContainer>
      {!loading && isBalance() && value && (
        <MaxLable
          fontSize="12px"
          pointer
          flex
          alignItems="center"
          fontWeight6
          onClick={() => maxClick && maxClick()}
        >
          Max
        </MaxLable>
      )}
    </FlexItemsCenterWrapper>
  );
};

const FlexItemsCenterWrapper = styled(FlexItemsCenter)`
  background-color: ${({theme}) => theme.basicGrey};
  justify-content: space-between;
`
const MaxLable = styled(StyledContainer)`
  color: ${({theme}) => theme.basicGreen};
`

export default BalanceRate;
