import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { WagmiConfig } from "wagmi";
import useEvmWallet from "./walles/useEvmWallet";
import useNearWallet from "./walles/useNearWallet";
import "@near-wallet-selector/modal-ui/styles.css";
import { WalletSelectorEvents } from "@near-wallet-selector/core";
import { CurWalletObj } from "types/baseType";

interface WalletProps {
  nearWalletInfo: CurWalletObj | null
  walletWin: boolean;

  openWalletWin: () => void;
  closeWalletWin: () => void;

  openNearWin: () => void;
  closeNearWin: () => void;
}

const WalletContext = createContext<WalletProps | null>(null);

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const wagmiClient = useEvmWallet();
  const { nearWalletSelector, signIn, signOut, initNearWallet } =
    useNearWallet();

  const [walletWin, setWalletWin] = useState(false);
  const [nearWalletInfo, setNearWalletInfo] = useState<CurWalletObj | null>(null);

  const openWalletWin = () => {
    setWalletWin(true);
  };
  const closeWalletWin = () => {
    setWalletWin(false);
  };

  const updateNearWalletInfo = useCallback((isConnected: boolean, address: string, wallet: any) => {
    setNearWalletInfo({
      userAddress: address,
      isConnected: isConnected,
      wallet: wallet,
      nearWalletSelector: nearWalletSelector
    })
  }, [nearWalletSelector])

  const removeNearWalletInfo = useCallback(() => {
    setNearWalletInfo(null)
  }, [])

  // --------------------- near wallet start -------------------------------------------
  const getWalletForNear = useCallback(async () => {
    const wallet = await nearWalletSelector?.wallet()
    const address = nearWalletSelector?.store.getState().accounts[0].accountId as string;
    updateNearWalletInfo(true, address, wallet)
  }, [nearWalletSelector, updateNearWalletInfo])
  
  const nearSignedInEvent = (event:  WalletSelectorEvents["signedIn"]) => {
    if(event.walletId === "sender") {
      getWalletForNear()
      window.location.reload()
    }
  };

  const nearSignedOutEvent = (event: WalletSelectorEvents["signedOut"]) => {
    removeNearWalletInfo()
    initNearWallet();
  };

  const nearAccountsChanged = (event: WalletSelectorEvents["accountsChanged"]) => {
    console.log('accountsChanged:')
  };

  useEffect(() => {
    if (nearWalletSelector) {
      const isSignedIn = nearWalletSelector.isSignedIn()
      if(isSignedIn) {
        getWalletForNear()
      }
      nearWalletSelector.on("signedIn", nearSignedInEvent)
      nearWalletSelector.on("signedOut", nearSignedOutEvent)
      nearWalletSelector.on("accountsChanged", nearAccountsChanged)      
    }

    return () => {
      if(nearWalletSelector) {
        nearWalletSelector.off("signedIn", nearSignedInEvent)
        nearWalletSelector.off("signedOut", nearSignedOutEvent)
        nearWalletSelector.off("accountsChanged", nearAccountsChanged)
      }
    }
  }, [getWalletForNear, nearWalletSelector]);

  // open near wallet list
  const openNearWin = () => {
    signIn();
  };

  const closeNearWin = async () => {
    await signOut();
  };
  // --------------------- near wallet end -------------------------------------------

  return (
    <WalletContext.Provider
      value={{
        walletWin,
        nearWalletInfo,
        openWalletWin,
        closeWalletWin,
        openNearWin,
        closeNearWin
      }}
    >
      {wagmiClient ? (
        <WagmiConfig config={wagmiClient}>{children}</WagmiConfig>
      ) : (
        <></>
      )}
    </WalletContext.Provider>
  );
};

export const useWalletInfo = () => {
  return (
    useContext(WalletContext) || {
      walletWin: false,
      nearWalletInfo: null,
      openWalletWin: () => {},
      closeWalletWin: () => {},
      openNearWin: () => {},
      closeNearWin: () => {}
    }
  );
};
