import { Chain, TokenVo } from "@chainge/sdk";
import useDebounce from "hooks/useDebounce";
import { useSystemInfo } from "hooks/useSystemProvider";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AssetTpye, DefObj } from "types/baseType";
import { toLowerCase } from "utils";
import BasePopup from "../BasePopup";
import SearchAsset from "./components/SearchAsset";
import SearchChain from "./components/SearchChain";
import SearchHeader from "./components/SearchHeader";

type Props = {
  onOpenChange?: (state: boolean) => void;
  onChosed?: (type: AssetTpye, data: Chain | TokenVo) => void;
  open: boolean;
  type: AssetTpye; // chain symbol
  chainName?: string;
  symbolName?: string;
  direction: string;
};

const AssetPopupWin = ({
  onOpenChange,
  open,
  type,
  chainName,
  symbolName,
  onChosed,
  direction
}: Props) => {
  const [localOpen, setLocalOpen] = useState(false);
  const {
    chains = [],
    tokens = {},
    allTokens = [],
    sortTokens = {},
  } = useSystemInfo();

  // The pop-up is closed and the local data is updated
  const onClosePopup = () => {
    setLocalOpen(false);
  };

  // The pop-up is opened to initialize local data
  useEffect(() => {
    if (open) {
      setLocalOpen(open);
    }
  }, [open]);

  useEffect(() => {
    onOpenChange && onOpenChange(localOpen);
  }, [localOpen, onOpenChange]);

  const getSortAsset = useCallback(
    (filter: string) => {
      let symbolList = tokens[filter] || [];
      const symbolListForSort = sortTokens[filter] || [];
      if (symbolList.length && symbolListForSort.length) {
        const newAssets = symbolList.filter(
          (item) => !symbolListForSort.includes(item.symbol)
        );
        const sortDataList = [] as TokenVo[];
        symbolListForSort.forEach((item) => {
          const sortItem = symbolList.find(
            (orgItem) => orgItem.symbol === item
          );
          if (sortItem) {
            sortDataList.push(sortItem);
          }
        });
        symbolList = [...sortDataList, ...newAssets];
      }

      return symbolList;
    },
    [sortTokens, tokens]
  );

  const [data, setData] = useState<Chain[] | TokenVo[]>([]);
  const [backupData, setBackupData] = useState<Chain[] | TokenVo[]>([]);
  const formatChainData = useCallback(
    (filter?: string) => {
      let tempResult: Chain[] = [];
      if (filter) {
        // This section of logic abandons
        // const symbolList = allTokens.filter(item => item.symbol === filter)
        // const nameList = symbolList.map(item => item.chain)
        // const resultChains = chains.filter(item => nameList.includes(item.name))
        // tempResult = resultChains
      } else {
        tempResult = chains;
      }
      setData(tempResult);
      setBackupData(tempResult);
    },
    [chains]
  );

  const formatSymbolData = useCallback(
    (filter?: string) => {
      let tempResult: TokenVo[] = [];
      if (filter) {
        tempResult = getSortAsset(filter);
      } else {
        // This section of logic abandons
        // tempResult = defaultTokens
      }
      const firstLoad = tempResult.slice(0, 20);
      setData(firstLoad);
      setBackupData(tempResult);
    },
    [getSortAsset]
  );

  const fnLoadMoreForAsset = (status: boolean) => {
    if (filterStr) return;
    const length = (data as TokenVo[]).length;
    const tempNewDataSub = (backupData as TokenVo[]).slice(length, length + 20);
    const newDataAry = [...(data as TokenVo[]), ...tempNewDataSub];
    setData(newDataAry);
  };

  const checkChain = useCallback(() => {
    return type === "chain";
  }, [type]);

  useEffect(() => {
    if (localOpen) {
      if (checkChain()) {
        // formatChainData(symbolName)
        formatChainData();
      } else {
        formatSymbolData(chainName);
      }
    } else {
      setData([]);
      setBackupData([]);
    }
  }, [chainName, checkChain, formatChainData, formatSymbolData, localOpen]);

  const [filterStr, setFilterStr] = useState<string>("");
  const fnFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterStr(value);
  };

  /**
   * Search according to filter
   */
  useDebounce(
    () => {
      const filterVal = toLowerCase(filterStr);
      if (checkChain()) {
        let tempData: Chain[] = [];
        if (filterVal) {
          tempData = (backupData as Chain[]).filter((item) => {
            return (
              toLowerCase(item.name).includes(filterVal) ||
              toLowerCase(item.fullName).includes(filterVal)
            );
          });
          if (!tempData.length) {
            tempData = (chains as Chain[]).filter((item) => {
              return (
                toLowerCase(item.name).includes(filterVal) ||
                toLowerCase(item.fullName).includes(filterVal)
              );
            });
          }
        } else {
          tempData = backupData as Chain[];
        }
        setData(tempData);
      } else {
        let tempData: TokenVo[] = [];
        if (filterVal) {
          // Retrieve from the list of existing assets
          tempData = (backupData as TokenVo[]).filter((item) => {
            return (
              toLowerCase(item.name).includes(filterVal) ||
              toLowerCase(item.symbol).includes(filterVal)
            );
          });

          // If the default asset list has no matching data, search the entire list
          if (!tempData.length && !chainName) {
            tempData = (allTokens as TokenVo[]).filter((item) => {
              return (
                toLowerCase(item.name).includes(filterVal) ||
                toLowerCase(item.symbol).includes(filterVal)
              );
            });
            // Get rid of assets with the same name
            const tempDataObj: DefObj<string, TokenVo> = {};
            tempData.forEach((item) => {
              tempDataObj[item.symbol] = item;
            });
            tempData = Object.values(tempDataObj);
          }
        } else {
          tempData = backupData as TokenVo[];
        }
        setData(tempData);
      }
    },
    300,
    [filterStr]
  );

  /**
   * Select and return assets
   */
  const fnOnChosed = (detail: Chain | TokenVo) => {
    if (onChosed) {
      if(type === 'symbol') {
        // direction === 'to' && 
        // if((detail as TokenVo).symbol === 'METIS') {
        //   toast.error("Cross chain for token METIS is temporarily out of service.")
        //   return;
        // }
      }
      onChosed(type, detail);
      onClosePopup();
    }
  };

  return (
    <>
      {localOpen && (
        <BasePopup
          onClose={onClosePopup}
          title={`Select ${checkChain() ? 'a network' : 'an asset'}`}
          extraNode={<SearchHeader onFilterChange={fnFilterChange}></SearchHeader>}
          extraStyle={`height: 143px; margin-bottom: 0px;`}
        >
          {!checkChain() && (
            <SearchAsset
              onChosed={fnOnChosed}
              allTokenData={backupData as TokenVo[]}
              dataList={data as TokenVo[]}
              loadMore={fnLoadMoreForAsset}
            ></SearchAsset>
          )}
          {checkChain() && (
            <SearchChain
              onChosed={fnOnChosed}
              dataList={data as Chain[]}
            ></SearchChain>
          )}
        </BasePopup>
      )}
    </>
  );
};
export default AssetPopupWin;
