import { FlexCenter } from "style/preset";
import styled, { useTheme } from "styled-components";
import { ReactComponent as LockSvg } from "assets/svgs/icon-btn-lock.svg";
import { ReactComponent as AddSvg } from "assets/svgs/icons-actions-add-v-1.svg";
import { ReactComponent as ConnectedSvg } from "assets/svgs/icons-misc-connected.svg";
import { Chain, Connector, useAccount, useConnect, useSwitchNetwork } from "wagmi";
import { useWalletInfo } from "hooks/useWalletProvider";
import WalletPopupWin from "./WalletPopupWin";
import StyledContainer from "style/styledContainer";
import ConnectedWallets from "./components/ConnectedWallets";
import { getStore, setStore } from "utils/store";
import { useSystemInfo } from "hooks/useSystemProvider";
import useDebounce from "hooks/useDebounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { reloadWin } from "utils";
import { getWalletError } from "utils/walletManage";

const ConnectBtn = () => {
  const theme = useTheme()
  const { chains, switchNetworkAsync } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
  });
  const { chosedChainId } = useSystemInfo();
  const { nearWalletInfo, openWalletWin, closeWalletWin, openNearWin } =
    useWalletInfo();
  const { connect, connectors } = useConnect({
    onError: getWalletError
  });
  const { isConnected: evmIsConnected, connector: evmActiveConnector } =
    useAccount({
      onDisconnect() {
        if (getStore("metamask.connected")) {
          if (connectors && connectors.length && evmActiveConnector) {
            connect({ connector: evmActiveConnector });
          }
        }
      },
    });

  const fnChosedWallet = (walletId: string, connector?: Connector) => {
    if (walletId.includes("evm")) {
      setFirst(false)
      setStore("metamask.connected", true);
      connect({ connector: connector });
    } else {
      openNearWin();
    }
    closeWalletWin();
  };

  useEffect(() => {
    if(evmActiveConnector) {
      setStore('chainge.curWallet', evmActiveConnector.name.toLocaleLowerCase())
    } else {
      setStore('chainge.curWallet', '')
    }
  }, [evmActiveConnector])

  const getConnectedNumber = () => {
    return +evmIsConnected + +(nearWalletInfo?.isConnected || 0);
  };

  const fnSwitchNetwork = useCallback(
    async (curNetwork: Chain) => {
      try {
        setTimeout(() => {
          reloadWin()
        }, 1000);
        await switchNetworkAsync?.(curNetwork.id);
      } catch (error) {
        console.log();
      }
    },
    [switchNetworkAsync]
  );
  const [first, setFirst] = useState(true);
  useDebounce(
    () => {
      if (!first && evmIsConnected && chains.length && chosedChainId !== -1) {
        setFirst(true);
        const chain = chains.find((item) => item.id === chosedChainId);
        if (chain) {
          fnSwitchNetwork(chain);
        }
      }
    },
    500,
    [evmIsConnected, chains, chosedChainId, first]
  );

  return (
    <>
      <ConnectBtnBox>
        <FlexCenter onClick={() => openWalletWin()}>
          <LockSvg fill={theme.basicBlack} style={{ width: "26px", height: "26px" }}></LockSvg>
          <Text>Wallets</Text>
          <AddSvg
            fill={theme.basicBlack}
            style={{ width: "12px", height: "12px", marginRight: "15px" }}
          ></AddSvg>
        </FlexCenter>
        {(evmIsConnected || nearWalletInfo?.isConnected) && (
          <ConnectedNode number={getConnectedNumber()}></ConnectedNode>
        )}
      </ConnectBtnBox>
      <WalletPopupWin
        connectors={connectors}
        activeConnector={evmActiveConnector}
        chosedWallet={fnChosedWallet}
      ></WalletPopupWin>
    </>
  );
};

const ConnectedNode = ({ number }: { number: number }) => {
  const theme = useTheme()
  const conWalletRef = useRef(null);
  const fnOpenConWin = () => {
    if (conWalletRef) {
      (conWalletRef as any).current.openWin();
    }
  };

  return (
    <>
      <ConnectedWalletBox position="relative">
        <ConnectedBox onClick={fnOpenConWin}>
          <ConnectedSvg
            fill={theme.basicBlack}
            style={{ width: "14px", height: "14px", marginRight: "10px" }}
          ></ConnectedSvg>
          <span>{number}</span>
        </ConnectedBox>
      </ConnectedWalletBox>
      <ConnectedWallets innerRef={conWalletRef}></ConnectedWallets>
    </>
  );
};

const ConnectBtnBox = styled(FlexCenter)`
  background-color: ${({theme}) => theme.basicWhite};
  padding-left: 5px;
  border-radius: 38px;
  height: 38px;
  box-sizing: border-box;
  cursor: pointer;
  border: 0.5px solid transparent;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 17px;
`;

const ConnectedWalletBox = styled(StyledContainer)`
  position: relative;
`;

const ConnectedBox = styled(FlexCenter)`
  padding: 9px 12px;
  border-radius: 32px;
  background-color: ${({theme}) => theme.basicGrey};
  font-size: 12px;
  font-weight: 500;
  margin-right: 3px;
`;

export default ConnectBtn;
