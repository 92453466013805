import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { allNearForReSubmit, checkTaskInAllNearFroRefresh, evmForReSubmit, getOrderDetailForRefresh, onlyNearForReSubmit } from "service/executeService";
import { CatchType } from "types/baseType";
import { getOrderStore } from "utils/store";

interface OrderProps {
    orderList: CatchType[]
    pendingList: CatchType[]
}

const OrderContext = createContext<OrderProps | null>(null);

export const OrderProvider = ({ children }: { children: ReactNode }) => {
    const [pendingList, setPendingList] = useState<CatchType[]>([])
    const [orderList, setOrderList] = useState<CatchType[]>([])

    const [count, setCount] = useState(0);
    useEffect(() => {
      const timer = setInterval(() => {
        setCount(count + 1);
      }, 1000);
      return () => {
        timer && clearInterval(timer);
      };
    }, [count]);
  
    useEffect(() => {
      const trList = getOrderStore()
      setOrderList(trList)
      getPendingList(trList)
    }, [count]);

    const getPendingList = (trList: CatchType[]) => {
      const list = trList.filter((item: CatchType) => (item.status !== "successful" && item.status !== "refunded"));
      setPendingList(list);
      getRefreshList(list)
      getReSubmitList(list)
    };

    // pending
    const [refreshList, setRefreshList] = useState<CatchType[]>([])
    const getRefreshList = (trList: CatchType[]) => {
      const list = trList.filter((item: CatchType) => {
        const date = item.date
        const now = Date.now()
        if(item.status === "pending" && (now - date) >= 5*60*1000) {
          return true
        }
        return false
      });
      setRefreshList(list);
    }

    const checkType = (detail: CatchType) => {
      const originParams = detail.originParams
      if(originParams.fromChain.name === 'NEAR' && originParams.toChain.name === 'NEAR') {
        return 'all_near'
      } else if(originParams.fromChain.name === 'NEAR' || originParams.toChain.name === 'NEAR') {
        return 'only_near'
      } else {
        return 'evm'
      }
    }

    const refreshData = useCallback(() => {
      if(refreshList.length) {
        refreshList.forEach(item => {
          const type = checkType(item)
          if(type === 'all_near') {
            checkTaskInAllNearFroRefresh(item.certHash as string, item.uuid, item.originParams)
          } else if(type === 'only_near') {
            getOrderDetailForRefresh(item.certHash as string, item.realyParams.params.evmAddress, item.uuid, item.originParams)
          } else {
            let isOShi = false;
            if(item.realyParams.fromToken === 'OSHI') {
              isOShi = true;
            }
            getOrderDetailForRefresh(item.certHash as string, item.realyParams.evmAddress, item.uuid, item.originParams, isOShi)
          }
        })
      }
    }, [refreshList])

    const checkAllEvm = (item: CatchType) => {
      const {fromChain = '', toChain = ''} = item.realyParams || {}
      if(fromChain && toChain && fromChain !== 'NEAR' && toChain !== 'NEAR') {
        return true
      } else {
        return false
      }
    }
    
    // executing
    const [reSubmitList, setReSubmitList] = useState<CatchType[]>([])
    const getReSubmitList = (trList: CatchType[]) => {
      const list = trList.filter((item: CatchType) => {
        const date = item.date
        const now = Date.now()
        if((item.status === "executing" || (checkAllEvm(item) && item.status === 'validating')) && (now - date) >= 3*60*1000) {
          return true
        }
        return false
      });
      setReSubmitList(list);
    }

    const submitData = useCallback(() => {
      if(reSubmitList.length) {
        reSubmitList.forEach(async item => {
          const type = checkType(item)
          if(type === 'all_near') {
            allNearForReSubmit(item.realyParams, item.uuid, item.originParams)
          } else if(type === 'only_near') {
            onlyNearForReSubmit(item.realyParams, item.uuid, item.originParams)
          } else {
            let isOShi = false
            if(item.realyParams.fromToken === 'OSHI') {
              isOShi = true;
            }
            const isOrder = await getOrderDetailForRefresh(item.certHash as string, item.realyParams.evmAddress, item.uuid, item.originParams, isOShi)
            if(!isOrder) {
              evmForReSubmit(item.realyParams, item.uuid, item.originParams)
            }
          }
        })
      }
    }, [reSubmitList])

    useEffect(() => {
      if (count % (1 / 2 * 60) === 0) {
        refreshData()
      }
      if (count % (3 * 60) === 0) {
        submitData()
      }
    }, [count, refreshData, submitData])

    return (
      <OrderContext.Provider
        value={{orderList, pendingList}}
      >
        {children}
      </OrderContext.Provider>
    );
}

export const useOrder = () => {
    return (
      useContext(OrderContext) || {orderList: [], pendingList: []}
    );
  };