import { Wallet, WalletSelector } from "@near-wallet-selector/core";
import { ethers } from "ethers";
import { connect, InMemorySigner, KeyPair, keyStores, providers} from "near-api-js";
import { formatNearAmount } from "near-api-js/lib/utils/format";
import { bufTohex, stringToBuffer } from "utils";

const THIRTY_TGAS = "30000000000000";
const NO_DEPOSIT = "0";

export const viewMethod = async (
  walletSelector: WalletSelector,
  { contractId, method, args = {} }: any
) => {
  const { network } = walletSelector.options;
  const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });
  let res = await provider.query({
    request_type: "call_function",
    account_id: contractId,
    method_name: method,
    args_base64: Buffer.from(JSON.stringify(args)).toString("base64"),
    finality: "optimistic",
  });
  return JSON.parse(Buffer.from((res as any).result).toString());
};

export const callMethod = async (
  wallet: Wallet,
  accountId: string,
  {
    contractId,
    method,
    args = {},
    gas = THIRTY_TGAS,
    deposit = NO_DEPOSIT,
    flagHash = false,
  }: any
) => {
  const outcome = await wallet.signAndSendTransaction({
    signerId: accountId,
    receiverId: contractId,
    actions: [
      {
        type: "FunctionCall",
        params: {
          methodName: method,
          args,
          gas,
          deposit,
        },
      },
    ],
  });
  if (flagHash) {
    return outcome;
  } else {
    return providers.getTransactionLastResult(
      outcome as providers.FinalExecutionOutcome
    );
  }
};

// Get transaction result from the network
export const getTransactionResult = async (
  walletSelector: WalletSelector,
  txhash: string
) => {
  const transaction = await getTxHashStatus(walletSelector, txhash);
  return providers.getTransactionLastResult(transaction);
};

export const getTxHashStatus = async (
  walletSelector: WalletSelector,
  txhash: string
) => {
  const { network } = walletSelector.options;
  const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

  // Retrieve transaction result from the network
  const transaction = await provider.txStatus(txhash, "unnused");
  return transaction;
};

export const getBalance = async (
  walletSelector: WalletSelector,
  accountId: string,
  contractId: string,
  unitName = 6
) => {
  try {
    const result = await viewMethod(walletSelector, {
      contractId: contractId,
      method: "ft_balance_of",
      args: { account_id: accountId },
    });
    return ethers.utils.formatUnits(result, unitName);
  } catch (error) {
    return "0";
  }
};

export const getNearBalance = async (accountId: string) => {
  const connectionConfig = {
    networkId: "mainnet",
    nodeUrl: "https://rpc.mainnet.near.org",
  };
  const nearConnection = await connect(connectionConfig as any);
  const account = await nearConnection.account(accountId);
  const balance = await account.getAccountBalance();
  return formatNearAmount(balance.available)
};


export const signMsgByNear = async (msg: string, accountId: string, networkId: string, curWallet: Wallet) => {
  let myKeyStore = null
  if(curWallet && curWallet.id === "sender") {
    const authData = (window as any).near.authData
    let secretKey = ''
    if(authData && authData.accessKey) {
      secretKey = authData.accessKey.secretKey
    }
    if(!secretKey) {
      return
    }
    const keyPair = KeyPair.fromString(secretKey)
    myKeyStore = new keyStores.InMemoryKeyStore();
    await myKeyStore.setKey("mainnet", accountId, keyPair)
  } else {
    myKeyStore = new keyStores.BrowserLocalStorageKeyStore();
  }
  const signer = new InMemorySigner(myKeyStore)
  const curBuffer = stringToBuffer(msg)
  const signerInfo = await signer.signMessage(curBuffer, accountId, networkId)
  return bufTohex(signerInfo.signature)
}