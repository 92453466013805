import StyledContainer from "style/styledContainer";
import { ReactComponent as SearchSvg } from "assets/svgs/icons-actions-search.svg";
import styled from "styled-components";
import { useEffect, useRef } from "react";


type Props = {
    onFilterChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchHeader = ({onFilterChange}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);
  return (
    <StyledContainer position="relative" overflow="hidden">
      <SearchSvgIcon></SearchSvgIcon>
      <SearchInput
        ref={inputRef}
        placeholder="Start typing…"
        onChange={onFilterChange}
      ></SearchInput>
      <BoxShadow></BoxShadow>
    </StyledContainer>
  );
};

const SearchSvgIcon = styled(SearchSvg)`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0, -50%);
  fill: ${({ theme }) => theme.basicGreen};
`;

const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.searchBoxBgForPcAsset};
  border: none;

  width: 100%;
  height: 75px;

  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.basicGreen};
  padding: 0 15px;
  padding-left: 60px;
  box-sizing: border-box;
  border-radius: 12px 12px 0 0;

  &::placeholder {
    color: ${({ theme }) => theme.deepGrey};
  }

  &:focus {
    outline: none;
  }
`;

const BoxShadow = styled.div`
  box-shadow: ${({ theme }) => theme.searchBoxShadowForPcAsset} 0px 0 20px 0.5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export default SearchHeader;
