import {
  basicBlack,
  FlexCenter,
} from "style/preset";
import styled, { useTheme } from "styled-components";
import { ReactComponent as SwapSvg } from "assets/svgs/icon-btn-swap.svg";
import { ReactComponent as LockSvg } from "assets/svgs/icon-btn-lock.svg";
import { ReactComponent as Right01Svg } from "assets/svgs/icons-arrows-right-01.svg";
import { ReactComponent as ErrorSvg } from "assets/svgs/icons-misc-info.svg";
// ismobile
import { ReactComponent as NextSvg } from "assets/svgs/mobile/icons-arrows-right-03.svg";

import StyledContainer from "style/styledContainer";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useWalletInfo } from "hooks/useWalletProvider";
import { Chain } from "@chainge/sdk";
import { useCallback, useEffect, useRef, useState } from "react";
import { comparationBigNumber, reloadWin } from "utils";
import { useSystemInfo } from "hooks/useSystemProvider";
import WarningPopupWin from "components/Mobile/WarningPopupWin";
import { createPortal } from "react-dom";
// import { getCurProvider } from "utils/provider";

const svgStyle = { width: "20px", height: "20px" };
const NearFlag = "NEAR";
type Props = {
  fromChain: Chain;
  toChain: string;
  fromSymbol: string;
  toSymbol: string;
  fromAmount: string;
  toAmount: string;
  errMsg?: string;
  balance: string;

  onClick?: (type: string) => void;
};
const MainBtn = ({
  fromChain,
  toChain,
  fromSymbol,
  toSymbol,
  fromAmount,
  toAmount,
  errMsg = "",
  balance,
  onClick,
}: Props) => {
  const { isMobile } = useSystemInfo();
  const { chain } = useNetwork();
  const { isConnected: evmIsConnected } = useAccount();
  const { nearWalletInfo } = useWalletInfo();
  const { isConnected: nearIsConnected } = nearWalletInfo || {};
  const [isConnected, setIsConnected] = useState(false);
  const { switchNetworkAsync } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
  });
  const { openWalletWin } = useWalletInfo();

  const [btnLabel, setBtnLabel] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSwitchNetwork, setIsSwitchNetwork] = useState(false);
  const [executeType, setExecuteType] = useState("");

  const [disabled, setDisabled] = useState(false);

  const { connector: curConnector } = useAccount()

  useEffect(() => {
    setIsError(false);
    setIsSwitchNetwork(false);
    setIsConnected(false);
    setDisabled(false);
    setExecuteType("");
    if (!fromChain?.name || !toChain || !fromSymbol || !toSymbol) {
      setBtnLabel("Completion parameter");
      setIsError(true);
      setDisabled(true);
      return;
    }
    if(fromSymbol === 'BUSD' || toSymbol === 'BUSD') {
      if(['FSN', 'ETH'].includes(toChain) && ['FSN', 'ETH'].includes(fromChain?.name)) {
       
      } else {
        setBtnLabel("Not support");
        setIsError(true);
        return;
      }
    }
    if(fromSymbol === 'OSHI' || toSymbol === 'OSHI' || (toChain === 'OKT' && toSymbol === 'ETH')) {
      setBtnLabel("Not support");
      setIsError(true);
      return;
    }
    if(toSymbol === 'POLS') {
      if(!['FSN'].includes(toChain)) {
        setBtnLabel("Not support");
        setIsError(true);
        return;
      }
    }
    if (errMsg) {
      setBtnLabel(errMsg);
      // setBtnLabel("Internal server error");
      setIsError(true);
      return;
    }
    if (!fromAmount || +fromAmount <= 0) {
      setBtnLabel("Please input an amount to proceed");
      setIsError(true);
      setDisabled(true);
      return;
    }
    if (toChain === fromChain?.name && fromChain?.name === NearFlag) {
      if (nearIsConnected) {
        setBtnLabel("Swap now");
        setExecuteType("all_near");
        setIsConnected(true);
      } else {
        setBtnLabel("Connect a Near wallet");
        setIsConnected(false);
      }
    } else {
      if (fromChain?.name !== NearFlag && toChain !== NearFlag) {
        if (evmIsConnected) {
          setBtnLabel("Swap now");
          setExecuteType("all_evm");
          setIsConnected(true);
        } else {
          setBtnLabel("Connect an EVM wallet");
          setIsConnected(false);
        }
        if (chain && chain?.id !== fromChain.chainId) {
          setBtnLabel("Switch network to " + fromChain?.fullName);
          setIsSwitchNetwork(true);
          return;
        }
      } else {
        if (fromChain?.name === NearFlag) {
          if (nearIsConnected && evmIsConnected) {
            setBtnLabel("Swap now");
            setExecuteType("near");
            setIsConnected(true);
          } else {
            if (!nearIsConnected) {
              setBtnLabel("Connect a Near wallet");
              setIsConnected(false);
              return;
            }
            if (!evmIsConnected) {
              setBtnLabel("Connect an EVM wallet");
              setIsConnected(false);
              return;
            }
          }
        } else {
          if (nearIsConnected && evmIsConnected) {
            setBtnLabel("Swap now");
            setExecuteType("near");
            setIsConnected(true);
          } else {
            if (!evmIsConnected) {
              setBtnLabel("Connect an EVM wallet");
              setIsConnected(false);
              return;
            }
            if (!nearIsConnected) {
              setBtnLabel("Connect a Near wallet");
              setIsConnected(false);
              return;
            }
          }
          if (chain && chain?.id !== fromChain.chainId) {
            setBtnLabel("Switch network to " + fromChain?.fullName);
            setIsSwitchNetwork(true);
            return;
          }
        }
      }
    }
    if (comparationBigNumber(fromAmount, balance, "gt")) {
      setBtnLabel("Your balance is too low");
      setIsError(true);
      return;
    }
  }, [
    balance,
    chain,
    chain?.id,
    errMsg,
    evmIsConnected,
    fromAmount,
    fromChain.chainId,
    fromChain?.fullName,
    fromChain?.name,
    fromSymbol,
    nearIsConnected,
    toChain,
    toSymbol,
  ]);

  const fnSwitchNetwork = useCallback(
    async (curNetwork: Chain) => {
      try {
        setTimeout(() => {
          reloadWin()
        }, 1000);
        await switchNetworkAsync?.(curNetwork.chainId);
      } catch (error) {
        console.log();
      }
    },
    [switchNetworkAsync]
  );

  const fnOnClick = async () => {
    if (isError) {
      if(isMobile) {
        let errorMsg = {
          error: '',
          des: ''
        }
        if(btnLabel === 'Your balance is too low') {
          errorMsg.error = `Your ${fromSymbol} balance is not enough to execute the swap.`
          errorMsg.des = 'Please add more funds'
        } else {
          errorMsg.error = btnLabel
        }
        if(warningWinRef) {
          (warningWinRef as any).current.openWin(errorMsg)
        }
      }
      return 
    }
    if (isSwitchNetwork) {
      fnSwitchNetwork(fromChain);
      return
    }
    if (isConnected) {
      if (!balance) return;
      const chainId = await curConnector?.getChainId() as Number
      if(+fromChain.chainId === +chainId && +fromChain.chainId === +chain!.id) {
        onClick && onClick(executeType);
      }
    } else {
      openWalletWin();
    }
  };

  const theme = useTheme()
  const getBgColor = () => {
    if (isError) {
      if (disabled) {
        return theme.basicGrey;
      }
      return theme.basicRed;
    } else {
      return theme.lightYellow;
    }
  };

  const textColor = () => {
    if (isError) {
      if (disabled) {
        return theme.deepGrey;
      }
      return theme.deepRed;
    } else {
      return basicBlack;
    }
  };

  // --------------------------## mobile begin ----------------------------
  const warningWinRef = useRef(null);
  // --------------------------## mobile end ------------------------------

  return (
    <>
      <BtnBox
        bg={getBgColor()}
        color={textColor()}
        onClick={fnOnClick}
        isMobile={isMobile}
      >
        {!isMobile && <BtnText>{btnLabel}</BtnText>}
        <FlexCenter>
          <>
            {isError && (
              <ErrorSvg
                className="swapSvg"
                fill={textColor()}
                style={svgStyle}
              ></ErrorSvg>
            )}
            {!isError && (
              <>
                {!isConnected && (
                  <LockSvg className="lockSvg" style={svgStyle}></LockSvg>
                )}
                {isConnected && (
                  <>
                    {isSwitchNetwork && (
                      <ImgBox className="lockSvg" src={fromChain?.url}></ImgBox>
                    )}
                    {!isSwitchNetwork && !isMobile && (
                      <SwapSvg className="swapSvg" style={svgStyle}></SwapSvg>
                    )}
                    {!isSwitchNetwork && isMobile && <NextSvg></NextSvg>}
                  </>
                )}
              </>
            )}
          </>
          {!isMobile && !isError && (!isConnected || isSwitchNetwork) && (
            <Right01SvgWrapper className="rightSvg"></Right01SvgWrapper>
          )}
        </FlexCenter>
      </BtnBox>
      {
        isMobile && createPortal(<WarningPopupWin innerRef={warningWinRef}></WarningPopupWin>, document.body) 
      }
    </>
  );
};

const BtnBox = styled(StyledContainer)<any>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 17px 15px;
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};

  ${({ isMobile }) => {
    if (isMobile) {
      return ` width: 52px;
        height: 52px;
        border-radius: 50%;
        padding: 0;
        justify-content: center;
        `;
    } else {
      return `
      .lockSvg {
        position: relative;
        left: 8px;
        transition: all linear 0.2s;
      }
    
      .rightSvg {
        opacity: 0;
        transition: all linear 0.2s;
      }
    
      &:hover {
        .rightSvg {
          opacity: 1;
        }
        .lockSvg {
          left: -5px;
        }
      }
      `;
    }
  }}
`;

const BtnText = styled.span`
  font-family: InterSemiBold;
  font-size: 14px;
  line-height: 1.43;
`;

const Right01SvgWrapper = styled(Right01Svg)`
  width: 10px;
  height: 10px;
`;

const ImgBox = styled.img`
  width: 20px;
  height: 20px;
`;

export default MainBtn;
