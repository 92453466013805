import CImage from "components/CImage";
import { Card, FlexCenter, FlexItemsCenter } from "style/preset";
import styled from "styled-components";
import { ReactComponent as IntoArrowSVG } from "assets/svgs/into-arrow.svg";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import { CatchType } from "types/baseType";
import { useCallback, useEffect, useState } from "react";
import StatusBar from "./StatusBar";
import { HashHandlerItem } from "pages/Transactions";
import { useSystemInfo } from "hooks/useSystemProvider";

const TransactionItem = ({ detail }: { detail: CatchType }) => {
  const getOriginParams = () => {
    return detail.originParams;
  };

  const { externalChainInfo } = useSystemInfo();
  const openBrowser = useCallback(() => {
    let url = "";
    if (detail.originParams.fromChain.name === "NEAR") {
      url = `https://explorer.near.org/transactions/${detail.certHash}`;
    } else {
      const curObj = externalChainInfo.find(
        (item) => item.name === detail.originParams.fromChain.name
      );
      if (curObj) {
        const blockExplorerPathHash = "/tx/";
        url = `${curObj.blockBrowserUrls[0]}${blockExplorerPathHash}${detail.certHash}`;
      }
    }
    if (url) {
      (window as any).open(url, "_blank");
    }
  }, [detail.certHash, detail.originParams.fromChain.name, externalChainInfo]);


  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  }, [isCopy]);
  return (
    <TransactionItemBox>
      <FlexCenter justifyContent="flex-start !important">
        <FlexCenter>
          <CImage
            src={getOriginParams()?.fromSymbol.url || ""}
            width="22px"
            height="22px"
            mr="10px"
          ></CImage>
          <StyledContainer flex column>
            <StyledText fontSize="14px" fontWeight6 interSemiBold>
              {getOriginParams().fromAmount}{" "}
              {getOriginParams().fromSymbol.symbol}
            </StyledText>
            <StyledText fontSize="12px" interRegular mt="3px">
              on {getOriginParams().fromChain.fullName}
            </StyledText>
          </StyledContainer>
        </FlexCenter>
        <IntoArrowSVGWrapper></IntoArrowSVGWrapper>
        <FlexCenter>
          <CImage
            src={getOriginParams()?.toSymbol.url || ""}
            width="22px"
            height="22px"
            mr="10px"
          ></CImage>
          <StyledContainer flex column>
            <StyledText fontSize="14px" fontWeight6 interSemiBold>
              {getOriginParams().toAmount} {getOriginParams().toSymbol.symbol}
            </StyledText>
            <StyledText fontSize="12px" interRegular mt="3px">
              on {getOriginParams().toChain.fullName}
            </StyledText>
          </StyledContainer>
        </FlexCenter>
      </FlexCenter>
      <FlexItemsCenter mt="25px">
        {/* <CopyToClipboard
          text={detail.certHash || ""}
          onCopy={() => setIsCopy(true)}
        >
          <HxBar pointer>
            <StyledText fontSize="12px" interSemiBold fontWeight6 mr="4px">
              Tx Hash:
            </StyledText>
            <StyledText fontSize="12px" interRegular mr="12px">
              {isCopy
                ? "Copied"
                : detail.certHash
                ? shortStr(detail.certHash || "", 6)
                : ""}
            </StyledText>
            <CopySvg></CopySvg>
          </HxBar>
        </CopyToClipboard> */}
        <HashHandlerItem
          openBrowser={openBrowser}
          certHash={detail.certHash || ""}
        ></HashHandlerItem>

        <StatusBar status={detail.status}></StatusBar>
      </FlexItemsCenter>
    </TransactionItemBox>
  );
};

const TransactionItemBox = styled(Card)`
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

const IntoArrowSVGWrapper = styled(IntoArrowSVG)`
  width: 26px;
  height: 26px;
  margin: 0 40px;

  ${({ theme }) =>
    theme.mode === "day"
      ? `g {
    fill: none;
    circle {
      fill: #FFF;
      stroke: #95A3BE;
    }
    path {
      fill: #95A3BE;
    }
  }`
      : `g {
    fill: #1f1f1f;
    circle {
      fill: #1f1f1f;
      stroke: #1f1f1f;
    }
    path {
      fill: #ebebeb;
    }
  }`}
`;

export default TransactionItem;
