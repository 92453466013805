import styled, { css } from "styled-components";
import StyledContainer from "./styledContainer";

// icon
import LogoPngIcon from 'assets/images/symbol@3x.png'
import LogoPngIconDark from 'assets/images/symbol@3x_white.png'

import FavoritesOnIcon from "assets/images/favorites-on@3x.png";
import FavoritesOnIconDark from "assets/images/favorites-on-dark@3x.png";

import FavoritesOffIcon from "assets/images/favorites-off@3x.png";
import FavoritesOffIconDark from "assets/images/favorites-off-dark@3x.png";

import IllustrationWalletIcon from "assets/images/illustration-wallet@3x.png";
import IllustrationWalletIconDark from "assets/images/illustration-wallet-dark@3x.png";

import NearLogoIcon from "assets/images/near-logo@3x.png";
import NearLogoIconDark from "assets/images/near-logo-dark@3x.png";


import EmptyIllustrationIcon from "assets/images/empty-illustration@3x.png";
import EmptyIllustrationIconDark from "assets/images/empty-illustration-dark@3x.png";

import NoTxIllustrationIcon from "assets/images/no-tx-illustration@3x.png";
import NoTxIllustrationIconDark from "assets/images/no-tx-illustration-dark@3x.png";

import ProgressIllustrationIcon from 'assets/images/illustration-in-progress@3x.png'
import ProgressIllustrationIconDark from 'assets/images/illustration-in-progress-dark@3x.png'


import bgImgIcon from 'assets/images/bg.png'
import bgImgIconDark from 'assets/images/bg-dark.png'

import defWalletIcon from 'assets/images/defWallet.png'
import defWalletDarkIcon from 'assets/images/defWalletDark.png'


export const basicGreen = "#239589";
export const basicWhite = "#ffffff";
export const basicGrey = "#f0f3f9";
export const lightGrey = "#e5ebf7";
export const deepGrey = "#95a3be";
export const seniorGrey = "#aeaeae";
export const basicBlack = "#0a0a0a";
export const lightYellow = "#ffdd33";
export const deepYellow = "#ffd500";
export const basicRed = "#ffe8eb";
export const deepRed = "#d11026";
export const defaultContentColor = '#0a0a0a';
export const cardBoxShadow = '#e4e7ed';

export const minLayoutWidth = "1080px";
export const minContentWidth = "900px";

export const defaultTheme = {
  mode: 'day',

  basicGreen,
  basicWhite,
  basicGrey,
  lightGrey,
  deepGrey,
  seniorGrey,
  basicBlack,
  lightYellow,
  deepYellow,
  basicRed,
  deepRed,
  defaultContentColor,
  cardBoxShadow,
  cardBorder: 'border: none;',
  cardBorderHover: 'border: none;',
  hoverBorderColor: lightGrey,

  downAppBtn: lightGrey,
  downAppBtnHover: basicBlack,

  conedBtn: basicGrey,
  conedBtnHover: '#def7f5',

  walletItemShadow: '#ebebeb',
  walletDetailOptions: '#ffffff',
  walletIconBoxForNetwork: basicGrey,

  loadingBg: 'rgba(255, 255, 255, 0.92)',
  loadingColor: deepGrey,

  searchBoxBgForPcAsset: '#f8fafe',
  searchBoxShadowForPcAsset: basicBlack,
  tableHeaderShadowForPcAsset: '#ebebeb',
  tableHeaderBorderForPcAsset: '',
  scrollbarTrackForPcAsset: '#f8fafe',
  scrollbarThumbForPcAsset: lightGrey,
  assetItemBoxForPcAsset: '#f8fafe',

  orderListShadow: '#ebebeb',
  orderImgBox: '#f8fafe',
  orderDelBox: '#ffebed',
  orderDelFill: '#D11026',
  orderPending: '#b89000',
  confirmBg: 'rgba(255, 255, 255, 0.8)',

  progressLabel: '#B89000',
  progressBg: '#fffac2',
  progressNumBg: '#fffdeb',
  porgressShadow: '#ebebeb',
  statusBar: '#def7f5',
  statusBarLabel: basicGreen,

  popupWinBg: 'rgba(240, 243, 249, 0.8)',

  inputBorder: deepGrey,
  inputPlaceholder: deepGrey,

  // icon
  iconForLogo: LogoPngIcon,
  iconForFavoritesOn: FavoritesOnIcon,
  iconForFavoritesOff: FavoritesOffIcon,
  iconForWalletIllustration: IllustrationWalletIcon,
  iconForNearLogo: NearLogoIcon,
  iconForEmptyIllustration: EmptyIllustrationIcon,
  iconForNoTxIllustration: NoTxIllustrationIcon,
  iconForProgressIllustration: ProgressIllustrationIcon,

  pcBgImg: bgImgIcon,

  iconForDefWallet: defWalletIcon,

  // mobile 
  cselectIconBg: basicWhite,
  headerBorder: 'rgb(227 232 241)',
  themeBtnActive: '#ecf1fa',
  themeBtnTextActive: basicBlack,
  navBarActive: '#ecf1fa',
}

export const darkTheme = {
  mode: 'dark',
  
  basicGreen: '#2ec4b6',
  basicWhite: '#0a0a0a',
  basicGrey: '#1f1f1f',
  lightGrey: '#292929',
  deepGrey: '#aeaeae',
  seniorGrey,
  basicBlack: '#ebebeb',
  lightYellow,
  deepYellow,
  basicRed,
  deepRed,
  defaultContentColor: '#ebebeb',
  cardBoxShadow: 'rgba(0, 0, 0, 0.36)',
  cardBorder: 'border: solid 0.5px #404040;',
  cardBorderHover: 'border: solid 0.5px #ababab;',
  hoverBorderColor: '#404040',

  downAppBtn: '#0a0a0a',
  downAppBtnHover: lightYellow,

  conedBtn: '#292929',
  conedBtnHover: '#5b5b5b',

  walletItemShadow: 'rgba(0, 0, 0, 0.36)',
  walletDetailOptions: '#292929',
  walletIconBoxForNetwork: '#292929',

  loadingBg: 'rgba(10, 10, 10, 0.92)',
  loadingColor: '#ebebeb',

  searchBoxBgForPcAsset: '#141414',
  searchBoxShadowForPcAsset: '#181818',
  tableHeaderShadowForPcAsset: 'rgba(0, 0, 0, 0.36)',
  tableHeaderBorderForPcAsset: 'border-bottom: 1px solid #1f1f1f',
  scrollbarTrackForPcAsset: '#1f1f1f',
  scrollbarThumbForPcAsset: '#aeaeae',
  assetItemBoxForPcAsset: '#1f1f1f',

  orderListShadow: 'rgba(0, 0, 0, 0.36)',
  orderImgBox: '#292929',
  orderDelBox: '#1f1f1f',
  orderDelFill: '#ebebeb',
  orderPending: '#fdc500',
  confirmBg: 'rgba(10, 10, 10, 0.8)',

  progressLabel: '#ebebeb',
  progressBg: '#1f1f1f',
  progressNumBg: '#292929',
  porgressShadow: 'rgba(0, 0, 0, 0.36)',
  statusBar: '#7ae1d5',
  statusBarLabel: '#13534c',

  popupWinBg: 'rgba(10, 10, 10, 0.8)',

  inputBorder: '#484848',
  inputPlaceholder: '#ebebeb',

  // icon
  iconForLogo: LogoPngIconDark,
  iconForFavoritesOn: FavoritesOnIconDark,
  iconForFavoritesOff: FavoritesOffIconDark,
  iconForWalletIllustration: IllustrationWalletIconDark,
  iconForNearLogo: NearLogoIconDark,
  iconForEmptyIllustration: EmptyIllustrationIconDark,
  iconForNoTxIllustration: NoTxIllustrationIconDark,
  iconForProgressIllustration: ProgressIllustrationIconDark,

  pcBgImg: bgImgIconDark,

  iconForDefWallet: defWalletDarkIcon,

  // mobile 
  cselectIconBg: '#292929',
  headerBorder: 'rgb(64, 64, 64)',
  themeBtnActive: '#ecf1fa',
  themeBtnTextActive: basicBlack,
  navBarActive: '#1f1f1f',
}


export const BaseLayoutScreenSection = styled(StyledContainer)`
  width: ${minLayoutWidth};
  @media (max-width: 1090px) {
    padding: 0 10px;
    box-sizing: border-box;
  }
  @media (max-width: ${minLayoutWidth}) {
    width: 100%;
  }
`
export const BaseContentScreenSection = styled(StyledContainer)`
  width: ${minContentWidth};
  @media (max-width: 910px) {
    padding: 0 10px;
    box-sizing: border-box;
  }
  @media (max-width: ${minContentWidth}) {
    width: 100%;
  }
`

export const FullScreenWidthSection = styled(StyledContainer)`
  width: 100vw;
`;

export const FullScreenSection = styled(StyledContainer)`
  width: 100vw;
  height: 100vh;
`;
export const Bold = styled(StyledContainer)`
  font-wight: bold;
`;

export const FlexCenter = styled(StyledContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexItemsCenter = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BaseLabel = styled(StyledContainer)`
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  width: 55px;
  margin-right: 20px;
  color: ${({theme}) => theme.deepGrey};
`;

export const BaseTitle = styled(StyledContainer)`
  font-family: InterBold;
  font-size: 14px;
  line-height: 1.43;
`;

export const Card = styled(StyledContainer)`
  box-shadow: 3px 12px 30px 0 ${({theme}) => theme.cardBoxShadow};
  background-color: ${({theme}) => theme.basicWhite};
  padding: 30px 25px 30px 25px;
  border-radius: 12px;
  ${({theme}) => theme.cardBorder};
`;

export const Row = styled(StyledContainer)`
  display: flex;
  align-items: center;
`;

export const Column = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
`;

export const PopupWinOutBox = styled(StyledContainer)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
`
export const PopupWinBg = styled(StyledContainer)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({theme}) => theme.popupWinBg};
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
`