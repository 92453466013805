import { Card, Column, FlexCenter, FlexItemsCenter } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled, { useTheme } from "styled-components"

import { ReactComponent as CopySvg } from "assets/svgs/icon-02-actions-copy.svg";

import LaptopIcon from 'assets/images/referral/laptop.png'
import RefCrabIcon from 'assets/images/referral/ref-crab.png'
import RefFishIcon from 'assets/images/referral/ref-fish.png'
import RefSharkIcon from 'assets/images/referral/ref-shark.png'
import PiggybankIcon from 'assets/images/referral/piggybank.png'
import StatsIcon from 'assets/images/referral/stats-coins.png'


import CopyToClipboard from "react-copy-to-clipboard";
import CImage from "components/CImage";
import useKol from "./hooks/useKol";
import { useCallback } from "react";
import { toast } from "react-hot-toast";

const Referral = () => {
    const theme = useTheme()
    const {refUrl, refInfo} = useKol()

    const getIcon = useCallback((level: number) => {
        if(level === 0) {
            return RefCrabIcon
        } else if(level === 1) {
            return RefFishIcon
        } else {
            return RefSharkIcon
        }
    }, [])

    const getPercent = useCallback(() => {
        const level = refInfo && refInfo.level ? refInfo.level : 0
        let total = 10000;
        let curMoney = +refInfo.total;
        if(level === 0) {
            total = 10000;
        } else if(level === 1) {
            curMoney = curMoney - 10000
            total = (1000000 - 10000)
        }
        const percent = (curMoney / total * 100) + '%'
        return percent
    }, [refInfo])

    return <ReferralWrap>
        <LeftWrap>
            <FlexItemsCenter>
                <StyledContainer pr="20px">
                    <StyledContainer fontSize="13px" m="10px 0 20px 0">CHAINGE AFFILIATE & REFERRAL</StyledContainer>
                    <StyledContainer fontSize="13px" lineHeight="1.3" color={theme.deepGrey}>
                        Earn up to 50% commission on cross-chain fees generated by your referred users' swaps.
                    </StyledContainer>
                </StyledContainer>
                <LaptopIconWrap src={LaptopIcon}></LaptopIconWrap>
            </FlexItemsCenter>
            <FlexItemsCenter mt="30px">
                <LevelBox>
                    <img src={getIcon(refInfo.level || 0)} alt="" />
                </LevelBox>
                <ProgressBar>
                    <StyledContainer width={getPercent()}></StyledContainer>
                </ProgressBar>
                <LevelBox>
                    <img src={getIcon((refInfo.level || 0) + 1)} alt="" />
                </LevelBox>
            </FlexItemsCenter>

            <TableWrap>
                <table>
                    <thead>
                        <tr style={{marginBottom: '15px'}}>
                            <th style={{width: '30%', textAlign: "left", paddingBottom: '10px'}}>VOLUME</th>
                            <th style={{width: '40%', textAlign: "left", paddingBottom: '10px'}}>PERCENTAGE</th>
                            <th style={{width: '30%', textAlign: "right", paddingBottom: '10px'}}>RANK</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{`< $10k`}</td>
                            <td>20%</td>
                            <td style={{textAlign: 'right'}}>
                                <CImage src={RefCrabIcon} width="20px" height="20px"></CImage>
                            </td>
                        </tr>
                        <tr>
                            <td>{`$10k < $1M`}</td>
                            <td>40%</td>
                            <td style={{textAlign: 'right'}}>
                                <CImage src={RefFishIcon} width="20px" height="20px"></CImage>
                            </td>
                        </tr>
                        <tr>
                            <td>{`> $1M`}</td>
                            <td>50%</td>
                            <td style={{textAlign: 'right'}}>
                                <CImage src={RefSharkIcon} width="20px" height="20px"></CImage>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TableWrap>
        </LeftWrap>
        <RightWrap>
            {
                refUrl ? <>
                    <StyledContainer fontSize="13px">MY REFERRAL LINK</StyledContainer>
                    <MyReferralLinkBox>
                        {`http://dapp.chainge.finance?ref=${refUrl}`}
                        <CopyToClipboard text={`http://dapp.chainge.finance?ref=${refUrl}`} onCopy={() => {toast.success("Link Copied")}}>
                            <CopySvg fill={theme.basicBlack} cursor="pointer"></CopySvg>
                        </CopyToClipboard>
                    </MyReferralLinkBox>
                </> : <StyledContainer fontSize="12px" bold mt="20px">CONNECT ANY OF THE EVM COMPATIBLE WALLETS TO SEE YOUR UNIQUE REFERRAL LINK.</StyledContainer>
            }

            <StatisticsWrapBox>
                <StatisticsWrap>
                    <AmountBox>
                        <StyledContainer fontSize="24px" fontWeight6>${+(+(refInfo as any).monthFee || 0).toFixed(6)}</StyledContainer>
                        <StyledContainer fontSize="12px" mt="5px" color={theme.deepGrey}>THIS MONTH</StyledContainer>
                    </AmountBox>
                    <img src={PiggybankIcon} height="100%" alt=""/>
                </StatisticsWrap>
                <StyledContainer width="20px"></StyledContainer>
                <StatisticsWrap>
                    <AmountBox>
                        <StyledContainer fontSize="24px" fontWeight6>{(refInfo as any).referralNum || 0}</StyledContainer>
                        <StyledContainer fontSize="12px"  mt="5px" color={theme.deepGrey}>REFERRED USERS</StyledContainer>
                    </AmountBox>
                    <img src={StatsIcon} height="100%" alt=""/>
                </StatisticsWrap>
            </StatisticsWrapBox>

            <StyledContainer fontSize="12px" mt="10px" lineHeight="1.3" color={theme.deepGrey}>PLEASE NOTE: All reward fees are credited to your wallet instantly in the involved chains' native currency</StyledContainer>

            {/* <TableWrap>
                <table>
                    <thead>
                        <tr style={{marginBottom: '15px'}}>
                            <th style={{width: '35%', textAlign: "left", paddingBottom: '10px'}}>FRIENDS TRADE</th>
                            <th style={{width: '20%', textAlign: "left", paddingBottom: '10px'}}>DATE</th>
                            <th style={{width: '10%', textAlign: "left", paddingBottom: '10px'}}>FEE</th>
                            <th style={{textAlign: "right", paddingBottom: '10px'}}>MY COMMISSION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{`< $10k`}</td>
                            <td>20%</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </TableWrap>
            <StyledContainer fontSize="14px" fontWeight6 color={theme.lightGrey} mt="20px" textAlign="center">NO REFERRAL TRADES SO FAR</StyledContainer> */}
        </RightWrap>
    </ReferralWrap>
}

const ReferralWrap = styled(Card)`
    max-width: 950px;
    margin: 0 auto;

    display: flex;

    @media (max-width: 950px) {
        flex-direction: column;
        max-width: 100%;
        margin: 0 20px;
    }
`

// left warp 
const LeftWrap = styled(StyledContainer)`
    width: 40%;

    @media (max-width: 950px) {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    @media (max-width: 800px) {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
    }
`

const LaptopIconWrap = styled.img`
    width: 40%;
`

const LevelBox = styled(FlexCenter)`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.basicGrey};
    padding: 7px;
    box-sizing: border-box;
    &>img {
        width: 100%;
        height: 100%;
    }
`

const ProgressBar = styled(StyledContainer)`
    flex: 1;
    margin: 0 15px;
    height: 8px;
    border-radius: 5px;
    background-color: ${({theme}) => theme.lightGrey};
    overflow: hidden;
    position: relative;
    &>div {
        position: absolute;
        top: 0;
        left: 0;
        height: 8px;
        border-radius: 5px;
        background-color: ${({theme}) => theme.lightYellow};
    }
`

const TableWrap = styled(StyledContainer)`
    margin-top: 30px;
    &>table {
        width: 100%;
        thead {
            th {
                font-size: 14px;
                color: ${({theme}) => theme.deepGrey};
            }
        }

        tbody {
            td {
                padding: 10px 0;
                font-size: 12px;
            }
        }
    }
`



const RightWrap = styled(Column)`
    padding: 10px 0px 10px 40px;
    flex: 1;

    @media (max-width: 800px) {
        padding: 10px 0px 10px 0px;
    }
`

const MyReferralLinkBox = styled(FlexItemsCenter)`
    font-size: 12px;
    color: ${({theme}) => theme.deepGrey};

    margin-top: 15px;
    padding: 15px;

    border: 1px dashed ${({theme}) => theme.deepGrey};
    border-radius: 50px;
`

const StatisticsWrapBox = styled(FlexItemsCenter)`
    margin-top: 30px;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

const StatisticsWrap = styled(FlexItemsCenter)`
    border: 1px dashed ${({theme}) => theme.deepGrey};
    height: 110px;
    flex: 1;
    border-radius: 5px;

    @media (max-width: 800px) {
        flex: none;
        width: 100%;
    }
`

const AmountBox = styled(Column)`
    margin-left: 20px;
`

export default Referral