import { useCallback, useEffect, useState } from "react";
import {
  NetworkId,
  setupWalletSelector,
  WalletSelector,
} from "@near-wallet-selector/core";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import { setupModal } from "@near-wallet-selector/modal-ui";

export const CONTRACTID = 'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near'
const useNearWallet = (network: NetworkId = "mainnet") => {
  const [nearWalletSelector, setNearWalletSelector] =
    useState<WalletSelector>();
  const initNearWallet = useCallback(async () => {
    const walletSelector = await setupWalletSelector({
      network: network,
      modules: [setupMyNearWallet(), setupNearWallet(), setupSender()],
    });
    setNearWalletSelector(walletSelector);
  }, [network]);

  useEffect(() => {
    initNearWallet()
  }, [initNearWallet]);

  const signIn = useCallback(() => {
    if (nearWalletSelector) {
      const description = "Please select a wallet to sign in.";
      const modal = setupModal(nearWalletSelector, {
        contractId: CONTRACTID,
        description,
      });
      modal.show();
    }
  }, [nearWalletSelector]);

  const signOut = useCallback(async () => {
    if(nearWalletSelector) {
      const isSignedIn = nearWalletSelector.isSignedIn()
      if(isSignedIn) {
        const wallet = await nearWalletSelector.wallet()
        await wallet.signOut()
        setNearWalletSelector(undefined)
      }
    }
  }, [nearWalletSelector])

  return { nearWalletSelector,  signIn, signOut, initNearWallet};
};

export default useNearWallet;
