// import Chainge, { ConfigOptions } from "@chainge/sdk";
// const appKey = '27F3cas1YWvV8n8bYcrwR9sPQQnogsBuLW3JUjmGKyRvLTLBEUZzXvk4ZXRhLFqb'

// const config: ConfigOptions = {
//     appKey: appKey,
//     secret: 'xtGkSwwUWtmoC7zdMe2NCrFi9eG1MygVF9HDoaXH364j4fkFJiPi7qsDS1p9xtsh'
// }
// const chaingeForOShi = new Chainge(config)

// export default chaingeForOShi
import chainge from "."

export const updateConfigByOshi = () => {
    chainge.updateConfig({
        appKey: '27F3cas1YWvV8n8bYcrwR9sPQQnogsBuLW3JUjmGKyRvLTLBEUZzXvk4ZXRhLFqb'
    })
}