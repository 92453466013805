import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css'
import './index.css';
import 'react-tooltip/dist/react-tooltip.css'
import App from './pages/App/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { SystemProvider } from 'hooks/useSystemProvider';
import { WalletProvider } from 'hooks/useWalletProvider';
import { OrderProvider } from 'hooks/useOrderProvider';

import * as buffer from 'buffer'
window.Buffer = buffer.Buffer;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router basename=''>
      <SystemProvider>
        <WalletProvider>
          <OrderProvider>
            <App />
          </OrderProvider>
        </WalletProvider>
      </SystemProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
