import { TokenVo } from "@chainge/sdk"
import chainge from "utils/sdk"
import { getBalance as getNearBalanceForContract } from 'service/nearService'
import { getNearBalance as getNearBalanceForNear } from "service/nearService";
import { CurWalletObj } from "types/baseType"

// getEvm balance
export const getEvmBalance = async (userAddress: string = '', fromSymbolList: TokenVo[]) => {
    const result = await chainge.getBalances(userAddress, fromSymbolList)
    if(result.length > 0) {
        return result[0]
    } else {
        return ''
    }
}

// getEvm balance
export const getEvmBalanceAll = async (userAddress: string = '', fromSymbolList: TokenVo[]) => {
    const result = await chainge.getBalances(userAddress, fromSymbolList)
    if(result.length > 0) {
        return result
    } else {
        return []
    }
}

// near balance
export const getNearBalance = async (nearWalletInfo: CurWalletObj, fromSymbol: TokenVo) => {
    if(fromSymbol.address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
        return await getNearBalanceForNear(nearWalletInfo.userAddress)
    } else {
        if(nearWalletInfo?.nearWalletSelector) {
            const result = await getNearBalanceForContract(nearWalletInfo?.nearWalletSelector, nearWalletInfo.userAddress, fromSymbol.address)
            return result
        } else {
            return ''
        }
    }
}