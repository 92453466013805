import {
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import styled, { useTheme } from "styled-components";
import { ReactComponent as RemoveSvg } from "assets/svgs/icons-actions-close.svg";
import CImage from "components/CImage";
import StyledText from "style/styledText";
import { Chain, TokenVo } from "@chainge/sdk";

export const FavoritesBox = styled(FlexItemsCenter)`
  padding: 0px 30px 15px;
  align-items: flex-start;
`;

export const FavoriteItemBox = styled(FlexItemsCenter)`
  min-width: 100px;
  height: 32px;
  border-radius: 32px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 8px;
  background-color: ${({theme}) => theme.basicGrey};

  cursor: pointer;
`;

export const TableRow = styled(FlexItemsCenter)`
  padding: 9px 15px;
  box-shadow: 0 1px 2px 0 ${({theme}) => theme.tableHeaderShadowForPcAsset};
  ${({theme}) => theme.tableHeaderBorderForPcAsset};
`;

export const TableTh = styled(StyledText)`
  font-size: 12px;
  line-height: 1.17;
  font-family: InterRegular;
  color: ${({theme}) => theme.basicBlack};
  font-weight: bold;
`

export const ContentScrollBox = styled(StyledContainer)`
  flex: 1;
  margin: 0 15px 40px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({theme}) => theme.scrollbarTrackForPcAsset};
    border-radius: 5px;
    padding-top: 100px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: ${({theme}) => theme.scrollbarThumbForPcAsset};
    border-radius: 5px;
  }
`;

export const AssetItemBox = styled(FlexItemsCenter)`
  font-size: 12px;
  font-weight: 500;
  background-color: ${({theme}) => theme.assetItemBoxForPcAsset};
  border-radius: 12px;
  height: 60px;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 22px;
`;

export const FavoriteIcon = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0 15px;
`;

export const RemoveIcon = styled(RemoveSvg)`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

const HeightBlock = styled(StyledContainer)`
  height: 10px;
  background-color: ${({theme}) => theme.basicWhite};
`

const ClearLabel = styled(StyledText)`
  color: ${({theme}) => theme.basicGreen};
`

type Props = {
  imgUrl: string;
  name: string;
  onClick: () => void;
  onRemove: () => void;
};
export const FavoriteItem = ({ imgUrl, name, onClick, onRemove }: Props) => {
  const theme = useTheme()
  return (
    <FavoriteItemBox>
      <FlexCenter mr="10px" onClick={onClick}>
        <CImage src={imgUrl} mr="6px" width="18px" height="18px"></CImage>
        <StyledText fontWeight5 fontSize="12px">
          {name}
        </StyledText>
      </FlexCenter>
      <RemoveIcon fill={theme.basicBlack} onClick={onRemove}></RemoveIcon>
    </FavoriteItemBox>
  );
};

type PropsForBar = {
  dataList: Chain[] | TokenVo[];
  onChosed: (data: Chain | TokenVo) => void;
  onRemove: (name: string) => void;
  clearAllLike: () => void;
};
export const FavoritesBar = ({ dataList, onChosed,  onRemove, clearAllLike}: PropsForBar) => {
  return (
    <>
      <HeightBlock></HeightBlock>
      {dataList.length > 0 && (
        <FavoritesBox>
          <StyledText
            fontSize="12px"
            lineHeight="32px"
            fontWeight5
            mr="12px"
            mt="6px"
          >
            Favorites
          </StyledText>
          <StyledContainer flex flex1="1" flexWrap="wrap">
            {dataList.map((item, index) => (
              <FavoriteItem
                key={index}
                imgUrl={item.url}
                name={(item as TokenVo).symbol || item.name}
                onClick={() => onChosed(item)}
                onRemove={() => onRemove((item as TokenVo).symbol || item.name)}
              ></FavoriteItem>
            ))}
          </StyledContainer>
          <ClearLabel
            pointer
            fontSize="12px"
            lineHeight="32px"
            fontWeight5
            mt="6px"
            onClick={clearAllLike}
          >
            Clear
          </ClearLabel>
        </FavoritesBox>
      )}
    </>
  );
};

export const FavoriteOffOn = ({
  isLike,
  onClick,
}: {
  isLike: boolean;
  onClick?: () => void;
}) => {
  const theme = useTheme()
  return (
    <FavoriteIcon
      onClick={() => onClick && onClick()}
      src={isLike ? (theme.iconForFavoritesOn) : (theme.iconForFavoritesOff)}
    ></FavoriteIcon>
  );
};
