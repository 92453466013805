import MetaMaskLogo from "assets/images/metamask-logo@3x.png";
import OkxLogo from "assets/images/okx_logo.png";
import CoinbaseLogo from "assets/images/coinbase.png";
import TokenpocketLogo from "assets/images/tokenpocket.png";
import BitkeepLogo from "assets/images/bitkeep.png";
import WalletconnectLogo from "assets/images/walletconnect.png";
import PaliLogo from 'assets/images/paliwallet.png'
import defWalletIcon from 'assets/images/defWallet.png'
import { InjectedConnector } from "wagmi/connectors/injected";
import { Chain as WagmiChain } from "wagmi";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { toast } from "react-hot-toast";

const supportWallet = [
    {
      name: "MetaMask",
    },
    {
      name: "BitgetWallet",
    },
    {
      name: "OkxWallet",
    },
    {
      name: "TokenPocket",
    },
    {
      name: "CoinBase"
    },
    {
      name: "Pali"
    },
    {
      name: "WalletConnect"
    }
]

export const getWalletLogo = (walletName: string) => {
    walletName = walletName.toLocaleLowerCase()
    if(walletName === 'metamask') {
      return MetaMaskLogo
    } else if(walletName === 'okxwallet') {
      return OkxLogo
    } else if(walletName === 'bitgetwallet') {
      return BitkeepLogo
    } else if(walletName === 'pali') {
      return PaliLogo
    } else if(walletName === 'coinbase') {
      return CoinbaseLogo
    } if(walletName === 'tokenpocket') {
      return TokenpocketLogo
    } else if(walletName === 'walletconnect') {
      return WalletconnectLogo
    } else {
      return defWalletIcon
    }
}

export const getWalletConnecter = (chains: WagmiChain[], chosedChainId: number) => {
    const tempConnectors: any = []
    supportWallet.forEach((item) => {
        const walletName = item.name.toLocaleLowerCase()
        if(walletName === 'walletconnect') {
            if(chosedChainId > -1) {
                const tempChainList = [...chains]
                const index = tempChainList.findIndex((item: WagmiChain) => item.id === chosedChainId)
                if(index > -1) {
                  const curChain = tempChainList.splice(index, 1)[0]
                  tempChainList.unshift(curChain)
                }
        
                const connectorss = new WalletConnectConnector({
                  chains: tempChainList,
                  options: {
                    projectId: '565227bd5a9f33e793661fa48e7570a2'
                  },
                })
                tempConnectors.push(connectorss)
            }
        } else {
            tempConnectors.push(
                new InjectedConnector({
                    chains: chains,
                    options: {
                        name: item.name,
                        shimDisconnect: true,
                        getProvider: () => {
                            if(typeof window === 'undefined') return undefined
                            if(!(window as any).ethereum) return undefined
                            const defaultEthereum = (window as any).ethereum
                            const isTokenPocket = defaultEthereum.isTokenPocket
                            const isCoinbase = defaultEthereum.providers
                            const isOkx = (window as any).okxwallet !== undefined
                            const isPali = (window as any).pali !== undefined
                            if(walletName === 'metamask') {
                                let curEthereum = defaultEthereum
                                if(isTokenPocket) return undefined
                                if(isOkx) return undefined
                                if(isPali) {
                                  if(curEthereum.wallet && curEthereum.wallet === 'pali-v2') {
                                    return undefined
                                  }
                                }
                                if(!isTokenPocket && isCoinbase) {
                                  const index = defaultEthereum.providers.findIndex((item: any) => item.isMetaMask)
                                  if(index > -1) {
                                      curEthereum = defaultEthereum.providers[index]
                                  }
                                }
                                return curEthereum
                            } else if(walletName === 'coinbase') {
                                let curEthereum = defaultEthereum
                                if(isTokenPocket) return undefined
                                if(isOkx) return undefined
                                if(isPali) return undefined
                                if(!isCoinbase) return undefined
                                if(!isTokenPocket && isCoinbase) {
                                    const index = defaultEthereum.providers.findIndex((item: any) => item.isCoinbaseWallet)
                                    if(index > -1) {
                                        curEthereum = defaultEthereum.providers[index]
                                    }
                                }
                                return curEthereum
                            } else if(walletName === 'pali') {
                                if(defaultEthereum.wallet && defaultEthereum.wallet === 'pali-v2') {
                                  return defaultEthereum
                                } else {
                                  return undefined
                                }
                            } else if(walletName === 'bitgetwallet') {
                                return (window as any).bitkeep?.ethereum
                            } else if(walletName === 'okxwallet') {
                                return (window as any).okxwallet
                            } else if(walletName === 'tokenpocket') {
                                if(!isTokenPocket) return undefined
                                return defaultEthereum
                            }
                        }
                    }
                })
            )
        }
    })
    return tempConnectors
}

export const getWalletError = (data: any, args: any) => {
  if(data.name === 'UserRejectedRequestError') {
    toast.error('User rejected the request.',{
      position: 'top-right'
    })
  } else if(data.name === 'ConnectorNotFoundError') {
    const defaultEthereum = (window as any).ethereum
    const isCoinbase = defaultEthereum?.providers
    const isTokenPocket = defaultEthereum?.isTokenPocket
    const isOkx = (window as any).okxwallet !== undefined
    const isPali = (window as any).pali !== undefined
    let errorMsg = ''

    const exitError = `If you already have ${args.connector.name} installed, check your browser extension settings to make sure you have it enabled and that you have disabled any other browser extension wallets.`;
    const notInstallError = `Error: not install ${args.connector.name}`
    if (isOkx) {
      errorMsg = exitError
    } else if(isPali) {
      if(!defaultEthereum) {
        errorMsg = 'Error: You have disabled window.ethereum, and MetaMask is not installed.'
      }
    } else {
      if(isCoinbase || isTokenPocket) {
        errorMsg = exitError
      } else {
        errorMsg = notInstallError
      }
    }
    toast(
      errorMsg,
      {
        duration: 3000,
        position: 'top-right',
        icon: '⚠️'
      }
    );
  } else {
    toast.error(data.message, {
      position: 'top-right'
    })
  }
}