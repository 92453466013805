import {
  Card,
  FlexCenter,
  FlexItemsCenter,
  PopupWinBg,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import styled, { useTheme } from "styled-components";

import { ReactComponent as CopySvg } from "assets/svgs/icon-02-actions-copy.svg";
import { ReactComponent as ExplorerSvg } from "assets/svgs/icons-misc-explorer.svg";
import { ReactComponent as DeleteSvg } from "assets/svgs/icons-actions-delete.svg";
import { ReactComponent as SuccessSvg } from "assets/svgs/icons-misc-success.svg";
import { ReactComponent as FailedSvg } from "assets/svgs/icons-misc-failed.svg";
import { ReactComponent as ArrowDownSvg } from "assets/svgs/mobile/icons-arrows-bottom-03.svg";
import CImage from "components/CImage";
import { useOrder } from "hooks/useOrderProvider";
import { CatchType } from "types/baseType";
import moment from "moment";
import { shortStr } from "utils";
import { useSystemInfo } from "hooks/useSystemProvider";
import { useCallback, useEffect, useState } from "react";
import { removeOrder } from "utils/store";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Placeholder from "components/Placeholder";
import { useAccount } from "wagmi";
import { useWalletInfo } from "hooks/useWalletProvider";
import { useNavigate } from "react-router-dom";

const Transactions = () => {
  const { orderList } = useOrder();
  const { isConnected } = useAccount();
  const { nearWalletInfo, openWalletWin } = useWalletInfo();

  const navigate = useNavigate();
  const fnGoToSwap = () => {
    navigate("/");
  };

  const getIsConnected = () => {
    return isConnected || nearWalletInfo?.isConnected;
  };
  return (
    <TransactionsBox>
      {getIsConnected() && !!orderList.length && (
        <>
          <StyledText interRegular fontSize="18px">
            All Orders
          </StyledText>
          <TableRowList mt="20px">
            {orderList.map((item, index) => (
              <TableRowItem key={index} detail={item}></TableRowItem>
            ))}
          </TableRowList>
        </>
      )}
      {!getIsConnected() && (
        <Placeholder
          width="100%"
          btnClick={openWalletWin}
          preset={1}
        ></Placeholder>
      )}
      {getIsConnected() && !orderList.length && (
        <Placeholder
          width="100%"
          btnClick={fnGoToSwap}
          preset={2}
        ></Placeholder>
      )}
    </TransactionsBox>
  );
};

const TableRowItem = ({ detail }: { detail: CatchType }) => {
  const theme = useTheme()
  const { externalChainInfo } = useSystemInfo();
  const getOriginParams = () => {
    return detail.originParams;
  };
  const checkSuccessful = () => {
    return detail.status === "successful";
  };

  const openBrowser = useCallback(() => {
    let url = "";
    if (detail.originParams.fromChain.name === "NEAR") {
      url = `https://explorer.near.org/transactions/${detail.certHash}`;
    } else {
      const curObj = externalChainInfo.find(
        (item) => item.name === detail.originParams.fromChain.name
      );
      if (curObj) {
        const blockExplorerPathHash = "/tx/";
        url = `${curObj.blockBrowserUrls[0]}${blockExplorerPathHash}${detail.certHash}`;
      }
    }
    if (url) {
      (window as any).open(url, "_blank");
    }
  }, [detail.certHash, detail.originParams.fromChain.name, externalChainInfo]);

  const openBrowserForAddress = useCallback(
    (type: string) => {
      let url = "";
      if (type === "from") {
        const chainName = detail.originParams.fromChain.name;
        if (chainName === "NEAR") {
          url = `https://explorer.near.org/accounts/${detail.originParams.nearAddress}`;
        } else {
          const curObj = externalChainInfo.find(
            (item) => item.name === chainName
          );
          if (curObj) {
            url = `${curObj.blockBrowserUrls[0]}/address/${detail.originParams.evmAddress}`;
          }
        }
      } else {
        const chainName = detail.originParams.toChain.name;
        if (chainName === "NEAR") {
          url = `https://explorer.near.org/accounts/${detail.originParams.nearAddress}`;
        } else {
          const curObj = externalChainInfo.find(
            (item) => item.name === chainName
          );
          if (curObj) {
            url = `${curObj.blockBrowserUrls[0]}/address/${detail.originParams.evmAddress}`;
          }
        }
      }
      if (url) {
        (window as any).open(url, "_blank");
      }
    },
    [
      detail.originParams.evmAddress,
      detail.originParams.fromChain.name,
      detail.originParams.nearAddress,
      detail.originParams.toChain.name,
      externalChainInfo,
    ]
  );

  const fnRemoveCatch = () => {
    removeOrder(detail.uuid);
    fnCloseConfirm();
  };

  const [confirmOpen, setConfirmOpen] = useState(false)
  const fnOpenConfirm = () => {
    setConfirmOpen(true)
  }
  const fnCloseConfirm = () => {
    setConfirmOpen(false)
  }
  return (
    <TableRow>
      <FlexCenter p="20px 15px 25px" justifyContent="flex-start !important;">
        <StyledContainer
          flex1="2"
          fontSize="12px"
          fontWeight5
          flex
          alignItems="center"
        >
          {checkSuccessful() ? (
            <SuccessSvg></SuccessSvg>
          ) : (
            <FailedSvg fill={theme.orderPending}></FailedSvg>
          )}
          <StyledContainer ml="8px">
            <StyledText lineHeight={1.17} color={checkSuccessful() ? theme.basicGreen : theme.orderPending}>Swap</StyledText>
            <StyledText
              lineHeight={1.17}
              display="block"
              mb="6px"
              textTransform="capitalize"
              color={checkSuccessful() ? theme.basicGreen : theme.orderPending}
            >
              {detail.status}
            </StyledText>
            <TimeLabel interRegular fontWeight4>
              {moment(detail.date).format("DD MMM YY, HH:mm")}
            </TimeLabel>
          </StyledContainer>
        </StyledContainer>

        <StyledContainer>
          <FlexCenter justifyContent="flex-start !important;">
            <CImage
              src={getOriginParams().fromSymbol.url || ""}
              width="26px"
              height="26px"
              mr="8px"
            ></CImage>
            <StyledContainer>
              <StyledText fontSize="12px" fontWeight5>
                {getOriginParams().fromAmount}{" "}
                {getOriginParams().fromSymbol.symbol}
              </StyledText>
              <FlexCenter justifyContent="flex-start !important" mt="3px">
                <StyledText fontSize="12px" mr="5px">
                  on {getOriginParams().fromChain.fullName}
                </StyledText>
                <ExplorerSvg
                  style={{ cursor: "pointer" }}
                  onClick={() => openBrowserForAddress("from")}
                ></ExplorerSvg>
              </FlexCenter>
            </StyledContainer>
          </FlexCenter>
          <ArrowDownSvg fill={theme.basicBlack} style={{margin: '10px 8px'}}></ArrowDownSvg>
          <FlexCenter justifyContent="flex-start !important;">
            <CImage
              src={getOriginParams().toSymbol.url || ""}
              width="26px"
              height="26px"
              mr="8px"
            ></CImage>
            <StyledContainer>
              <StyledText fontSize="12px" fontWeight5>
                {getOriginParams().toAmount} {getOriginParams().toSymbol.symbol}
              </StyledText>
              <FlexCenter justifyContent="flex-start !important" mt="3px">
                <StyledText fontSize="12px" mr="5px">
                  {getOriginParams().toChain.fullName}
                </StyledText>
                <ExplorerSvg
                  style={{ cursor: "pointer" }}
                  onClick={() => openBrowserForAddress("to")}
                ></ExplorerSvg>
              </FlexCenter>
            </StyledContainer>
          </FlexCenter>
        </StyledContainer>
      </FlexCenter>

      <FlexItemsCenter p="15px 15px 20px" borderTop={`1px solid ${theme.lightGrey}`}>
        <StyledContainer>
          <HashHandlerItem
            certHash={detail.certHash || ""}
            openBrowser={openBrowser}
          ></HashHandlerItem>
        </StyledContainer>
        <StyledContainer flex1="1" flex justifyContent="flex-end">
          {/* {!checkSuccessful() && <HelpBtn>Help</HelpBtn>} */}
          <DeleteSvgWrapper onClick={fnOpenConfirm}></DeleteSvgWrapper>
        </StyledContainer>
      </FlexItemsCenter>

      {
        confirmOpen && <ConfirmDelOrder onCancel={fnCloseConfirm} onConfirm={fnRemoveCatch}></ConfirmDelOrder>
      }
    </TableRow>
  );
};

export const HashHandlerItem = ({
  certHash,
  openBrowser,
}: {
  certHash: string;
  openBrowser: () => void;
}) => {
  const theme = useTheme()
  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  }, [isCopy]);

  return (
    <HashHandlerBox>
      <StyledContainer width="110px" ml="10px">
        {isCopy ? "Copied" : certHash ? shortStr(certHash, 4) : ""}
      </StyledContainer>
      <CopyToClipboard text={certHash || ""} onCopy={() => setIsCopy(true)}>
        <ImgBox>
          <CopySvg fill={theme.basicBlack}></CopySvg>
        </ImgBox>
      </CopyToClipboard>
      <ImgBox ml="3px" mr="4px" onClick={openBrowser}>
        <ExplorerSvg style={{width: '12px', height: '12px'}} fill={theme.basicBlack}></ExplorerSvg>
      </ImgBox>
    </HashHandlerBox>
  );
};

type ConfirmProps = {
  onConfirm: () => void;
  onCancel: () => void;
}
const ConfirmDelOrder = ({onConfirm, onCancel}:ConfirmProps) => {
  return <ConfirmBox>
    <StyledText fontSize="12px" interRegular mb="20px">Delete the order?</StyledText>
    <FlexCenter>
      <ConfirmBtn onClick={onConfirm}>Confirm</ConfirmBtn>
      <CnacelBtn onClick={onCancel}>Cancel</CnacelBtn>
    </FlexCenter>
  </ConfirmBox>
}

const TransactionsBox = styled(StyledContainer)`
  margin: 0 auto;
`;

const TableRowList = styled(StyledContainer)`
  // overflow: auto;
`;

const TableRow = styled(Card)`
  padding: 0;
  margin-bottom: 10px;

  font-size: 12px;
  font-weight: 500;

  position: relative;
  overflow: hidden;
`;

const HashHandlerBox = styled(StyledContainer)`
  background-color: ${({theme}) => theme.basicGrey};
  border-radius: 6px;
  box-sizing: border-box;
  height: 36px;

  display: inline-flex;
  align-items: center;
`;

const ImgBox = styled(FlexCenter)`
  box-sizing: border-box;
  padding: 8px;
  border-radius: 2px;
  background-color: ${({theme}) => theme.orderImgBox};
  cursor: pointer;
`;

const DeleteSvgWrapper = styled(DeleteSvg)`
  padding: 6px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.orderDelBox};
  cursor: pointer;
  fill: ${({theme}) => theme.orderDelFill};
`;

const HelpBtn = styled(StyledContainer)`
  padding: 8px 15px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.basicGrey};
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  cursor: pointer;
`;

const TimeLabel = styled(StyledText)`
  color:  ${({theme}) => theme.basicBlack};
`

const ConfirmBox = styled(PopupWinBg)`
  position: absolute;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.confirmBg};
`

const CnacelBtn = styled(HelpBtn)`
  margin-right: 0;
  padding: 8px 8.5px;
  margin-left: 5px;
`

const ConfirmBtn = styled(CnacelBtn)`
  padding: 8px 8.5px;
  margin-left: 0px;
  background-color: ${({theme}) => theme.orderDelBox};
  color: ${({theme}) => theme.orderDelFill};
`

export default Transactions;
