import {
  BaseContentScreenSection,
  BaseTitle,
  Card,
  Column,
  FlexCenter,
  FlexItemsCenter,
  Row,
} from "style/preset";

import CSelect from "components/CSelect";
import CInputNumber from "components/CInputNumber";
import BalanceRate from "components/BalanceRate";
import StyledContainer from "style/styledContainer";
import StatusBar from "components/StatusBar";
import MainBtn from "components/MainBtn";
import styled, { useTheme } from "styled-components";

import AssetPopupWin from "components/AssetPopupWin";
import { useCallback, useEffect, useRef, useState } from "react";
import LoadingWin from "components/LoadingWin";
import { AssetTpye, CurWalletObj, DefObj, QuoteParamsType } from "types/baseType";
import { Chain, TokenVo } from "@chainge/sdk";
import { OnValueChange } from "react-number-format";
import { useSystemInfo } from "hooks/useSystemProvider";
import useDebounce from "hooks/useDebounce";
import { getQuote } from "./quoteService";
import { useAccount } from "wagmi";
import { useWalletInfo } from "hooks/useWalletProvider";
import { getEvmBalance, getNearBalance } from "./balanceService";
import { allEvmExecute, allNearExecute, coreExecuteForAllNear, coreExecuteForOnlyNear, onlyNearExecute } from "service/executeService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getStore, removeOrder, removeStore } from "utils/store";
import SlippageWarningWin from "components/SlippageWarningWin";
import chainge from "utils/sdk";
import StyledText from "style/styledText";
import { formatNum, getSearchParams } from "utils";
import { ReactComponent as SwitchSvg } from "assets/svgs/switch.svg";
import MinReceive from "components/MinReceive";

const Home = () => {
  // ------------------ base info start --------------------------------------
  const theme = useTheme()
  const { feeLevel = 0, tokens = {}, chains, updateChosedChainId, updateLoadingForExec } = useSystemInfo();
  // ------------------ base info end --------------------------------------

  // ------------------ chosed assets start--------------------------------------
  const [assetOpen, setAssetOpen] = useState(false);
  const [direction, setDirection] = useState("");
  const [assetType, setAssetType] = useState<AssetTpye>("chain");
  const fnChosedAssets = (type: AssetTpye, meta?: DefObj<string, any>) => {
    if (meta) {
      setDirection(meta.direction);
    }
    setAssetType(type);
    setAssetOpen(true);
  };

  const [fromChain, setFromChain] = useState<Chain>({} as Chain);
  const [fromSymbol, setFromSymbol] = useState<TokenVo>({} as TokenVo);

  const [toChain, setToChain] = useState<Chain>({} as Chain);
  const [toSymbol, setToSymbol] = useState<TokenVo>({} as TokenVo);
  const fnAssetChosed = (type: AssetTpye, chosedItem: Chain | TokenVo) => {
    if (type === "chain") {
      if (direction === "from") {
        setFromChain(chosedItem as Chain);
      } else {
        setToChain(chosedItem as Chain);
      }
    } else {
      if (direction === "from") {
        setFromSymbol(chosedItem as TokenVo);
      } else {
        setToSymbol(chosedItem as TokenVo);
      }
    }
    setAssetOpen(false);
  };

  const [defChainName, setDefChainName] = useState("");
  useEffect(() => {
    setDefChainName("");
    if (direction === "from") {
      if (fromChain?.name) {
        setDefChainName(fromChain?.name);
      }
    } else {
      if (toChain?.name) {
        setDefChainName(toChain?.name);
      }
    }
  }, [direction, fromChain?.name, toChain?.name]);

  const fnOpenChangeForAssets = (isOpen: boolean) => {
    setAssetOpen(isOpen);
  };

  useEffect(() => {
    if(fromSymbol?.symbol) {
      const curTokens = tokens[fromChain.name]
      if(curTokens && curTokens.length) {
        const curToken = curTokens.find(item => item.symbol === fromSymbol?.symbol)
        if(curToken) {
          setFromSymbol(curToken)
        } else {
          setFromSymbol({} as TokenVo)
        }
      } else {
        setFromSymbol({} as TokenVo)
      }
    }
  }, [fromChain?.name, fromSymbol?.symbol, tokens])

  useEffect(() => {
    if(toSymbol?.symbol) {
      const curTokens = tokens[toChain.name]
      if(curTokens && curTokens.length) {
        const curToken = curTokens.find(item => item.symbol === toSymbol?.symbol)
        if(curToken) {
          setToSymbol(curToken)
        } else {
          setToSymbol({} as TokenVo)
        }
      } else {
        setToSymbol({} as TokenVo)
      }
    }
  }, [toChain?.name, toSymbol?.symbol, tokens])

  useDebounce(() => {
    if(fromChain?.name) {
      updateChosedChainId(fromChain.chainId)
    }
  }, 500, [fromChain])
  // ------------------ chosed assets end ----------------------------------------

  // ------------------ fromamount start ----------------------------------------
  const [fromAmount, setFromAmount] = useState("");
  const fnFromAmountChange: OnValueChange = ({ value }) => {
    setFromAmount(value);
  };
  const maxClick = async () => {
    setFromAmount(balance);
  };
  // ------------------ fromamount end ----------------------------------------

  // ------------------ fromamount start ----------------------------------------
  const [toAmount, setToAmount] = useState("");
  const [toDecimalScale, setToDecimalScale] = useState(4);
  useEffect(() => {
    if (["USDT", "USDC"].includes(toSymbol.symbol)) {
      setToDecimalScale(2);
    } else if (["BTC"].includes(toSymbol.symbol)) {
      setToDecimalScale(6);
    } else {
      setToDecimalScale(4);
    }
  }, [toSymbol.symbol, toAmount]);
  // ------------------ fromamount end ----------------------------------------


  // ---------------------- USDT price begin --------------------------------------------------
  const [fromPrice, setFromPrice] = useState('')
  const [toPrice, setToPrice] = useState('')

  useDebounce(async () => {
    if(fromSymbol?.symbol && fromAmount && +fromAmount > 0) {
      const {code, data} = await chainge.getTokenPrice(fromSymbol?.symbol)
      if(code === 200) {
        const price = +((data as any).price || 0)
        setFromPrice('$' + formatNum((+fromAmount) * price, 2))
      }
    } else {
      setFromPrice("")
    }
  }, 200, [fromAmount, fromSymbol?.symbol])

  useDebounce(async () => {
    if(toSymbol?.symbol && toAmount && +toAmount > 0) {
      const {code, data} = await chainge.getTokenPrice(toSymbol?.symbol)
      if(code === 200) {
        const price = +((data as any).price || 0)
        setToPrice('$' + formatNum((+toAmount) * price, 2))
      }
    } else {
      setToPrice("")
    }
  }, 200, [toAmount, toSymbol?.symbol])

  // ---------------------- USDT price end  ---------------------------------------------------

  // ------------------ Quote start ----------------------------------------
  const [loadingForQuote, setLoadingForQuote] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [gas, setGas] = useState("");
  const [slippage, setSlippage] = useState("");
  const [minAmount, setMinAmount] = useState("");
  useDebounce(
    async () => {
      setToAmount("");
      setGas("");
      setSlippage("");
      setErrorMsg("");
      setMinAmount("")
      if (
        fromChain.name &&
        toChain.name &&
        fromSymbol?.symbol &&
        toSymbol?.symbol &&
        fromAmount &&
        +fromAmount > 0
      ) {
        const quoteParams: QuoteParamsType = {
          fromChain: fromChain.name,
          toChain: toChain.name,
          fromSymbol: fromSymbol?.symbol,
          toSymbol: toSymbol?.symbol,
          fromAmount: fromAmount,
          feeLevel: feeLevel,
        };
        try {
          setLoadingForQuote(true);
          const result = await getQuote(quoteParams);
          if (result?.msg) {
            setErrorMsg(result.msg);
          } else {
            const { gas = 0, toAmount = 0, slippage = 0, amountOutMin = 0 } = result || {};
            setGas((+gas).toFixed(16) + "");
            if (+slippage !== 0) {
              setSlippage((+slippage * 100).toFixed(16));
            }
            setToAmount(toAmount + "");
            setErrorMsg("");
            if(amountOutMin > 0) {
              setMinAmount(amountOutMin + '')
            }
          }
        } catch (error) {
          setErrorMsg("Network error, try again!");
          console.error(error);
        } finally {
          setLoadingForQuote(false);
        }
      }
    },
    300,
    [
      fromChain?.name,
      toChain?.name,
      fromSymbol?.symbol,
      toSymbol?.symbol,
      fromAmount,
    ]
  );
  // ------------------ Quote end ----------------------------------------

  // ------------------ balance start ----------------------------------------
  const { address: evmAddress = '', connector: activeConnector } = useAccount()
  const { nearWalletInfo } = useWalletInfo()
  const [loadingForBalance, setLoadingForBalance] = useState(false);
  const [balance, setBalance] = useState("");
  const fnGetBalance = useCallback(async () => {
    try {
      setLoadingForBalance(true);
      setBalance('')
      let result = ''
      if(fromChain?.name === 'NEAR') {
        if(nearWalletInfo) {
          result = await getNearBalance(nearWalletInfo, fromSymbol)
        }
      } else {
        if(!evmAddress) return
        result = await getEvmBalance(evmAddress, [fromSymbol]);
      }
      setBalance(result);
    } catch (error) {
      setBalance('')
    } finally {
      setLoadingForBalance(false);
    }
  }, [evmAddress, fromChain?.name, fromSymbol, nearWalletInfo]);

  useDebounce(
    () => {
      if (fromChain.name && fromSymbol && fromSymbol.symbol) {
        fnGetBalance();
      }
    },
    150,
    [fromChain.name, fromSymbol, fnGetBalance]
  );

  // ------------------ balance end ----------------------------------------

  // ------------------ swap execute bagin ---------------------------------
  const [uuidForNear, setUuidForNear] = useState('')
  const [loadingForExec, setLoadingForExec] = useState(false)
  const slippageWinRef = useRef(null)
  const [executeType, setExecuteType] = useState('')
  const fnExecute = async (type: string) => {
    setExecuteType(type)
    if(slippage && +slippage > 5) {
      if(slippageWinRef) {
        (slippageWinRef as any).current.openWin(slippage)
      }
      return
    }
    fnCoreExecute(type)
  }

  const fnCoreExecute = async(tempType: string = '') => {
    if(!(tempType || executeType)) return
    const type = tempType || executeType
    const params = {
      fromChain,
      fromSymbol,
      toChain,
      toSymbol,
      fromAmount,
      toAmount,
      feeLevel,
      evmAddress: evmAddress || '',
      nearAddress: nearWalletInfo?.userAddress || '',
      referralCode: refCode
    }
    const callbackOptions = {
      lockCb: (loading: boolean) =>{
        setLoadingForExec(loading)
      },
      refreshBalance: () => {
        fnGetBalance()
      },
      updateUrlQuery: (uuid: string) => {
        setUuidForNear(uuid)
      }
    }
    const curProvider = await activeConnector?.getProvider()
    if(type === 'all_evm') {
      allEvmExecute(curProvider, params, callbackOptions)
    } else if(type === 'all_near') {
      allNearExecute(params, nearWalletInfo as CurWalletObj, callbackOptions)
    } else {
      onlyNearExecute(curProvider, params, nearWalletInfo as CurWalletObj, callbackOptions)
    }
    // clear formamount
    setFromAmount('')
  }

  const fnContinueExecute = () => {
    fnCoreExecute()
  }

  useEffect(() => {
    updateLoadingForExec(loadingForExec)
  }, [loadingForExec, updateLoadingForExec])
  // ------------------ swap execute end ---------------------------------


  // --------------------- url params start ------------------------------
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [refCode, setRefCode] = useState('')
  useEffect(() => {
    let queryStr = [];
    if (fromChain.name) {
      queryStr.push(`fromChain=${fromChain.name}`);
    }
    if (toChain.name) {
      queryStr.push(`toChain=${toChain.name}`);
    }
    if (fromSymbol?.symbol) {
      queryStr.push(`fromToken=${fromSymbol?.symbol}`);
    }
    if (toSymbol?.symbol) {
      queryStr.push(`toToken=${toSymbol?.symbol}`);
    }
    if (fromAmount) {
      queryStr.push(`fromAmount=${fromAmount}`);
    }
    if(uuidForNear) {
      queryStr.push(`uuid=${uuidForNear}`);
    }

    if(refCode) {
      queryStr.push(`ref=${refCode}`)
    }

    if (queryStr.length) {
      sessionStorage.setItem('transParams', `${queryStr.join("&")}`)
      navigate(`/?${queryStr.join("&")}`);
    }

  }, [fromChain, toChain, fromSymbol, toSymbol, fromAmount, uuidForNear, refCode, navigate]);


  const [isFirst, setIsFirst] = useState(false)
  useEffect(() => {
    if (!isFirst && chains.length && Object.keys(tokens).length && tokens['FSN'].length > 100) {
      setIsFirst(true)
      let defFromChain = searchParams.get("fromChain");
      let defToChain = searchParams.get("toChain");
      let defFromToken = searchParams.get("fromToken");
      let defToToken = searchParams.get("toToken");
      let defFromAmount = searchParams.get("fromAmount");

      if(!defFromChain && !defToChain && !defFromToken && !defToToken) {
        const sourceParams = sessionStorage.getItem('transParams')
        if(sourceParams) {
          defFromChain = getSearchParams('fromChain', sourceParams);
          defToChain = getSearchParams('toChain', sourceParams);
          defFromToken = getSearchParams('fromToken', sourceParams);
          defToToken = getSearchParams('toToken', sourceParams);
          defFromAmount = getSearchParams('fromAmount', sourceParams);

          setRefCode(getSearchParams('ref', sourceParams))
        }
      }

      if (!defFromChain && !defToChain && !defFromToken && !defToToken) {
        defFromChain = "ETH";
        defToChain = "BSC";
        defFromToken = "USDT";
        defToToken = "USDT";
      } else {
        if (defFromChain) defFromChain = defFromChain.toUpperCase();
        if (defToChain) defToChain = defToChain.toUpperCase();
      }

      if (defFromChain) {
        const tempFromChain = chains.find((item) => item.name === defFromChain);
        if(tempFromChain) {
          setFromChain(tempFromChain as Chain);
        }
      }

      if (defToChain) {
        const tempToChain = chains.find((item) => item.name === defToChain);
        if(tempToChain) {
          setToChain(tempToChain as Chain);
        }
      }

      if (defFromToken) {
        const tempFromTokens = tokens[defFromChain || "ETH"] || [];
        const tempFromSymol = tempFromTokens.find(
          (item) => item.symbol === defFromToken
        );
        if(tempFromSymol) {
          setFromSymbol(tempFromSymol as TokenVo);
        }
      }

      if (defToToken) {
        const tempToTokens = tokens[defToChain || "BSC"];
        const tempToSymol = tempToTokens.find(
          (item) => item.symbol === defToToken
        );
        if(tempToSymol) {
          setToSymbol(tempToSymol as TokenVo);
        }
      }

      if (defFromAmount) {
        setFromAmount(defFromAmount);
      }

      // ref
      const refCode = searchParams.get("ref");
      if(refCode) {
        setRefCode(refCode)
      }
    }
  }, [chains, searchParams, tokens, isFirst]);

  const [uuidNearSuccess, setUuidNearSuccess] = useState('')
  const [txHashNearSuccess, setTxHashNearSuccess] = useState('')
  useEffect(() => {
    if(uuidForNear) return
    let uuidStr = searchParams.get("uuid");
    let certHash = searchParams.get("transactionHashes");
    let errorCode = searchParams.get("errorCode")
    if(uuidStr && errorCode === 'userRejected') {
      removeOrder(uuidStr)
      return
    }
    if(uuidStr) {
      setUuidNearSuccess(uuidStr)
    }
    if(certHash) {
      setTxHashNearSuccess(certHash)
    }
    uuidStr = uuidNearSuccess
    certHash = txHashNearSuccess

    if(nearWalletInfo && nearWalletInfo.wallet && nearWalletInfo.userAddress) {
      const params = getStore('chainge.originParams')
      if(params.uuid === uuidStr) {
        const allParams = params.allParams
        const userAddress = nearWalletInfo.userAddress
        const callbackOptions = {
          lockCb: (loading: boolean) =>{
            setLoadingForExec(loading)
          },
          refreshBalance: () => {
          },
          updateUrlQuery: (uuid: string) => {
          }
        }
        if(allParams.fromChain.name === allParams.toChain.name && allParams.fromChain.name === 'NEAR') {
          coreExecuteForAllNear(allParams, certHash, userAddress, uuidStr, nearWalletInfo, callbackOptions)
        } 
        if(allParams.fromChain.name !== allParams.toChain.name && allParams.fromChain.name === 'NEAR') {
          coreExecuteForOnlyNear(allParams, certHash, userAddress, uuidStr, nearWalletInfo, callbackOptions)
        }
        setUuidNearSuccess('')
        setTxHashNearSuccess('')
        removeStore('chainge.originParams')
      }
    }
  }, [nearWalletInfo, searchParams, txHashNearSuccess, uuidForNear, uuidNearSuccess])
  // --------------------- url params end ------------------------------

  // --------------------- switch begin ------------------------------
  const fnSwitch = () => {
    const fromChainTemp = fromChain
    const fromSymbolTemp = fromSymbol
    const toChainTemp = toChain
    const toSymbolTemp = toSymbol

    setFromChain(toChainTemp)
    setFromSymbol(toSymbolTemp)
    setToChain(fromChainTemp)
    setToSymbol(fromSymbolTemp)

    // if(fromSymbol.symbol === 'USDT') {
    //   setToSymbol({} as TokenVo)
    // } else {
    //   setToSymbol(fromSymbolTemp)
    // }
  }
  // --------------------- switch end --------------------------------

  return (
    <>
      <HomeBox>
        {/* Left card */}
        <CardWrapper flex1="1.16" width="100%" flex>
          <Column flex1="1">
            <BaseTitle mb="12px">You will swap from</BaseTitle>
            <Row mb="5px">
              <CSelect
                onClick={fnChosedAssets}
                type="chain"
                meta={{ direction: "from" }}
                url={fromChain?.url}
                name={fromChain?.fullName}
                label="Network"
              ></CSelect>
            </Row>
            <Row mb="10px">
              <CSelect
                type="symbol"
                url={fromSymbol?.url}
                name={fromSymbol?.symbol}
                fullName={fromSymbol?.name}
                address={fromSymbol?.address}
                meta={{ direction: "from" }}
                onClick={fnChosedAssets}
                label="Asset"
              ></CSelect>
            </Row>

            <FlexCenter><SwitchSvg style={{'cursor': 'pointer'}} onClick={fnSwitch}></SwitchSvg></FlexCenter>

            <BaseTitle mb="12px">Into</BaseTitle>
            <Row mb="5px">
              <CSelect
                onClick={fnChosedAssets}
                type="chain"
                meta={{ direction: "to" }}
                url={toChain?.url}
                name={toChain?.fullName}
                label="Network"
              ></CSelect>
            </Row>
            <Row mb="47px">
              <CSelect
                type="symbol"
                url={toSymbol?.url}
                name={toSymbol?.symbol}
                fullName={toSymbol?.name}
                address={toSymbol?.address}
                meta={{ direction: "to" }}
                onClick={fnChosedAssets}
                label="Asset"
              ></CSelect>
            </Row>

            <FlexItemsCenter mb="10px">
              <BaseTitle>You will pay</BaseTitle>
              <StyledText fontSize="12px" mr="10px" interRegular>{fromPrice}</StyledText>
            </FlexItemsCenter>
            <CInputNumber
              value={fromAmount}
              symbolName={fromSymbol?.symbol}
              disabled={loadingForQuote}
              onValueChange={fnFromAmountChange}
            ></CInputNumber>
            <BalanceRate
              type="balance"
              loading={loadingForBalance}
              value={balance}
              fromAmount={fromAmount}
              fromSymbol={fromSymbol?.symbol}
              maxClick={maxClick}
            ></BalanceRate>
          </Column>
          <StyledContainer width="13%"></StyledContainer>
        </CardWrapper>
        {/* Right card */}
        <CardWrapper position="relative" flex1="1" ml="-25px">
          <FlexItemsCenter mb="10px">
            <BaseTitle>You will receive</BaseTitle>
            <StyledText fontSize="12px" interRegular>{toPrice}</StyledText>
          </FlexItemsCenter>
          <CInputNumber
            type="text"
            symbolName={toSymbol?.symbol}
            decimalScale={toDecimalScale}
            value={toAmount}
          ></CInputNumber>
          <MinReceive minAmount={minAmount} toSymbol={toSymbol?.symbol}></MinReceive>
          <BalanceRate
            type="rate"
            loading={loadingForQuote}
            fromAmount={fromAmount}
            fromSymbol={fromSymbol?.symbol}
            toAmount={toAmount}
            toSymbol={toSymbol?.symbol}
            mt="5px"
          ></BalanceRate>
          <StyledContainer height="40px"></StyledContainer>
          <StatusBar isGas value={gas} suffix={` ${toSymbol?.symbol || ''}`}></StatusBar>
          <StyledContainer height="20px"></StyledContainer>
          <StatusBar isGas={false} value={slippage} suffix='%'>
            <StyledContainer
              pointer
              fontSize="12px"
              fontWeight5
              color={theme.basicGreen}
              underline
              hover={"text-decoration-color:" + theme.basicGreen}
            >
              Need help?
            </StyledContainer>
          </StatusBar>
          <StyledContainer height="45px"></StyledContainer>
          <MainBtn onClick={fnExecute} balance={balance} fromAmount={fromAmount} toAmount={toAmount} fromChain={fromChain} toChain={toChain?.name} fromSymbol={fromSymbol?.symbol} toSymbol={toSymbol?.symbol} errMsg={errorMsg}></MainBtn>

          {(loadingForQuote || loadingForExec) && (
            <LoadingWin loading={true} mt="-100px"></LoadingWin>
          )}
          <SlippageWarningWin innerRef={slippageWinRef} onClick={fnContinueExecute}></SlippageWarningWin>
        </CardWrapper>
      </HomeBox>

      <AssetPopupWin
        type={assetType}
        open={assetOpen}
        direction={direction}
        onChosed={fnAssetChosed}
        onOpenChange={fnOpenChangeForAssets}
        chainName={defChainName}
      ></AssetPopupWin>
    </>
  );
};

const HomeBox = styled(BaseContentScreenSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const CardWrapper = styled(Card)`
  @media (max-width: 800px) {
    flex: 1;
    margin: 0;
    box-sizing: border-box;
  }
`;

export default Home;
