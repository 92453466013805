import {
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import styled, { useTheme } from "styled-components";
import { ReactComponent as RemoveSvg } from "assets/svgs/icons-actions-close.svg";
import CImage from "components/CImage";
import StyledText from "style/styledText";
import { Chain, TokenVo } from "@chainge/sdk";

export const FavoritesBox = styled(StyledContainer)`
  padding: 5px 0px 20px;
`;

export const FavoriteItemBox = styled(FlexItemsCenter)`
  min-width: 100px;
  height: 32px;
  border-radius: 32px;
  margin-right: 5px;
  margin-top: 12px;
  padding: 0 8px;
  background-color: ${({theme}) => theme.basicGrey};

  cursor: pointer;
  &:first-child {
    margin-left: 10px;
  }
`;

export const TableRow = styled(FlexItemsCenter)`
  padding: 9px 10px;
  box-shadow: 0 1px 2px 0 ${({theme}) => theme.tableHeaderShadowForPcAsset};
  ${({theme}) => theme.tableHeaderBorderForPcAsset};
`;

export const TableTh = styled(StyledText)`
  font-size: 12px;
  line-height: 1.17;
  font-family: InterRegular;
  color: ${({theme}) => theme.deepGrey};
`

export const ContentScrollBox = styled(StyledContainer)`
  flex: 1;
  margin: 0 10px 40px;
  overflow: auto;
`;

export const AssetItemBox = styled(FlexItemsCenter)`
  font-size: 12px;
  font-weight: 500;
  background-color: ${({theme}) => theme.assetItemBoxForPcAsset};
  border-radius: 12px;
  height: 60px;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const FavoriteIcon = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0 15px;
`;

export const RemoveIcon = styled(RemoveSvg)`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

type Props = {
  imgUrl: string;
  name: string;
  onClick: () => void;
  onRemove: () => void;
};
export const FavoriteItem = ({ imgUrl, name, onClick, onRemove }: Props) => {
  const theme = useTheme()
  return (
    <FavoriteItemBox>
      <FlexCenter mr="10px" onClick={onClick}>
        <CImage src={imgUrl} mr="6px" width="18px" height="18px"></CImage>
        <StyledText fontWeight5 fontSize="12px">
          {name}
        </StyledText>
      </FlexCenter>
      <RemoveIcon fill={theme.basicBlack} onClick={onRemove}></RemoveIcon>
    </FavoriteItemBox>
  );
};

type PropsForBar = {
  dataList: Chain[] | TokenVo[];
  onChosed: (data: Chain | TokenVo) => void;
  onRemove: (name: string) => void;
  clearAllLike: () => void;
};
export const FavoritesBar = ({
  dataList,
  onChosed,
  onRemove,
  clearAllLike,
}: PropsForBar) => {
  const theme = useTheme()
  return (
    <>
      {dataList.length > 0 && (
        <FavoritesBox>
          <FlexItemsCenter>
            <StyledText
              fontSize="12px"
              fontWeight5
              mr="12px"
              ml="10px"
            >
              Favorites
            </StyledText>
            <StyledText
              pointer
              color={theme.basicGreen}
              fontSize="12px"
              fontWeight5
              mr="10px"
              onClick={clearAllLike}
            >
              Clear
            </StyledText>
          </FlexItemsCenter>
          <StyledContainer flex flex1="1" flexWrap="nowrap" overflowX="auto">
            {dataList.map((item, index) => (
              <FavoriteItem
                key={index}
                imgUrl={item.url}
                name={(item as TokenVo).symbol || item.name}
                onClick={() => onChosed(item)}
                onRemove={() => onRemove((item as TokenVo).symbol || item.name)}
              ></FavoriteItem>
            ))}
          </StyledContainer>
        </FavoritesBox>
      )}
    </>
  );
};

export const FavoriteOffOn = ({
  isLike,
  onClick,
}: {
  isLike: boolean;
  onClick?: () => void;
}) => {
  const theme = useTheme()
  return (
    <FavoriteIcon
      onClick={() => onClick && onClick()}
      src={isLike ? (theme.iconForFavoritesOn) : (theme.iconForFavoritesOff)}
    ></FavoriteIcon>
  );
};
