import styled, { useTheme } from "styled-components"
import { BaseLayoutScreenSection, basicBlack, basicWhite, FlexCenter, lightYellow } from "style/preset"

import NavBar from "./NavBar"
import ConnectBtn from "components/ConnectBtn"
import ThemeBtn from "components/ThemeBtn"
import StyledContainer from "style/styledContainer"

const Header = () => {
    const theme = useTheme()
    return <FlexBox>
        <FlexCenter>
            <LogoIcon src={theme.iconForLogo} alt="chainge_logo"></LogoIcon>
            {/* <WebFlag>Web</WebFlag> */}
        </FlexCenter>
        <StyledContainer position="absolute" left="50%" transform="translate(-50%, 0)">
            <NavBar></NavBar>
        </StyledContainer>
        <FlexCenter>
            <ConnectBtn></ConnectBtn>
            <ThemeBtn></ThemeBtn>
        </FlexCenter>
    </FlexBox>
}

const FlexBox = styled(BaseLayoutScreenSection)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 104px;
    margin-bottom: 10px;
`


const LogoIcon = styled.img`
    height: 40px;
`

const WebFlag = styled(StyledContainer)`
    font-size: 12px;
    padding: 4px 6px;
    animation: changeColor 7s infinite;
    color: ${basicBlack};

    @keyframes changeColor {
        0% {
            background-color: ${lightYellow};
        }
        25% {
            background-color: #E5EBF7;
        }
        75% {
            background-color: ${basicWhite};
        }
        100% {
            background-color: ${lightYellow};
        }
    }
`

export default Header