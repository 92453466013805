import { PulseLoader } from "react-spinners";
import { FlexCenter } from "style/preset";
import styled, { useTheme } from "styled-components";

type Props = {
  status: string;
};
const StatusBar = ({ status }: Props) => {
  const theme = useTheme();
  return (
    <StatusBarWrapper ml="11px">
      {status}
      <PulseLoader
        color={theme.statusBarLabel}
        cssOverride={{
          marginLeft: "5px",
        }}
        loading={true}
        margin={2}
        size={3}
        speedMultiplier={1}
      />
    </StatusBarWrapper>
  );
};

const StatusBarWrapper = styled(FlexCenter)`
  background-color: ${({ theme }) => theme.statusBar};
  border-radius: 6px;
  padding: 8px 10px;
  font-family: InterRegular;
  font-size: 12px;
  color: ${({ theme }) => theme.statusBarLabel};
  text-transform: capitalize;
`;

export default StatusBar;
