import Chainge, { ConfigOptions } from "@chainge/sdk";
export const appKey = '24uDK13ZJrfi1v2QAS4Jywd4Eyh3tb7XZ29Pqkjrth8jAUKVjVQnAayhePHNGcfx'

const config: ConfigOptions = {
    appKey: appKey,
}
const chainge = new Chainge(config)

export const updateConfigByDefault = () => {
    chainge.updateConfig({
        appKey: '24uDK13ZJrfi1v2QAS4Jywd4Eyh3tb7XZ29Pqkjrth8jAUKVjVQnAayhePHNGcfx'
    })
}

export default chainge