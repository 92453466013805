import { FlexCenter, FlexItemsCenter } from "style/preset";
import StyledText from "style/styledText";
import styled, { useTheme } from "styled-components";
import CImage from "components/CImage";
import StyledContainer from "style/styledContainer";
import { ReactComponent as RightSvg } from "assets/svgs/icons-arrows-right-02.svg";
import { Chain, useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useCallback, useEffect, useState } from "react";
import { reloadWin } from "utils";
import { getWalletLogo } from "utils/walletManage";

const NetworkList = () => {
  const { chain, chains } = useNetwork()
  const { connector } = useAccount();
  const [dataList, setDataList] = useState<Chain[]>([])
  const { switchNetworkAsync } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
  })

  useEffect(() => {
    setDataList(chains)
  }, [chains])

  const fnSwitchNetwork = useCallback(async (curNetwork: Chain) => {
    try {
      setTimeout(() => {
        reloadWin()
      }, 1000);
      await switchNetworkAsync?.(curNetwork.id);
    } catch (error) {
      console.log('error:', error)
    }
  }, [switchNetworkAsync])

  return (
    <ContentBox>
      <FlexCenter column m="0 0 25px">
        <ImgBox>
          <CImage src={getWalletLogo(connector?.name || '')} width="35px" height="35px"></CImage>
        </ImgBox>
        <StyledText mt="8px" interSemiBold fontSize="14px" fontWeight6>
          { connector?.name || 'Metamask'} 
        </StyledText>
      </FlexCenter>
      <NetworkListBox>
        {
          dataList.map((item, index) => <NetWorkItem key={index} onClick={() => fnSwitchNetwork(item)} name={item.name} isCurrent={item.id === chain?.id} logoUrl={(item as any).iconUrl}></NetWorkItem>)
        }
      </NetworkListBox>
    </ContentBox>
  );
};


type NetworkProps = {
  isCurrent: boolean
  name: string
  logoUrl: string
  onClick: () => void
}
const NetWorkItem = ({isCurrent, name, logoUrl, onClick}: NetworkProps) => {
  const theme = useTheme()
  return <NetWorkItemBox onClick={onClick}>
    <FlexCenter>
        <CImage src={logoUrl} width="22px" height="22px" mr="12px"></CImage>
        <StyledText fontSize="12px" fontWeight6 interRegular lineHeight="1.17">{name}</StyledText>
    </FlexCenter>
    {
      isCurrent ? <CurNetWork></CurNetWork> : <RightSvg fill={theme.basicBlack}></RightSvg>
    }
  </NetWorkItemBox>;
};

const ContentBox = styled(StyledContainer)`
  padding: 0 15px 48px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
`;

const ImgBox = styled(FlexCenter)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.walletIconBoxForNetwork};
`;

const NetworkListBox = styled(StyledContainer)`
  flex: 1;
  height: 0;
  overflow: auto;
`;

const NetWorkItemBox = styled(FlexItemsCenter)`
  padding: 14px 15px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.basicGrey};
  cursor: pointer;
  margin-bottom: 5px;
`;

const CurNetWork = styled(StyledContainer)`
  width: 12px;
  height: 12px;
  background-color: ${({theme}) => theme.basicGreen};
  border-radius: 50%;
`

export default NetworkList;
