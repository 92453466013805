import styled from "styled-components";
import { BaseLayoutScreenSection, basicBlack, basicWhite, FlexCenter, lightYellow } from "style/preset";

// import LogoPngIcon from "assets/images/mobile/anyswap-logo@3x.png";
import LogoPngIcon from "assets/images/mobile/symbol@3x.png";
import ConnectBtn from "components/Mobile/ConnectBtn";
import StyledContainer from "style/styledContainer";

const Header = () => {
  return (
    <>
      <FlexBox>
        <FlexCenter>
          <LogoIcon src={LogoPngIcon}></LogoIcon>
          {/* <WebFlag>Web</WebFlag> */}
        </FlexCenter>
        <FlexCenter>
          <ConnectBtn></ConnectBtn>
        </FlexCenter>
      </FlexBox>
      <StyledContainer height="70px" mb="15px"></StyledContainer>
    </>
  );
};

const FlexBox = styled(BaseLayoutScreenSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 20px;

  border-bottom: 1px solid ${({theme}) => theme.headerBorder};

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${({theme}) => theme.basicGrey};
`;

const LogoIcon = styled.img`
  height: 40px;
  position: relative;
  z-index: 1;
`;

const WebFlag = styled(StyledContainer)`
    font-size: 12px;
    padding: 4px 6px 4px 15px;
    animation: changeColor 7s infinite;
    margin-left: -11px;
    color: ${basicBlack};

    @keyframes changeColor {
        0% {
            background-color: ${lightYellow};
        }
        25% {
            background-color: #E5EBF7;
        }
        75% {
            background-color: ${basicWhite};
        }
        100% {
            background-color: ${lightYellow};
        }
    }
`

export default Header;
