import CImage from "components/CImage";
import {
  deepGrey,
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import { ReactComponent as RightSvg } from "assets/svgs/icons-arrows-right-02.svg";
import { ReactComponent as CopySvg } from "assets/svgs/icon-02-actions-copy.svg";
import { useWalletInfo } from "hooks/useWalletProvider";
import { shortStr } from "utils";
import { useCallback, useEffect, useState } from "react";
import { getNearBalance } from "service/nearService";
import CopyToClipboard from "react-copy-to-clipboard";
import { ContentBox, ImgBox, OptionItem } from "./StyledCommon";
import { useTheme } from "styled-components";

type Props = {
  openChosedNetwork?: () => void;
};

const WalletDetailForNear = ({ openChosedNetwork }: Props) => {
  const theme = useTheme()
  const { nearWalletInfo, closeNearWin } = useWalletInfo();
  const { userAddress = "", isConnected = false } = nearWalletInfo || {};

  const [balance, setBalance] = useState("0");
  const getBalance = useCallback(async () => {
    const result = await getNearBalance(userAddress);
    setBalance(result);
  }, [userAddress]);

  useEffect(() => {
    if (userAddress) {
      getBalance();
    }
  }, [getBalance, userAddress]);

  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  }, [isCopy]);

  return (
    <>
      {isConnected && (
        <ContentBox>
          <StyledContainer flex alignItems="center" mb="15px">
            <ImgBox>
              <CImage
                src={nearWalletInfo?.wallet?.metadata.iconUrl}
                width="35px"
                height="35px"
              ></CImage>
            </ImgBox>
            <StyledContainer flex1="1" flex column>
              <FlexItemsCenter>
                <StyledText fontSize="14px" fontWeight6 interSemiBold>
                  {nearWalletInfo?.wallet?.metadata.name}
                </StyledText>
                <StyledText
                  fontSize="12px"
                  fontWeight5
                  color="#ef233c"
                  pointer
                  onClick={() => closeNearWin()}
                >
                  Disconnect
                </StyledText>
              </FlexItemsCenter>
              <FlexCenter mt="6px" justifyContent="flex-start !important">
                <StyledText fontSize="12px" fontWeight5 mr="5px">
                  {(+balance).toFixed(6)} NEAR
                </StyledText>
                {/* <RightSvgWrapper></RightSvgWrapper> */}
              </FlexCenter>
            </StyledContainer>
          </StyledContainer>
          <OptionItem  onClick={() => openChosedNetwork && openChosedNetwork()}>
            <StyledContainer>
              <StyledText
                fontSize="12px"
                interRegular
                color={theme.deepGrey}
                display="block"
                mb="4px"
              >
                Network
              </StyledText>
              <StyledText
                fontSize="12px"
                interSemiBold
                fontWeight6
                lineHeight="1.17"
              >
                mainnet
              </StyledText>
            </StyledContainer>
            <RightSvg fill={theme.basicBlack}></RightSvg>
          </OptionItem>

          <CopyToClipboard
            text={userAddress || ""}
            onCopy={() => setIsCopy(true)}
          >
            <OptionItem mt="5px">
              <StyledContainer>
                <StyledText
                  fontSize="12px"
                  interRegular
                  color={theme.deepGrey}
                  display="block"
                  mb="4px"
                >
                  Address
                </StyledText>
                <StyledText
                  fontSize="12px"
                  interSemiBold
                  fontWeight6
                  lineHeight="1.17"
                >
                  {isCopy ? "Copied" : shortStr(userAddress, 6)}
                </StyledText>
              </StyledContainer>
              <CopySvg fill={theme.basicBlack}></CopySvg>
            </OptionItem>
          </CopyToClipboard>
        </ContentBox>
      )}
    </>
  );
};

export default WalletDetailForNear;
