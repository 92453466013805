import CImage from "components/CImage";
import {
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import StyledText from "style/styledText";
import styled, { useTheme } from "styled-components";
import { ReactComponent as ConnectedSvg } from "assets/svgs/icons-misc-connected.svg";
import { ReactComponent as RightSvg } from "assets/svgs/icons-arrows-right-02.svg";
import StyledContainer from "style/styledContainer";

type Props = {
  imgUrl: string;
  name: string;
  isOnly?: boolean;
  isConnected?: boolean;

  onClick?: () => void;
};

const WalletItem = ({
  imgUrl,
  name,
  isOnly = true,
  isConnected = false,
  onClick,
}: Props) => {
  const theme = useTheme()
  return (
    <Card onClick={() => !isConnected && onClick && onClick()}>
      <FlexCenter>
        <CImage src={imgUrl} width="40px" height="40px" mr="10px"></CImage>
        <StyledText interSemiBold fontSize="14px" fontWeight6>
          {name}
        </StyledText>
      </FlexCenter>
      {isConnected && <ConnectedNode></ConnectedNode>}
      {!isConnected && (
        <>
          {isOnly ? (
            <ConnectedBox>
              <span>Connect</span>
              <ConnectedSvg
                fill={theme.basicBlack}
                style={{ width: "14px", height: "14px" }}
              ></ConnectedSvg>
            </ConnectedBox>
          ) : (
            <ConnectedBox>
              <span>Manager</span>
              <RightSvg fill={theme.basicBlack} style={{ width: "10px", height: "10px" }}></RightSvg>
            </ConnectedBox>
          )}
        </>
      )}
    </Card>
  );
};

const ConnectedNode = () => {
  const theme = useTheme()
  return <FlexCenter>
    <StyledText fontSize="12px" color={theme.basicGreen} mr="8px">Connected</StyledText>
    <StyledContainer width="6px" height="6px" borderRadius="50%" backgroundColor={theme.basicGreen}></StyledContainer>
  </FlexCenter>;
};

const Card = styled(FlexItemsCenter)`
  padding: 15px 15px 15px 10px;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 ${({theme}) => theme.walletItemShadow};
  background-color: ${({theme}) => theme.basicWhite};
  cursor: pointer;
  margin-bottom: 8px;

  ${
    ({theme}) => theme.cardBorder
  };

  &:hover {
    box-shadow: 2px 8px 26px 0 ${({theme}) => theme.walletItemShadow };

    ${
      ({theme}) => theme.cardBorderHover
    };
  }
`;

const ConnectedBox = styled(FlexItemsCenter)`
  width: 100px;
  padding: 6px 10px 6px 8px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.basicGrey};
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
`;

export default WalletItem;
