import { Chain, ExternalChainInfo } from "@chainge/sdk";
import { useEffect, useState } from "react";
import { DefObj } from "types/baseType";
import { configureChains, createConfig, createStorage } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { useSystemInfo } from "../useSystemProvider";
import { Chain as WagmiChain } from "wagmi";
import { getWalletConnecter } from "utils/walletManage";

const useEvmWallet = () => {
  const { chains: suportChains, externalChainInfo, chosedChainId } = useSystemInfo();
  const [wagmiClient, setWagmiClient] = useState<any>();

  useEffect(() => {
    if (suportChains.length && externalChainInfo.length) {
      const wagmiChains = formatWagmiChains(suportChains, externalChainInfo);
      const { chains, publicClient, webSocketPublicClient } = configureChains(
        wagmiChains,
        [publicProvider()]
      );
      
      const tempConnectors: any = getWalletConnecter(chains, chosedChainId)

      // tempConnectors.push(new InjectedConnector({
      //   chains,
      //   options: {
      //     shimDisconnect: true,
      //   },
      // }))
      // if (typeof (window as any).okxwallet !== 'undefined') {
      //   tempConnectors.push(new InjectedConnector({
      //     chains,
      //     options: {
      //       name: 'okx wallet',
      //       shimDisconnect: true,
      //       getProvider: () => typeof window !== 'undefined' ? (window as any).okxwallet : undefined,
      //     },
      //   }))
      // }

      const client = createConfig({
        autoConnect: true,
        publicClient,
        webSocketPublicClient,
        connectors: tempConnectors,
        storage: createStorage({ storage: window.localStorage }),
      });
      setWagmiClient(client);
    }
  }, [suportChains, externalChainInfo, chosedChainId]);

  return wagmiClient;
};

const formatWagmiChains = (
  chains: Chain[],
  extendInfoList: ExternalChainInfo[]
) => {
  const extendInfoObj: DefObj<string, ExternalChainInfo> = {};
  extendInfoList.forEach((item) => {
    extendInfoObj[item["name"]] = item;
  });
  const chainList: WagmiChain[] = [];
  chains.forEach((item) => {
    const extendInfo = extendInfoObj[item.name];
    if (extendInfo) {
      const { decimals, name, symbol } = extendInfo.token;
      const tempChain: WagmiChain = {
        id: item.chainId,
        name: item.fullName,
        network: item.name,
        nativeCurrency: {
          decimals: decimals,
          name: name,
          symbol: symbol,
        },
        rpcUrls: {
          public: { http: extendInfo.rpcUrls },
          default: { http: extendInfo.rpcUrls },
        },
        blockExplorers: {
          default: { name: item.fullName, url: extendInfo.blockBrowserUrls[0] },
        },
      };
      chainList.push({...tempChain, iconUrl: item.url} as any);
    }
  });
  return chainList;
};

export default useEvmWallet;
