import CImage from "components/CImage";
import {
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import { ReactComponent as RightSvg } from "assets/svgs/icons-arrows-right-02.svg";
import { ReactComponent as CopySvg } from "assets/svgs/icon-02-actions-copy.svg";
import { useAccount, useBalance, useDisconnect, useNetwork } from "wagmi";
import { shortStr } from "utils";

import CopyToClipboard from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import { setStore } from "utils/store";
import { ContentBox, ImgBox, OptionItem } from "./StyledCommon";
import { useTheme } from "styled-components";
import { getWalletLogo } from "utils/walletManage";

type Props = {
  openChosedNetwork?: () => void;
};

const WalletDetail = ({ openChosedNetwork }: Props) => {
  const theme = useTheme()
  const { disconnect } = useDisconnect()
  const { address, connector, isConnected } = useAccount();
  const { data } = useBalance({ address });
  const { chain } = useNetwork()

  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  }, [isCopy]);

  const fnDisconnect = () => {
    setStore('metamask.connected', false)
    disconnect()
  }

  return (
    <>
      {isConnected && (
        <ContentBox>
          <StyledContainer flex alignItems="center" mb="15px">
            <ImgBox>
              <CImage src={getWalletLogo(connector?.name || '')} width="35px" height="35px"></CImage>
            </ImgBox>
            <StyledContainer flex1="1" flex column>
              <FlexItemsCenter>
                <StyledText fontSize="14px" fontWeight6 interSemiBold>
                  {connector?.name}
                </StyledText>
                <StyledText fontSize="12px" fontWeight5 color="#ef233c" pointer onClick={fnDisconnect}>
                  Disconnect
                </StyledText>
              </FlexItemsCenter>
              <FlexCenter mt="6px" justifyContent="flex-start !important">
                <StyledText fontSize="12px" fontWeight5 mr="5px">
                  {(+(data?.formatted || '0.0')).toFixed(6)} {data?.symbol}
                </StyledText>
                {/* <RightSvgWrapper></RightSvgWrapper> */}
              </FlexCenter>
            </StyledContainer>
          </StyledContainer>
          <OptionItem onClick={() => openChosedNetwork && openChosedNetwork()}>
            <StyledContainer>
              <StyledText
                fontSize="12px"
                interRegular
                color={theme.deepGrey}
                display="block"
                mb="4px"
              >
                Network
              </StyledText>
              <StyledText
                fontSize="12px"
                interSemiBold
                fontWeight6
                lineHeight="1.17"
              >
                {chain?.name}
              </StyledText>
            </StyledContainer>
            <RightSvg fill={theme.basicBlack}></RightSvg>
          </OptionItem>

          <CopyToClipboard
            text={address || ""}
            onCopy={() => setIsCopy(true)}
          >
          <OptionItem mt="5px">
            <StyledContainer>
              <StyledText
                fontSize="12px"
                interRegular
                color={theme.deepGrey}
                display="block"
                mb="4px"
              >
                Address
              </StyledText>
              <StyledText
                fontSize="12px"
                interSemiBold
                fontWeight6
                lineHeight="1.17"
              >
                {isCopy ? 'Copied' : address && shortStr(address, 6)}
              </StyledText>
            </StyledContainer>
            <CopySvg fill={theme.basicBlack}></CopySvg>
          </OptionItem>
          </CopyToClipboard>

        </ContentBox>
      )}
    </>
  );
};


export default WalletDetail;
