import { CatchType } from "types/baseType"

export const setStore = (key: string, value: any): void => {
    key = key.trim()
    if(!key) {
        throw new Error('key is empty')
    }
    if(!value && value !== 0) value = ''
    const valueStr = JSON.stringify(value)
    window.localStorage.setItem(key, valueStr)
}

export const getStore = (key: string) : any => {
    key = key.trim()
    if(!key) {
        throw new Error('key is empty')
    }
    const valueStr = window.localStorage.getItem(key)
    if(valueStr) {
        return JSON.parse(valueStr)
    } else {
        return ''
    }
}

export const removeStore = (key: string) => {
    key = key.trim()
    if(!key) {
        throw new Error('key is empty')
    }
    window.localStorage.removeItem(key)
}

export const setOrderStore = (value:Partial<CatchType>) => {
    const transactionList = getOrderStore()
    const { status, uuid } = value
    if(status === 'signing') {
        value['date'] = Date.now()
    }
    if(status === 'delete') {
        removeOrder(uuid as string)
        return
    }
    const isExistIndex = transactionList.findIndex((item:any) => item.uuid === uuid)
    if(isExistIndex > -1) {
        const oldVal = transactionList[isExistIndex]
        const orderInfo = Object.assign({}, oldVal.orderInfo, value.orderInfo)
        const originParams = Object.assign({}, oldVal.originParams, value.originParams)
        const realyParams = Object.assign({}, oldVal.realyParams, value.realyParams)
        const tempVal = Object.assign({}, oldVal, value, {orderInfo, originParams, realyParams})
        transactionList[isExistIndex] = tempVal
    } else {
        transactionList.push(value)
    }
    setStore('chainge.transaction', transactionList)
}

export const getOrderStore = () => {
    const transactionList = getStore('chainge.transaction')
    if(!transactionList) {
        return []
    }
    return transactionList
}

export const removeOrder = (uuid: string) => {
    const transactionList = getOrderStore()
    const index = transactionList.findIndex((item: any) => item.uuid === uuid)
    if(index > -1) {
        transactionList.splice(index, 1)
        setStore('chainge.transaction', transactionList)
    }
}


export const setLikeChainStore = (likeDatas: string[]) => {
    setStore('chainge.chain_like', likeDatas)
}

export const getLikeChainStore = () => {
    const likeData = getStore('chainge.chain_like')
    if(!likeData) {
        return []
    }
    return likeData
}


export const setLikeTokenStore = (likeDatas: string[]) => {
    setStore('chainge.token_like', likeDatas)
}

export const getLikeTokenStore = () => {
    const likeData = getStore('chainge.token_like')
    if(!likeData) {
        return []
    }
    return likeData
}