import {
  AggregateQuoteParams,
  CrossChainQuoteParams,
  Data,
} from "@chainge/sdk";
import { QuoteParamsType } from "types/baseType";
import { requestHttp } from "utils/request";
import chainge from "utils/sdk";

const NEARFLAG = "NEAR";

export const getQuote = async (params: QuoteParamsType) => {
  try {
    const { fromChain, toChain, fromSymbol, toSymbol } = params;
    if (fromChain === toChain && fromChain === NEARFLAG) {
      return getQuoteByAllNear(params);
    } else if (fromChain === NEARFLAG || toChain === NEARFLAG) {
      // one near
      if (fromSymbol === "USDT.e" || toSymbol === "USDT.e") {
        // not support
        return {
          msg: 'Not support token USDT.e'
        }
      } else {
        return await getQuoteExtend(params)
      }
    } else {
      // evm logic
      if (fromChain !== toChain && fromSymbol === toSymbol) {
        return await getQuoteByEvmCrossChain(params);
      } else {
        return await getQuoteByEvmAggregate(params);
      }
    }
  } catch (error) {
    throw error;
  }
};

// evm
const getQuoteByEvmCrossChain = async (tempParams: QuoteParamsType) => {
  let defaultFeeLevel = tempParams.feeLevel
  // Oshi
  if(tempParams.fromSymbol === 'OSHI') {
    defaultFeeLevel = 100;
  }

  const params: CrossChainQuoteParams = {
    amount: tempParams.fromAmount,
    feeLevel: defaultFeeLevel,
    fromChain: tempParams.fromChain,
    toChain: tempParams.toChain,
    token: tempParams.fromSymbol,
  };
  const { code, data, msg = "" } = await chainge.getCrossChainQuote(params);
  if (code === 200) {
    const dataInfo = (data as Data).crossChain;
    return {
      gas: dataInfo?.gas,
      toAmount: dataInfo?.amountOut,
      slippage: 0,
      amountOutMin: 0
    };
  } else {
    return {
      msg: msg,
    };
  }
};

const getQuoteByEvmAggregate = async (tempParams: QuoteParamsType) => {
  let defaultFeeLevel = tempParams.feeLevel
  // Oshi
  if(tempParams.fromSymbol === 'OSHI' && tempParams.toSymbol !== 'OSHI') {
    defaultFeeLevel = 500;
  }

  const params: AggregateQuoteParams = {
    feeLevel: defaultFeeLevel,
    fromAmount: tempParams.fromAmount,
    fromToken: tempParams.fromSymbol,
    toChain: tempParams.toChain,
    toToken: tempParams.toSymbol,
  };
  const { code, data, msg = "" } = await chainge.getAggregateQuote(params);
  if (code === 200) {
    const dataInfo = (data as Data).aggregate;
    return {
      gas: dataInfo?.gas,
      toAmount: dataInfo?.amountOut,
      slippage: dataInfo?.slippage,
      amountOutMin: (dataInfo as any)?.amountOutMin
    };
  } else {
    return {
      msg: msg,
    };
  }
};

// all near
const getQuoteByAllNear = (tempParams: QuoteParamsType) => {
  if (tempParams.fromSymbol !== tempParams.toSymbol) {
    const tempGas = (+tempParams.fromAmount * 0.0002).toFixed(6);
    if (+tempParams.fromAmount < 0.001) {
      return {
        msg: "At least 0.001 " + tempParams.fromSymbol,
      };
    }
    return {
      gas: tempGas,
      toAmount: +tempParams.fromAmount - +tempGas,
      slippage: 0,
      amountOutMin: 0
    };
  } else {
    return {
      msg: "Not support",
    };
  }
};

// only near
const getQuoteExtend = async (tempParams: QuoteParamsType) => {
  const params = {
    feeLevel: tempParams.feeLevel,
    fromAmount: tempParams.fromAmount,
    fromChain: tempParams.fromChain,
    fromToken: tempParams.fromSymbol,
    toChain: tempParams.toChain,
    toToken: tempParams.toSymbol,
  };
  const {
    code,
    data,
    msg = "",
  } = await requestHttp("post", "/open/v1/order/getQuoteExtend", params);
  if (code === 200) {
    const dataInfo = data.crossChain || data.aggregate;
    return {
      gas: dataInfo?.gas,
      toAmount: dataInfo?.amountOut,
      slippage: dataInfo?.slippage,
      amountOutMin: 0
    };
  } else {
    return {
      msg: msg,
    };
  }
};
