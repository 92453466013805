import { ReactComponent as SwapSvg } from "assets/svgs/icon-btn-swap.svg";
import { ReactComponent as AlertSvg } from "assets/svgs/icn-alert.svg";
import { basicBlack, FlexItemsCenter, lightYellow } from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import styled from "styled-components";
import BasePopup from "../BasePopup";
import { useImperativeHandle, useState } from "react";

type Props = {
  innerRef: any;
  onClick?: () => void;
};

const SlippageWarningWin = ({ innerRef, onClick }: Props) => {
  const [slippage, setSlippage] = useState("");
  const [open, setOpen] = useState(false);
  const fnOpenWin = (slippage: string) => {
    setSlippage(slippage);
    setOpen(true);
  };
  const fnCloseWin = () => {
    setOpen(false);
  };

  useImperativeHandle(innerRef, () => ({
    openWin: fnOpenWin,
    closeWin: fnCloseWin,
  }));

  const fnContinue = () => {
    if (onClick) {
      onClick();
      fnCloseWin();
    }
  };

  return (
    <>
      {open && (
        <BasePopup title="Slippage warning" onClose={fnCloseWin}>
          <FlexItemsCenter
            flex1="1"
            justifyContent="flex-start !important"
            flexDirection="column"
          >
            <AlertSvgWrapper></AlertSvgWrapper>
            <StyledText
              p="10px 0 30px"
              fontSize="14px"
              fontWeight5
              color="#ef233c"
            >
              Slippage warning
            </StyledText>
            <StyledText fontSize="14px" fontWeight5>
              For this swap, the expected slippage is
            </StyledText>
            <StyledText
              p="10px 0 25px"
              fontSize="20px"
              fontWeight6
              interSemiBold
            >
              {slippage}
            </StyledText>
            <StyledText
              fontSize="12px"
              lineHeight={1.33}
              interRegular
              textAlign="center"
            >
              By proceeding with the swap, you acknowledge <br></br> the price
              impact due to existing liquidity
            </StyledText>
          </FlexItemsCenter>
          <StyledContainer p="20px 20px 50px" textAlign="center">
            <BtnBox onClick={fnContinue}>
              <BtnText>Continue and swap</BtnText>
              <SwapSvg></SwapSvg>
            </BtnBox>
            <StyledContainer
              fontSize="14px"
              fontWeight5
              textDecoration="underline"
              mt="20px"
              pointer
              onClick={fnCloseWin}
            >
              Cancel
            </StyledContainer>
          </StyledContainer>
        </BasePopup>
      )}
    </>
  );
};

const AlertSvgWrapper = styled(AlertSvg)`
  width: 40px;
  height: 40px;
`;

const BtnBox = styled(FlexItemsCenter)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 17px 15px;
  background-color: ${lightYellow};
`;

const BtnText = styled.span`
  font-family: InterSemiBold;
  font-size: 14px;
  line-height: 1.43;
  color: ${basicBlack};
`;

export default SlippageWarningWin;
