import styled from "styled-components";
import { Outlet } from "react-router-dom";
import Header from "./components/pc/Header";
import Footer from "./components/pc/Footer";

import ProgressBar from "components/ProgressBar";

const BaseLayout = () => {
  return (
    <Layout>
      <Header></Header>
      <Content>
        <Outlet></Outlet>
      </Content>
      <Footer></Footer>
      <ProgressBar></ProgressBar>
    </Layout>
  );
};

const Layout = styled.div`
  background-color: ${({theme}) => theme.basicGrey};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Content = styled.div`
  width: 100vw;
  flex: 1;
  position: relative;
  background-image: url(${({theme}) => theme.pcBgImg});
  background-position: center;
  background-size: 1000px;
  background-repeat: no-repeat;
  height: 0;
`;

export default BaseLayout;
