import { Card, deepYellow, FlexCenter, seniorGrey } from "style/preset"
import styled, { useTheme } from "styled-components"

import StyledText from "style/styledText"
import StyledContainer from "style/styledContainer"


const Description = ({num, onClick}: {num: number, onClick: () => void}) => {
    const theme = useTheme()
    return <ContentBox>
        <TopImg src={theme.iconForProgressIllustration}></TopImg>
        <StyledText fontSize="30px" interBold fontWeight6 display="block" mb="5px">{num}</StyledText>
        <StyledText fontSize="14px" lineHeight="1.14" interSemiBold fontWeight6 display="block">Transactions in progress</StyledText>
        <StyledContainer backgroundColor={deepYellow} width="40px" height="4px" margin="10px 0 30px"></StyledContainer>
        <ULWrapper>
            <LIWrapper>Signing and validating the transaction might take a few minutes</LIWrapper>
            <LIWrapper>If the transaction fails please click “Retry" or click “Help" for assistance. You can find more info on the Transactions page.</LIWrapper>
        </ULWrapper>
        <SeeAllBtn onClick={onClick}>
            See all orders
        </SeeAllBtn>
    </ContentBox>
}

const ContentBox = styled(Card)`
    padding: 20px;
    width: 217px;
    box-size: border-box;
`

const TopImg = styled.img`
    height: 104px;
    margin-bottom:30px;
`
const ULWrapper = styled.ul`
    font-family: InterRegular;
    font-size: 12px;
    line-height: 1.17;
    color: ${seniorGrey};
    margin: 0;
    padding: 0 0 0 13px;
`
const LIWrapper = styled.li`
    margin-bottom: 15px;
`

const SeeAllBtn = styled(FlexCenter)`
    background-color: ${({theme}) => theme.lightGrey};
    width: 100%;
    height: 44px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
    text-decoration: none !important;
    color:  ${({theme}) => theme.basicBlack} !important;
`
export default Description