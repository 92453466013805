import { useCallback, useEffect, useState } from "react"
import { requestHttp } from "utils/request"
import { getStore, setStore } from "utils/store"
import { useAccount } from "wagmi"

const useKol = () => {
    const { isConnected, address: evmAddress = '' } = useAccount()
    const [refUrl, setRefUrl] = useState('')
    const [refInfo, setRefInfo] = useState<{[key: string]: any}>({})
    
    const getRefCode = useCallback(async() => {
        if(!isConnected || !evmAddress) {
            setRefUrl('')
            return ''
        }
        const evmAddressLower = evmAddress.toLowerCase()
        if(evmAddressLower.indexOf('0x') !== 0) {
            return ''
        }
        if(evmAddress.length !== 42) {
            return ''
        }

        const fnKolAdd = async () => {
            try {
                const params = {
                    address: evmAddressLower
                }
                const res = await requestHttp('post', '/open/v1/kol/add', params)
                if(res.code === 200) {
                    const result = res.data;
                    setRefInfo(result.Kol)
                    return result.Kol.referral
                } else {
                    return ''
                }
            } catch(error) {
                return ''
            }
        }

        const fnKolInfo = async () => {
            try {
                const params = {
                    address: evmAddressLower
                }
                const res = await requestHttp('post', '/open/v1/kol/info', params)
                if(res.code === 200) {
                    setRefInfo(res.data.Kol)
                }
            } catch(error) {}
        }

        const refCodes = getStore('chainge.refCodes')
        if(refCodes) {
            let curAddressRefUrl = refCodes[evmAddressLower]
            if(!curAddressRefUrl) {
                curAddressRefUrl = await fnKolAdd()
                const tempRefCods = {...refCodes}
                tempRefCods[evmAddressLower] = curAddressRefUrl
                setStore('chainge.refCodes', tempRefCods)
            } else {
               fnKolInfo();
            }
            setRefUrl(curAddressRefUrl)
        } else {
            const curAddressRefUrl = await fnKolAdd()
            setRefUrl(curAddressRefUrl)
            const tempRefCods = {} as any
            tempRefCods[evmAddressLower] = curAddressRefUrl
            setStore('chainge.refCodes', tempRefCods)
        }
    }, [evmAddress, isConnected])

    useEffect(() => {
        getRefCode();
    }, [getRefCode])

    return {refUrl, refInfo}
}

export default useKol