import {
  basicBlack,
  Card,
  FlexCenter,
  lightYellow,
  PopupWinBg,
  PopupWinOutBox,
} from "style/preset";
import { ReactComponent as ErrorSvg } from "assets/svgs/icons-misc-info.svg";
import styled, { useTheme } from "styled-components";
import StyledContainer from "style/styledContainer";
import { useImperativeHandle, useState } from "react";

type Props = {
    innerRef: any
}

type ErrObj = {
  error?: string;
  des?: string
}

const defErrObj = {error: '', des: ''}
const WarningPopupWin = ({innerRef}: Props) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false);
  const [msgObj, setMsgObj] = useState({
    error: '',
    des: ''
  })

  const fnOpenWin = (msgObj: ErrObj) => {
    setMsgObj({...defErrObj, ...msgObj})
    setOpen(true)
  }

  const fnCloseWin = () => {
    setMsgObj(defErrObj)
    setOpen(false)
  }

  useImperativeHandle(innerRef, () => ({
    openWin: fnOpenWin,
    closeWin: fnCloseWin
  }));
  return (
    <>
      {open && (
        <PopupWinOutBox>
          <PopupWinBg></PopupWinBg>

          <ContentBox>
            <ErrorSvg fill={theme.basicBlack} style={{ width: "22px", height: "22px" }}></ErrorSvg>
            <StyledContainer
              mt="20px"
              fontSize="14px"
              textAlign="center"
              lineHeight={1.29}
              width="65%"
              fontWeight5
            >
              {msgObj.error}
            </StyledContainer>
            <StyledContainer
              mt="20px"
              mb="40px"
              interRegular
              fontSize="14px"
              lineHeight={1.29}
            >
              {msgObj.des}
            </StyledContainer>

            <BaseBtn onClick={fnCloseWin}>Understood</BaseBtn>
          </ContentBox>
        </PopupWinOutBox>
      )}
    </>
  );
};

const ContentBox = styled(Card)`
  padding: 40px 15px 30px;
  box-sizing: border-box;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  width: calc(100% - 20px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BaseBtn = styled(FlexCenter)`
  border-radius: 6px;
  font-family: InterSemiBold;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  color: ${basicBlack};
  background-color: ${lightYellow};
  height: 48px;
  width: 100%;
`;

export default WarningPopupWin;
