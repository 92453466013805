import { Card, FlexCenter } from "style/preset"
import StyledText from "style/styledText"
import styled from "styled-components"

import { ReactComponent as DaySvg } from "assets/svgs/icons-misc-day_2.svg";
import { ReactComponent as NightSvg } from "assets/svgs/icons-misc-night.svg";
import StyledContainer from "style/styledContainer";
import DownloadWin from "components/DownloadAppBtn/components/DownloadWin";
import { useSystemInfo } from "hooks/useSystemProvider";

const MorePage = () => {
    const { updateTheme, theme  } = useSystemInfo()
    return <>
        <ThemeCard mb="10px">
            <StyledText interRegular fontSize="16px">Color theme</StyledText>
            <FlexCenter mt="20px">
                <ThemeItem active={+(theme === 'day')} onClick={updateTheme}>
                    <DaySvg className="base_svg"></DaySvg>
                    <StyledText className="base_title">Day mode</StyledText>
                </ThemeItem>
                <StyledContainer width="10px"></StyledContainer>
                <ThemeItem  active={+(theme === 'dark')} onClick={updateTheme}>
                    <NightSvg className="base_svg"></NightSvg>
                    <StyledText className="base_title">Night mode</StyledText>
                </ThemeItem>
            </FlexCenter>
        </ThemeCard>
        {/* <DownloadWin width="100%" position="relative" bottom="0"></DownloadWin> */}
    </>
}

const ThemeCard = styled(Card)`
    padding: 20px;
    text-align: center;
`

const ThemeItem = styled(FlexCenter)<any>`
    flex-direction: column;
    border-radius: 9px;
    width: 100px;
    height: 70px;
    box-sizing: border-box;
    border: 1px solid ${({active, theme}) => active ? theme.themeBtnActive : theme.deepGrey};
    background-color: ${({active, theme}) => active ? theme.themeBtnActive : ''};
    
    .base_svg {
        fill: ${({active, theme}) => active ? theme.themeBtnTextActive : theme.deepGrey};
        width: 16px;
        height: 16px;
    }

    .base_title {
        font-size:12px;
        margin-top: 12px;
        font-weight: 500;
        color: ${({active, theme}) => active ? theme.themeBtnTextActive : theme.deepGrey};
    }
`

export default MorePage