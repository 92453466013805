import StyledContainer from "style/styledContainer";
import styled, { useTheme } from "styled-components";

import StyledText from "style/styledText";
import WalletItem from "./components/WalletItem";

import { useWalletInfo } from "hooks/useWalletProvider";
import { Connector } from "wagmi";
import BasePopup from "components/BasePopup";
import { getWalletLogo } from "utils/walletManage";

type Props = {
    connectors: Connector[]
    activeConnector?: Connector
    chosedWallet: (walletId: string, connector?: Connector) => void;
}

const WalletPopupWin = ({chosedWallet, connectors, activeConnector }: Props) => {
  const theme = useTheme()
  const { walletWin, nearWalletInfo, closeWalletWin } = useWalletInfo();

  const onClosePopup = () => {
    closeWalletWin()
  };

  const fnChosedWallet = (walletName: string, connector: any) => {
    walletName = walletName.toLocaleLowerCase()
    if(walletName === 'pali') {
      const defEthereum = (window as any).ethereum
      if(defEthereum) {
        if(!(defEthereum.wallet && defEthereum.wallet === 'pali-v2')) {
          connector = connectors[0]
        }
      }
    }
    chosedWallet(walletName.toLocaleLowerCase() + 'evm_wallet', connector)
  }

  return (
    <>
      {walletWin && (
        <BasePopup onClose={onClosePopup}>
          <ContentBox>
            <TopImg src={theme.iconForWalletIllustration}></TopImg>
            <StyledText
              m="30px 0 25px"
              interRegular
              fontSize="16px"
              display="block"
            >
              Wallets management
            </StyledText>
            <ScrollBox>
              {
                connectors.map((item, index) => {
                  return <WalletItem key={index} imgUrl={getWalletLogo(item.name)} name={item.name} isConnected={activeConnector?.name === item.name} onClick={() => fnChosedWallet(item.name, item)}></WalletItem>
                })
              }
              <WalletItem
                imgUrl={theme.iconForNearLogo}
                name="Near"
                isOnly={false}
                isConnected={nearWalletInfo?.isConnected}
                onClick={() => chosedWallet('near_wallet')}
              ></WalletItem>
            </ScrollBox>
          </ContentBox>
        </BasePopup>
      )}
    </>
  );
};

const ContentBox = styled(StyledContainer)`
  padding: 30px 0px 44px;
  text-align: center;
`;

const TopImg = styled.img`
  height: 97px;
  margin: 0 auto;
`;

const ScrollBox = styled(StyledContainer)`
  height: 300px;
  overflow: auto;
  padding: 0 20px;
`

export default WalletPopupWin;
