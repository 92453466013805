import { Card, FlexCenter, FlexItemsCenter } from "style/preset";
import StyledText from "style/styledText";
import styled, { useTheme } from "styled-components";
import { ReactComponent as LeftSvg } from "assets/svgs/icons-arrows-left-03.svg";
import CImage from "components/CImage";
import StyledContainer from "style/styledContainer";
import { ReactComponent as RightSvg } from "assets/svgs/icons-arrows-right-02.svg";
import { Chain, useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useCallback, useEffect, useState } from "react";
import { getWalletLogo } from "utils/walletManage";

type Props = {
    closeWin?: () => void;
}

const NetworkList = ({closeWin}: Props) => {
  const theme = useTheme()
  const { chain, chains } = useNetwork()
  const { connector } = useAccount();
  const [dataList, setDataList] = useState<Chain[]>([])
  const { switchNetworkAsync } = useSwitchNetwork()
  
  useEffect(() => {
    setDataList(chains)
  }, [chains])

  const fnSwitchNetwork = useCallback(async (curNetwork: Chain) => {
    try {
      await switchNetworkAsync?.(curNetwork.id);
    } catch (error) {
      console.log();
    }
  }, [switchNetworkAsync])

  return (
    <ContentBox>
      <FlexItemsCenter mb="20px">
        <LeftSvgWrapper fill={theme.basicBlack} onClick={() => closeWin && closeWin()}></LeftSvgWrapper>
        <StyledText interRegular fontSize="16px">
          Select a network
        </StyledText>
        <div></div>
      </FlexItemsCenter>
      <FlexCenter column m="35px 0 25px">
        <ImgBox>
          <CImage src={getWalletLogo(connector?.name || '')} width="35px" height="35px"></CImage>
        </ImgBox>
        <StyledText mt="8px" interSemiBold fontSize="14px" fontWeight6>
          { connector?.name || 'Metamask'} 
        </StyledText>
      </FlexCenter>
      <NetworkListBox>
        {
          dataList.map((item, index) => <NetWorkItem key={index} onClick={() => fnSwitchNetwork(item)} name={item.name} isCurrent={item.id === chain?.id} logoUrl={(item as any).iconUrl}></NetWorkItem>)
        }
      </NetworkListBox>
    </ContentBox>
  );
};


type NetworkProps = {
  isCurrent: boolean
  name: string
  logoUrl: string
  onClick: () => void
}
const NetWorkItem = ({isCurrent, name, logoUrl, onClick}: NetworkProps) => {
  const theme = useTheme()
  return <NetWorkItemBox onClick={onClick}>
    <FlexCenter>
        <CImage src={logoUrl} width="22px" height="22px" mr="12px"></CImage>
        <StyledText fontSize="12px" fontWeight6 interRegular lineHeight="1.17">{name}</StyledText>
    </FlexCenter>
    {
      isCurrent ? <CurNetWork></CurNetWork> : <RightSvg fill={theme.basicBlack}></RightSvg>
    }
  </NetWorkItemBox>;
};

const ContentBox = styled(Card)`
  padding: 20px 15px 48px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;

const LeftSvgWrapper = styled(LeftSvg)`
  cursor: pointer;
`;

const ImgBox = styled(FlexCenter)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.walletIconBoxForNetwork};
`;

const NetworkListBox = styled(StyledContainer)`
  max-height: 300px;
  overflow: auto;
`;

const NetWorkItemBox = styled(FlexItemsCenter)`
  padding: 14px 15px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.basicGrey};
  cursor: pointer;
  margin-bottom: 5px;
`;

const CurNetWork = styled(StyledContainer)`
  width: 12px;
  height: 12px;
  background-color: ${({theme}) => theme.basicGreen};
  border-radius: 50%;
`

export default NetworkList;
