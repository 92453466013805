import BasePopup from "components/Mobile/BasePopup";
import { useEffect, useImperativeHandle, useState } from "react";
import StyledContainer from "style/styledContainer";
import styled from "styled-components";
import NetworkList from "./NetworkList";
import WalletDetail from "./wallets/WalletDetail";
import WalletDetailForNear from "./wallets/WalletDetailForNear";

const ConnectedWallets = ({ innerRef }: { innerRef: any }) => {
  const [open, setOpen] = useState(false);
  const [openNetworkWin, setOpenNetworkWin] = useState(false);
  const [title, setTitle] = useState('Connected wallets')

  const fnOpenNetworkWin = () => {
    setOpenNetworkWin(true);
  };

  const fnCloseNetworkWin = () => {
    setOpenNetworkWin(false);
  };

  const fnOpenWin = () => {
    setOpen(true);
  };

  const fnCloseWin = () => {
    setOpen(false);
    setOpenNetworkWin(false)
  };

  useImperativeHandle(innerRef, () => ({
    openWin: fnOpenWin,
    closeWin: fnCloseWin,
  }));

  useEffect(() => {
    let tempTitle = ''
    if(openNetworkWin) {
      tempTitle = 'Select a network'
    } else {
      tempTitle = 'Connected wallets'
    }
    setTitle(tempTitle)
  }, [openNetworkWin])

  return (
    <>
      {open && (
        <BasePopup title={title} onClose={fnCloseWin} showBack={openNetworkWin} onBack={fnCloseNetworkWin}>
          {!openNetworkWin && (
            <ContentBox>
              <WalletDetail openChosedNetwork={fnOpenNetworkWin}></WalletDetail>
              <WalletDetailForNear></WalletDetailForNear>
            </ContentBox>
          )}
          {openNetworkWin && (
            <NetworkList></NetworkList>
          )}
        </BasePopup>
      )}
    </>
  );
};

const ContentBox = styled(StyledContainer)`
  padding: 0 15px;
`;

export default ConnectedWallets;
