import { FlexCenter, FlexItemsCenter } from "style/preset";
import StyledContainer from "style/styledContainer";
import styled from "styled-components";
import { ReactComponent as RightSvg } from "assets/svgs/icons-arrows-right-02.svg";

export const ContentBox = styled(StyledContainer)`
  padding: 15px 12px;
  border-radius: 9px;
  background-color: ${({theme}) => theme.basicGrey};
  margin-bottom: 10px;
`;

export const ImgBox = styled(FlexCenter)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.walletDetailOptions};
  margin-right: 10px;
`;

export const RightSvgWrapper = styled(RightSvg)`
  width: 12px;
  height: 12px;
  transition: fill linear 0.2s;
  transform: rotate(90deg);
`;

export const OptionItem = styled(FlexItemsCenter)`
  padding: 10px 15px 10px 12px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.walletDetailOptions};
  box-sizing: border-box;
  cursor: pointer;
`;
