import { BaseLabel, FlexCenter } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled, { useTheme } from "styled-components"

import { ReactComponent as DownSvg } from 'assets/svgs/icons-arrows-top-01.svg'
import CImage from "components/CImage"
import { AssetTpye, DefObj } from "types/baseType"


type Props = {
    type: AssetTpye
    label: string
    name: string
    url: string
    meta?: DefObj<string, any>
    fullName?: string
    onClick?: (type: AssetTpye, meta?: DefObj<string, any>) => void
}

const CSelect = ({type, name, url, meta, fullName = '', label, onClick}: Props) => {
    const theme = useTheme()
    return <SelectBox onClick={() =>  onClick && onClick(type, meta)}>
        <StyledContainer flex1="1" flex alignItems="center">
            <FlexCenter width="38px" height="38px" backgroundColor={theme.cselectIconBg} borderRadius="50%" mr="5px">
                {
                    url && <CImage src={url} width="22px" height="22px"></CImage>
                }
            </FlexCenter>
            <StyledContainer>
                <BaseLabel>{label}:</BaseLabel>
                <StyledContainer mt="5px" fontWeight5 interMedium flex column justifyContent="center">
                    <StyledContainer fontSize="14px">{name || 'Select'}</StyledContainer>
                </StyledContainer>
            </StyledContainer>
        </StyledContainer>
        <FlexCenter>
            <DownIcon></DownIcon>
        </FlexCenter>
    </SelectBox>
}

const SelectBox = styled(StyledContainer).attrs(() => ({ tabIndex: 0 }))`
    width: 100%;
    padding: 8px;
    padding-right: 10px;
    background-color: ${({theme}) => theme.basicGrey};
    border-radius: 9px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    box-sizing: border-box;

    flex: 1;

    &:hover {
        background-color: ${({theme}) => theme.lightGrey};
    }
`

const DownIcon = styled(DownSvg)`
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
    fill: ${({theme}) => theme.basicBlack};
`

export default CSelect