import styled from "styled-components";
import Header from "./components/mobile/Header";
import NavBar from "./components/mobile/NavBar";
import { Outlet } from "react-router-dom";

const BaseLayout = () => {
  return (
    <Layout>
        <Header></Header>
        <Content>
          <Outlet></Outlet>
        </Content>
        <NavBar></NavBar>
    </Layout>
  );
};

const Layout = styled.div`
  background-color: ${({theme}) => theme.basicGrey};
  width: 100%;
  min-height: 100vh;
`;

const Content = styled.div`
  padding: 0 10px; 
`;

export default BaseLayout;
