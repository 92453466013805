import CImage from "components/CImage";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import {
  AssetItemBox,
  ContentScrollBox,
  FavoriteOffOn,
  FavoritesBar,
  TableRow,
  TableTh,
} from "./StyledCommon";
import { TokenVo } from "@chainge/sdk";
import { useSystemInfo } from "hooks/useSystemProvider";
import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { getEvmBalance, getNearBalance } from "pages/Home/balanceService";
import { useWalletInfo } from "hooks/useWalletProvider";

type Props = {
  dataList: TokenVo[];
  allTokenData: TokenVo[];
  onChosed: (chosed: TokenVo) => void;
  loadMore?: (status: boolean) => void
};

const SearchAsset = ({ dataList, allTokenData, onChosed, loadMore }: Props) => {
  const { updateLikeTokens, likeTokens } = useSystemInfo();
  const fnFavorite = (symbol: string) => {
    updateLikeTokens(symbol);
  };

  const [likeData, setLikeData] = useState<TokenVo[]>([]);
  useEffect(() => {
    if (allTokenData.length) {
      const tempData = allTokenData.filter((item) =>
        likeTokens.includes(item.symbol)
      );
      setLikeData(tempData);
    }
  }, [likeTokens, allTokenData]);

  const fnScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight && loadMore) {
      loadMore(true)
    }
  }

  return (
    <StyledContainer flex1="1" height="0" flex column>
      <FavoritesBar
        dataList={likeData}
        onChosed={(data) => onChosed(data as TokenVo)}
        onRemove={fnFavorite}
        clearAllLike={() => updateLikeTokens("", true)}
      ></FavoritesBar>
      <TableRow>
        <TableTh flex1="2" pl="20px">
          Asset name
        </TableTh>
        <TableTh flex1="1">Balance</TableTh>
        <TableTh>
          <StyledContainer width="26px" m="0 15px"></StyledContainer>
        </TableTh>
      </TableRow>

      <ContentScrollBox onScroll={fnScroll}>
        <StyledContainer height="20px"></StyledContainer>
        {dataList.map((item, index) => (
          <AssetItemNode
            key={index}
            detail={item}
            isLike={likeTokens.includes(item.symbol)}
            onClick={onChosed}
            onFavorite={fnFavorite}
          ></AssetItemNode>
        ))}
      </ContentScrollBox>
    </StyledContainer>
  );
};

type ItemProps = {
  detail: TokenVo;
  isLike: boolean;
  onClick?: (detail: TokenVo) => void;
  onFavorite: (symbol: string) => void;
};
const AssetItemNode = ({ detail, isLike, onClick, onFavorite }: ItemProps) => {
  const { address } = useAccount();
  const { nearWalletInfo } = useWalletInfo()
  const fnOnClick = () => {
    onClick && onClick(detail);
  };
  
  const [balance, setBalance] = useState(0)
  const getBalance = useCallback(async () => {
    let balance = 0
    if(detail.chain !== 'NEAR') {
      if(address) {
        const result = await getEvmBalance(address, [detail])
        balance = +(result || '0')
      }
    } else {
      if(nearWalletInfo?.userAddress) {
        const result = await getNearBalance(nearWalletInfo, detail)
        balance = +(result || '0')
      }
    }
    setBalance(balance)
  }, [address, detail, nearWalletInfo])

  useEffect(() => {
    getBalance()
  }, [detail, getBalance])

  return (
    <AssetItemBox>
      <StyledContainer
        flex1="2"
        flex
        alignItems="center"
        pl="20px"
        height="100%"
        onClick={fnOnClick}
      >
        <CImage src={detail.url} mr="10px"></CImage>
        <StyledContainer
          fontWeight5
          interMedium
          flex
          column
          justifyContent="center"
        >
          <StyledContainer fontSize="14px">{detail.symbol}</StyledContainer>
          <StyledContainer fontSize="10px" mt="4px">
            {detail.name}
          </StyledContainer>
        </StyledContainer>
      </StyledContainer>
      <StyledContainer
        flex1="1"
        lineHeight="1.33"
        flex
        alignItems="center"
        height="100%"
        onClick={fnOnClick}
      >
        {balance.toFixed(2)} {detail.symbol}
      </StyledContainer>
      <StyledContainer>
        <FavoriteOffOn
          isLike={isLike}
          onClick={() => onFavorite(detail.symbol)}
        ></FavoriteOffOn>
      </StyledContainer>
    </AssetItemBox>
  );
};

export default SearchAsset;
