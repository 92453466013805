import { FlexItemsCenter } from "style/preset";
import StyledContainer from "style/styledContainer";
import { StyleProps } from "types/baseType";
import styled from "styled-components";
import { NumericFormat } from "react-number-format";

type Props = {
  minAmount: string;
  toSymbol: string;
} & StyleProps;

const MinReceive = ({
    minAmount = '',
    toSymbol = ''
}: Props) => {
  return (
    <FlexItemsCenterWrapper
        p={"10px"}
        borderRadius="6px"
        mt={"15px"}
        justifyContent="space-between"
    >
      <StyledContainer
        fontSize="12px"
        flex
        alignItems="center"
      >
        <StyledContainer interSemiBold fontWeight6>
            Min. to receive:
        </StyledContainer>
        <StyledContainer ml="5px" interSemiBold>
            {minAmount ? <></> : 'N/A'}
        </StyledContainer>
      </StyledContainer>
      {
        minAmount ? <StyledContainer
            fontSize="12px"
            flex
            alignItems="center"
        >
            <NumericFormat
                displayType="text"
                value={minAmount}
                thousandSeparator=","
            />
            <div style={{width: '5px'}}></div>
            {toSymbol}
        </StyledContainer> : <></>
      }
      
    </FlexItemsCenterWrapper>
  );
};

const FlexItemsCenterWrapper = styled(FlexItemsCenter)`
  background-color: ${({theme}) => theme.basicGrey};
  justify-content: space-between;
`
export default MinReceive;
