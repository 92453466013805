import BasePopup from "components/BasePopup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StyledContainer from "style/styledContainer";
import styled from "styled-components";
import { CatchType } from "types/baseType";
import Description from "./components/Description";
import TransactionItem from "./components/TransactionItem";

type Props = {
  onOpenChange?: (state: boolean) => void;
  open: boolean;
  orderList: CatchType[];
};

const ProgressPopupWin = ({ open, orderList, onOpenChange }: Props) => {
  const [localOpen, setLocalOpen] = useState(false);
  const navigate = useNavigate()

  // The pop-up is closed and the local data is updated
  const onClosePopup = () => {
    setLocalOpen(false);
  };

  // The pop-up is opened to initialize local data
  useEffect(() => {
    if (open) {
      setLocalOpen(open);
    }
  }, [open]);

  useEffect(() => {
    onOpenChange && onOpenChange(localOpen);
  }, [localOpen, onOpenChange]);

  const fnNavToOrders = () => {
    onClosePopup()
    navigate('/transactions')
  }

  return (
    <>
      {localOpen && (
        <BasePopup onClose={onClosePopup} extraStyle={ExtraStyle}>
          <ContentBox>
            <Description num={orderList.length} onClick={fnNavToOrders}></Description>
            <StyledContainer width="24px"></StyledContainer>
            <TransactionList>
              {orderList.map((item, index) => (
                <TransactionItem key={index} detail={item}></TransactionItem>
              ))}
            </TransactionList>
          </ContentBox>
        </BasePopup>
      )}
    </>
  );
};

const ExtraStyle = `width: 870px;
height: 500px; box-shadow: none; background-color: transparent; border: none;`;

const ContentBox = styled(StyledContainer)`
  display: flex;
  height: 100%;
`;

const TransactionList = styled(StyledContainer)`
  flex: 1;
  overflow: auto;
`;

export default ProgressPopupWin;
