import { FlexItemsCenter, lightYellow, PopupWinBg } from "style/preset";
import styled from "styled-components";

import { ReactComponent as AlertSvg } from "assets/svgs/icn-alert.svg";
import StyledText from "style/styledText";
import { StyleProps } from "types/baseType";
import { ReactComponent as SwapSvg } from "assets/svgs/icon-btn-swap.svg";
import StyledContainer from "style/styledContainer";
import { useImperativeHandle, useState } from "react";

type Props = {
  innerRef: any;
  onClick?: () => void;
} & StyleProps;

const SlippageWarningWin = ({ innerRef, onClick }: Props) => {
  const [slippage, setSlippage] = useState("");
  const [open, setOpen] = useState(false);
  const fnOpenWin = (slippage: string) => {
    setSlippage(slippage);
    setOpen(true);
  };
  const fnCloseWin = () => {
    setOpen(false);
  };

  useImperativeHandle(innerRef, () => ({
    openWin: fnOpenWin,
    closeWin: fnCloseWin,
  }));

  const fnContinue = () => {
    if(onClick) {
      onClick()
      fnCloseWin()
    }
  }

  return (
    <>
      {open && (
        <LoadingBox>
          <AlertSvgWrapper></AlertSvgWrapper>
          <StyledText
            p="10px 0 30px"
            fontSize="14px"
            fontWeight5
            color="#ef233c"
          >
            Slippage warning
          </StyledText>
          <StyledText fontSize="14px" fontWeight5>
            For this swap, the expected slippage is
          </StyledText>
          <StyledText p="10px 0 25px" fontSize="20px" fontWeight6 interSemiBold>
            {slippage}
          </StyledText>
          <StyledText
            fontSize="12px"
            lineHeight={1.33}
            interRegular
            textAlign="center"
          >
            By proceeding with the swap, you acknowledge <br></br> the price
            impact due to existing liquidity
          </StyledText>

          <BtnBox onClick={fnContinue}>
            <StyledText
              fontSize="14px"
              lineHeight={1.43}
              fontWeight6
              interSemiBold
            >
              Continue and swap
            </StyledText>
            <SwapSvg></SwapSvg>
          </BtnBox>
          <StyledContainer
            fontSize="14px"
            fontWeight5
            textDecoration="underline"
            mt="20px"
            pointer
            onClick={fnCloseWin}
          >
            Cancel
          </StyledContainer>
        </LoadingBox>
      )}
    </>
  );
};

const LoadingBox = styled(PopupWinBg)`
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.92);
  z-index: 100;

  padding: 40px 25px 35px;
`;

const AlertSvgWrapper = styled(AlertSvg)`
  width: 40px;
  height: 40px;
`;

const BtnBox = styled(FlexItemsCenter)`
  cursor: pointer;
  border-radius: 6px;
  padding: 17px 15px;
  background-color: ${lightYellow};
  width: 100%;
  box-sizing: border-box;
  margin-top: 110px;
`;

export default SlippageWarningWin;
