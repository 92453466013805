import styled, { css } from "styled-components";

interface StyledProps {
   interBlank?: boolean
   interBold?: boolean
   interExtraBold?: boolean
   interExtraLight?: boolean
   interMedium?: boolean
   interRegular?: boolean
   interSemiBold?: boolean
   interThin?: boolean
   fontFamily?: string

   fontWeight1?: boolean
   fontWeight2?: boolean
   fontWeight3?: boolean
   fontWeight4?: boolean
   fontWeight5?: boolean
   fontWeight6?: boolean
   fontWeight7?: boolean
   fontWeight8?: boolean
   fontWeight9?: boolean
   bold?: boolean
   fontWeight?: string | number

   fontSize?: string
   background?: string
   backgroundColor?: string
   backgroundImage?: string
   backgroundSize?: string
   backgroundPosition?: string
   filter?: string
   backdropFilter?: string
   mt?: string
   mb?: string
   ml?: string
   mr?: string
   pt?: string
   pb?: string
   pl?: string
   pr?: string
   boxShadow?: string
   flex?: boolean
   flexDirection?: string
   flexWrap?: string
   grid?: boolean
   wrap?: boolean
   nowrap?: boolean
   column?: boolean
   row?: boolean
   justifyContent?: string
   alignItems?: string
   divider?: boolean
   lineHeight?: string | number
   border?: boolean
   borderBottom?: boolean
   borderTop?: string
   borderLeft?: string
   borderRadius?: string
   textAlign?: string
   width?: string
   maxWidth?: string
   minWidth?: string
   height?: string
   minHeight?: string
   maxHeight?: string
   pointer?: boolean
   flex1?: string
   overflow?: string
   overflowX?: string
   margin?: string
   m?: string
   padding?: string
   p?: string
   display?: string
   position?: string
   top?: string | number
   bottom?: string | number
   left?: string | number
   right?: string | number
   zIndex?: number
   gridGap?: string
   gridTemplateColumns?: string
   gridTemplateRows?: string
   boxSizing?: string
   gridAutoFlow?: string
   alignSelf?: string
   style?: string
   active?: string
   responsive?: string
   ifMobile?: string
   opacity?: string
   transform?: string
   transformOrigin?: string
   breakWord?: boolean
   clipPath?: string
   transition?: string
   noneUnderline?: boolean
   textDecoration?: string
   underline?:boolean
   hover?: string
}

const StyledContainer = styled.div<StyledProps>`
      ${({ fontFamily }) => fontFamily ? css`font-family: ${fontFamily as string};` : `font-family: InterMedium;`}
      ${({ interBlank }) => interBlank ? css`font-family: InterBlank;` : null}
      ${({ interBold }) => interBold ? css`font-family: InterBold;` : null}
      ${({ interExtraBold }) => interExtraBold ? css`font-family: InterExtraBold;` : null}
      ${({ interExtraLight }) => interExtraLight ? css`font-family: InterExtraLight;` : null}
      ${({ interMedium }) => interMedium ? css`font-family: InterMedium;` : null}
      ${({ interRegular }) => interRegular ? css`font-family: InterRegular;` : null}
      ${({ interSemiBold }) => interSemiBold ? css`font-family: InterSemiBold;` : null}
      ${({ interThin }) => interThin ? css`font-family: InterThin;` : null}

      ${({ fontWeight1 }) => fontWeight1 ? css`font-weight: 100;` : null}
      ${({ fontWeight2 }) => fontWeight2 ? css`font-weight: 200;` : null}
      ${({ fontWeight3 }) => fontWeight3 ? css`font-weight: 300;` : null}
      ${({ fontWeight4 }) => fontWeight4 ? css`font-weight: 400;` : null}
      ${({ fontWeight5 }) => fontWeight5 ? css`font-weight: 500;` : null}
      ${({ fontWeight6 }) => fontWeight6 ? css`font-weight: 600;` : null}
      ${({ fontWeight7 }) => fontWeight7 ? css`font-weight: 700;` : null}
      ${({ fontWeight8 }) => fontWeight8 ? css`font-weight: 800;` : null}
      ${({ fontWeight9 }) => fontWeight9 ? css`font-weight: 900;` : null}
      ${({ bold }) => bold ? css`font-weight: bold;` : null} 
      ${({ fontWeight }) => fontWeight ? css`font-weight: ${fontWeight};` : null}

      ${({ fontSize }) => fontSize ? css`font-size: ${fontSize};` : null} 
      ${({ background }) => background ? css`background: ${background};` : null} 
      ${({ backgroundColor }) => backgroundColor ? css`background-color: ${backgroundColor};` : null} 
      ${({ backgroundImage }) => backgroundImage ? css`background-image: ${backgroundImage};` : null} 
      ${({ backgroundSize }) => backgroundSize ? css`background-size: ${backgroundSize};` : null} 
      ${({ backgroundPosition }) => backgroundPosition ? css`background-position: ${backgroundPosition};` : null} 
      ${({ filter }) => filter ? css`filter: ${filter};` : null} 
      ${({ backdropFilter }) => backdropFilter ? css`backdrop-filter: ${backdropFilter};` : null} 
      ${({ mt }) => mt ? css`margin-top: ${mt};` : null} 
      ${({ mb }) => mb ? css`margin-bottom: ${mb};` : null} 
      ${({ ml }) => ml ? css`margin-left: ${ml};` : null} 
      ${({ mr }) => mr ? css`margin-right: ${mr};` : null} 
      ${({ pt }) => pt ? css`padding-top: ${pt};` : null} 
      ${({ pb }) => pb ? css`padding-bottom: ${pb};` : null} 
      ${({ pl }) => pl ? css`padding-left: ${pl};` : null} 
      ${({ pr }) => pr ? css`padding-right: ${pr};` : null} 
      ${({ boxShadow }) => boxShadow ? css`box-shadow: ${boxShadow};` : null} 
      ${({ flex }) => flex ? css`display: flex;` : null} 
      ${({ flexDirection }) => flexDirection ? css`flex-direction: ${flexDirection};` : null} 
      ${({ flexWrap }) => flexWrap ? css`flex-wrap: ${flexWrap};` : null} 
      ${({ grid }) => grid ? css`display: grid;` : null} 
      ${({ wrap }) => wrap ? css`flex-wrap: wrap;` : null} 
      ${({ nowrap }) => nowrap ? css`white-space: nowrap;text-overflow: ellipsis; overflow: hidden;` : null} 
      ${({ column }) => column ? css`flex-direction: column;` : null} 
      ${({ row }) => row ? css`fdlex-direction: row;` : null} 
      ${({ justifyContent }) => justifyContent ? css`justify-content: ${justifyContent};` : null} 
      ${({ alignItems }) => alignItems ? css`align-items: ${alignItems};` : null} 
      ${({ color, theme }) => color ? css`color: ${color};` : `color: ${theme.defaultContentColor};`} 
      ${({ lineHeight }) => lineHeight ? css`line-height: ${lineHeight};` : null} 
      ${({ divider }) => divider ? css`border-bottom: 1px solid #ced6e7;` : null} 
      ${({ border }) => border ? css`border: 1px solid #ced6e7;` : null} 
      ${({ borderBottom }) => borderBottom ? css`border-bottom: 1px solid #ced6e7;` : null} 
      ${({ borderTop }) => borderTop ? css`border-top: ${borderTop};` : null} 
      ${({ borderLeft }) => borderLeft ? css`border-left: ${borderLeft};` : null} 
      ${({ borderRadius }) => borderRadius ? css`border-radius: ${borderRadius};` : null} 
      ${({ textAlign }) => textAlign ? css`text-align: ${textAlign};` : null} 
      ${({ width }) => width ? css`width: ${width};` : null}
      ${({ maxWidth }) => maxWidth ? css`max-width: ${maxWidth};` : null} 
      ${({ minWidth }) => minWidth ? css`min-width: ${minWidth};` : null}
      ${({ height }) => height ? css`height: ${height};` : null} 
      ${({ minHeight }) => minHeight ? css`min-height: ${minHeight};` : null} 
      ${({ maxHeight }) => maxHeight ? css`max-height: ${maxHeight};` : null} 
      ${({ pointer }) => pointer ? css`cursor: pointer;` : null} 
      ${({ flex1 }) => flex1 ? css`flex: ${flex1};` : null} 
      ${({ overflow }) => overflow ? css`overflow: ${overflow};` : null} 
      ${({ overflowX }) => overflowX ? css`overflow-x: ${overflowX};` : null} 
      ${({ margin }) => margin ? css`margin: ${margin};` : null} 
      ${({ m }) => m ? css`margin: ${m};` : null} 
      ${({ padding }) => padding ? css`padding: ${padding};` : null} 
      ${({ p }) => p ? css`padding: ${p};` : null} 
      ${({ display }) => display ? css`display: ${display};` : null} 
      ${({ position }) => position ? css`position: ${position};` : null} 
      ${({ top }) => (top || top === 0) ? css`top: ${top};` : null} 
      ${({ bottom }) => (bottom || bottom === 0) ? css`bottom: ${bottom};` : null} 
      ${({ left }) => (left || left === 0) ? css`left: ${left};` : null} 
      ${({ right }) => (right || right === 0) ? css`right: ${right};` : null} 
      ${({ zIndex }) => zIndex ? css`z-index: ${zIndex};` : null} 
      ${({ gridGap }) => gridGap ? css`grid-gap: ${gridGap};` : null} 
      ${({ gridTemplateColumns }) => gridTemplateColumns ? css`grid-template-columns: ${gridTemplateColumns};` : null} 
      ${({ gridTemplateRows }) => gridTemplateRows ? css`grid-template-rows: ${gridTemplateRows};` : null} 
      ${({ boxSizing }) => boxSizing ? css`box-sizing: ${boxSizing};` : null} 
      ${({ gridAutoFlow }) => gridAutoFlow ? css`grid-auto-flow: ${gridAutoFlow};` : null} 
      ${({ alignSelf }) => alignSelf ? css`align-self: ${alignSelf};` : null} 
      ${({ style }) => style ? css`${style}` : null} 
      ${({ active }) => active ? css`font-weight: 600;` : null}
      ${({ responsive }) => responsive ? css`${responsive};` : null}
      ${({ ifMobile }) => ifMobile ? css`${ifMobile};` : null}
      ${({ opacity }) => opacity ? css`opacity: ${opacity};` : null}
      ${({ transform }) => transform ? css`transform: ${transform};` : null}
      ${({ transformOrigin }) => transformOrigin ? css`transform-origin: ${transformOrigin};` : null}
      ${({ breakWord }) => breakWord ? css`word-break: break-word;` : null}
      ${({ clipPath }) => clipPath ? css`clip-path: ${clipPath};` : null}
      
      ${({ transition }) => transition ? css`transition: ${transition};` : 'transition: all linear 0.2s;'} 
      ${({ noneUnderline }) => noneUnderline ? css`text-decoration: none;` : null} 
      ${({ textDecoration }) => textDecoration ? css`text-decoration: ${textDecoration};`: null}
      ${({ underline }) => underline ? css`text-decoration: underline;text-decoration-color: transparent;` : null} 

     ${({ hover }) => hover ? css`
     :hover{
        ${hover}
     };
     ` : null} 
`
// const StyledContainer = (props) => {
//     return (
//         <Container {...props}>
//             {props.children}
//         </Container>
//     );
// };

export default StyledContainer;
