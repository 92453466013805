import {
  Card,
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import styled, { useTheme } from "styled-components";

import { ReactComponent as ArrowDownSvg } from "assets/svgs/mobile/icons-arrows-bottom-03.svg";
import CImage from "components/CImage";
import { useOrder } from "hooks/useOrderProvider";
import { CatchType } from "types/baseType";
import { useSystemInfo } from "hooks/useSystemProvider";
import { useCallback } from "react";
import Placeholder from "components/Placeholder";
import { useAccount } from "wagmi";
import { useWalletInfo } from "hooks/useWalletProvider";
import { useNavigate } from "react-router-dom";
import StatusBar from "pages_mobile/Transactions/components/StatusBar";
import { HashHandlerItem } from "pages_mobile/Transactions";

const PendingPage = () => {
  const { pendingList } = useOrder();
  const { isConnected } = useAccount();
  const { nearWalletInfo, openWalletWin } = useWalletInfo();

  const navigate = useNavigate();
  const fnGoToSwap = () => {
    navigate("/");
  };

  const getIsConnected = () => {
    return isConnected || nearWalletInfo?.isConnected;
  };

  const fnNavToOrders = () => {
    navigate("/transactions");
  }
  return (
    <TransactionsBox>
      {getIsConnected() && !!pendingList.length && (
        <>
          <FlexItemsCenter>
            <StyledText interRegular fontSize="18px">
              Pending orders ({pendingList.length})
            </StyledText>
            <SellAllBtn onClick={fnNavToOrders}>Sell all</SellAllBtn>
          </FlexItemsCenter>

          <TableRowList mt="20px">
            {pendingList.map((item, index) => (
              <TableRowItem key={index} detail={item}></TableRowItem>
            ))}
          </TableRowList>
        </>
      )}
      {!getIsConnected() && (
        <Placeholder
          width="100%"
          btnClick={openWalletWin}
          preset={1}
        ></Placeholder>
      )}
      {getIsConnected() && !pendingList.length && (
        <Placeholder
          width="100%"
          btnClick={fnGoToSwap}
          preset={3}
        ></Placeholder>
      )}
    </TransactionsBox>
  );
};

const TableRowItem = ({ detail }: { detail: CatchType }) => {
  const theme = useTheme()
  const { externalChainInfo } = useSystemInfo();
  const getOriginParams = () => {
    return detail.originParams;
  };

  const openBrowser = useCallback(() => {
    let url = "";
    if (detail.originParams.fromChain.name === "NEAR") {
      url = `https://explorer.near.org/transactions/${detail.certHash}`;
    } else {
      const curObj = externalChainInfo.find(
        (item) => item.name === detail.originParams.fromChain.name
      );
      if (curObj) {
        const blockExplorerPathHash = "/tx/";
        url = `${curObj.blockBrowserUrls[0]}${blockExplorerPathHash}${detail.certHash}`;
      }
    }
    if (url) {
      (window as any).open(url, "_blank");
    }
  }, [detail.certHash, detail.originParams.fromChain.name, externalChainInfo]);

  return (
    <TableRow>
      <FlexCenter p="20px 15px 25px" justifyContent="flex-start !important;">
        <StatusBar status={detail.status}></StatusBar>

        <StyledContainer ml="34px">
          <FlexCenter justifyContent="flex-start !important;">
            <CImage
              src={getOriginParams().fromSymbol.url || ""}
              width="26px"
              height="26px"
              mr="8px"
            ></CImage>
            <StyledContainer>
              <StyledText fontSize="12px" fontWeight5>
                {getOriginParams().fromAmount}{" "}
                {getOriginParams().fromSymbol.symbol}
              </StyledText>
              <FlexCenter justifyContent="flex-start !important" mt="3px">
                <StyledText fontSize="12px" mr="5px">
                  on {getOriginParams().fromChain.fullName}
                </StyledText>
              </FlexCenter>
            </StyledContainer>
          </FlexCenter>
          <ArrowDownSvg fill={theme.basicBlack} style={{ margin: "10px 8px" }}></ArrowDownSvg>
          <FlexCenter justifyContent="flex-start !important;">
            <CImage
              src={getOriginParams().toSymbol.url || ""}
              width="26px"
              height="26px"
              mr="8px"
            ></CImage>
            <StyledContainer>
              <StyledText fontSize="12px" fontWeight5>
                {getOriginParams().toAmount} {getOriginParams().toSymbol.symbol}
              </StyledText>
              <FlexCenter justifyContent="flex-start !important" mt="3px">
                <StyledText fontSize="12px" mr="5px">
                  on {getOriginParams().toChain.fullName}
                </StyledText>
              </FlexCenter>
            </StyledContainer>
          </FlexCenter>
        </StyledContainer>
      </FlexCenter>

      <FlexItemsCenter p="15px 15px 20px" borderTop={`1px solid ${theme.lightGrey}`}>
        <StyledContainer>
          <HashHandlerItem certHash={detail.certHash || ""} openBrowser={openBrowser}></HashHandlerItem>
        </StyledContainer>
      </FlexItemsCenter>
    </TableRow>
  );
};



const TransactionsBox = styled(StyledContainer)`
  margin: 0 auto;
`;

const TableRowList = styled(StyledContainer)`
  // overflow: auto;
`;

const TableRow = styled(Card)`
  padding: 0;
  margin-bottom: 10px;

  font-size: 12px;
  font-weight: 500;
`;

const HashHandlerBox = styled(StyledContainer)`
  background-color: ${({theme}) => theme.basicGrey};
  border-radius: 6px;
  box-sizing: border-box;
  height: 36px;

  display: inline-flex;
  align-items: center;
`;

const SellAllBtn = styled(StyledContainer)`
    font-size: 12px;
    font-weight: 500;
    padding: 8px 14px;
    border-radius: 6px;
    background-color: ${({theme}) => theme.lightGrey};
    cursor: pointer;
`;

export default PendingPage;
