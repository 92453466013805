import HomePage from "pages_mobile/Home"
import MorePage from "pages_mobile/More"
import PendingPage from "pages_mobile/Pending"
import TransactionsPage from "pages_mobile/Transactions"
import { Route, Routes } from "react-router-dom"
import MobileLayout from "../pages/Layouts/MobileLayout"
import Referral from "pages/Referral"

const Routers = () => {
    return <Routes>
        <Route path="" element={<MobileLayout></MobileLayout>}>
            <Route path="/" element={<HomePage></HomePage>}></Route>
            <Route path="/transactions" element={<TransactionsPage></TransactionsPage>}></Route>
            <Route path="/pending" element={<PendingPage></PendingPage>}></Route>
            <Route path="/more" element={<MorePage></MorePage>}></Route>
            <Route path="/referral" element={<Referral></Referral>}></Route>
        </Route>
    </Routes>
}

export default Routers