import styled from "styled-components";
import {
  Card,
  PopupWinBg,
  PopupWinOutBox,
} from "style/preset";
import { ReactComponent as CloseSvg } from "assets/svgs/button-close.svg";
import { ReactNode, useRef } from "react";
import { Transition } from "react-transition-group";

type Props = {
  children?: ReactNode;
  extraStyle?: string;
  extraNode?: ReactNode;
  onClose?: () => void;
};

const bgDefStyle = {
  opacity: "0",
  transition: `all linear 0.1s`,
};

const bgActiveStyle = {
  opacity: "1 !important",
};

const contentDefStyle = {
  opacity: "0",
  top: "25% !important",
  transition: `all linear 0.1s`,
};

const contentActiveStyle = {
  opacity: "1 !important",
  top: "30% !important",
};

const BasePopup = ({ children, extraStyle, extraNode = <></>, onClose }: Props) => {
  const nodeRef = useRef(null)

  return (
    <PopupWinOutBox>
      <Transition nodeRef={nodeRef} in={true} appear={true} timeout={100}>
        {(state) => (
          <PopupWinBg
            ref={nodeRef}
            {...bgDefStyle}
            {...(state === "entered" ? bgActiveStyle : {})}
          ></PopupWinBg>
        )}
      </Transition>
      <Transition nodeRef={nodeRef} in={true} appear={true} timeout={100}>
        {(state) => (
          <PopupContent extraStyle={extraStyle} ref={nodeRef} {...contentDefStyle} {...(state === "entered" ? contentActiveStyle : {})}>
            <CloseWinBtn onClick={() => onClose && onClose()}></CloseWinBtn>
            {extraNode}
            {children}
          </PopupContent>
        )}
      </Transition>
    </PopupWinOutBox>
  );
};

const PopupContent = styled(Card)<any>`
  width: 410px;
  padding: 0px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);

  display: flex;
  flex-direction: column;

  ${({extraStyle}) => extraStyle}
`;

const CloseWinBtn = styled(CloseSvg)`
  width: 45px;
  height: 45px;
  position: absolute;
  top: -36px;
  right: -135px;
  z-index: 10;
  cursor: pointer;

  ${({ theme }) =>
  theme.mode === "day"
    ? `g {
  fill: none;
  rect {
    fill: #FFF;
  }
  path {
    fill: #121217;
  }
}`
    : `g {
  fill: #0a0a0a;
  rect {
    fill: #0a0a0a;
  }
  path {
    fill: #ebebeb;
  }
}`}
`;

export default BasePopup;