import { useState } from "react";
import { Card } from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import styled from "styled-components";
import NetworkList from "./NetworkList";
import WalletDetail from "./wallets/WalletDetail";
import WalletDetailForNear from "./wallets/WalletDetailForNear";

const ConnectedWallets = () => {
  const [openNetworkWin, setOpenNetworkWin] = useState(false)

  const fnOpenNetworkWin = () => {
    setOpenNetworkWin(true)
  }

  const fnCloseNetworkWin = () => {
    setOpenNetworkWin(false)
  }

  return (
    <>
      <StyledContainer position="absolute" right="0" top="32px" zIndex={30}>
        <StyledContainer height="20px"></StyledContainer>
        <ContentBox>
          <StyledText interRegular fontSize="16px" display="block" mb="20px" textAlign="center">
            Connected wallets
          </StyledText>

          <WalletDetail openChosedNetwork={fnOpenNetworkWin}></WalletDetail>
          <WalletDetailForNear></WalletDetailForNear>
          {
            openNetworkWin && <NetworkList closeWin={fnCloseNetworkWin}></NetworkList>
          }
        </ContentBox>
      </StyledContainer>
    </>
  );
};

const ContentBox = styled(Card)`
  padding: 20px 15px;
  width: 350px;
  cursor: default;
  position: relative;
`;

export default ConnectedWallets;
