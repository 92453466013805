import CImage from "components/CImage";
import StyledContainer from "style/styledContainer";
import {
  AssetItemBox,
  ContentScrollBox,
  FavoriteOffOn,
  FavoritesBar,
  TableRow,
  TableTh,
} from "./StyledCommon";

import { Chain } from "@chainge/sdk";
import { useSystemInfo } from "hooks/useSystemProvider";
import { useEffect, useState } from "react";

type Props = {
  dataList: Chain[];
  onChosed: (chosed: Chain) => void;
};

const SearchChain = ({ dataList, onChosed }: Props) => {
  const { updateLikeChains, likeChains } = useSystemInfo();
  const fnFavorite = (chainName: string) => {
    updateLikeChains(chainName);
  };

  const [likeData, setLikeData] = useState<Chain[]>([]);
  useEffect(() => {
    if (dataList.length) {
      const tempData = dataList.filter((item) =>
        likeChains.includes(item.name)
      );
      setLikeData(tempData);
    }
  }, [likeChains, dataList]);

  return (
    <StyledContainer flex1="1" height="0" flex column>
      <FavoritesBar dataList={likeData} onChosed={(data) => onChosed(data as Chain)} onRemove={fnFavorite} clearAllLike={() => updateLikeChains('', true)}></FavoritesBar>
      <TableRow pr="45px !important">
        <TableTh flex1="1" pl="20px">
          Network name
        </TableTh>
        <TableTh>
          <StyledContainer width="26px" m="0 15px"></StyledContainer>
        </TableTh>
      </TableRow>

      <ContentScrollBox>
        <StyledContainer height="20px"></StyledContainer>
        {dataList.map((item, index) => (
          <AssetItemNode
            key={index}
            detail={item}
            isLike={likeChains.includes(item.name)}
            onFavorite={fnFavorite}
            onClick={onChosed}
          ></AssetItemNode>
        ))}
      </ContentScrollBox>
    </StyledContainer>
  );
};

type ItemProps = {
  detail: Chain;
  isLike: boolean;
  onClick?: (detail: Chain) => void;
  onFavorite: (chainName: string) => void;
};
const AssetItemNode = ({ detail, onClick, isLike, onFavorite }: ItemProps) => {
  return (
    <AssetItemBox>
      <StyledContainer
        flex1="1"
        flex
        alignItems="center"
        pl="20px"
        height="100%"
        onClick={() => onClick && onClick(detail)}
      >
        <CImage src={detail.url} mr="10px"></CImage>
        <StyledContainer
          fontWeight5
          interMedium
          flex
          column
          justifyContent="center"
        >
          <StyledContainer fontSize="14px">{detail.fullName}</StyledContainer>
        </StyledContainer>
      </StyledContainer>
      <StyledContainer>
        <FavoriteOffOn
          isLike={isLike}
          onClick={() => onFavorite(detail.name)}
        ></FavoriteOffOn>
      </StyledContainer>
    </AssetItemBox>
  );
};

export default SearchChain;
