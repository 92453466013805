import { Chain, TokenVo } from "@chainge/sdk";
import BalanceRate from "components/BalanceRate";
import CImage from "components/CImage";
import StatusBar from "components/StatusBar";
import { useImperativeHandle, useState } from "react";
import { ReactComponent as SwapSvg } from "assets/svgs/icon-btn-swap.svg";
import {
  basicBlack,
  Card,
  FlexCenter,
  FlexItemsCenter,
  lightYellow,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import styled from "styled-components";
import { formatNum } from "utils";
import BasePopup from "../BasePopup";

type Props = {
  innerRef: any;
  fromChain: Chain;
  fromSymbol: TokenVo;
  toChain: Chain;
  toSymbol: TokenVo;
  fromAmount: string;
  toAmount: string;
  gas: string;
  slippage: string;
  fromPrice: string;
  toPrice: string;

  executeType: string;

  onClick?: (executeType: string) => void;
};

const ConfirmOrder = ({
  innerRef,
  fromChain,
  fromSymbol,
  toChain,
  toSymbol,
  fromAmount,
  toAmount,
  gas,
  slippage,
  fromPrice,
  toPrice,
  executeType,
  onClick,
}: Props) => {
  const [open, setOpen] = useState(false);
  const fnOpenWin = () => {
    setOpen(true);
  };
  const fnCloseWin = () => {
    setOpen(false);
  };

  useImperativeHandle(innerRef, () => ({
    openWin: fnOpenWin,
    closeWin: fnCloseWin,
  }));

  const fnExecute = () => {
    if (onClick) {
      fnCloseWin();
      onClick(executeType);
    }
  };

  return (
    <>
      {open && (
        <BasePopup title="Confirm the swap" onClose={fnCloseWin}>
          <FlexItemsCenter
            flex1="1"
            justifyContent="flex-start !important"
            flexDirection="column"
          >
            <StyledContainer flex1="1" width="100%">
              <InfoCard>
                <StyledContainer className="info_item">
                  <FlexItemsCenter>
                    <StyledText className="base_title">You’ll swap</StyledText>
                    <StyledText fontSize="12px" interRegular>
                      {fromPrice}
                    </StyledText>
                  </FlexItemsCenter>
                  <FlexItemsCenter className="pay_info_item">
                    <CImage
                      src={fromSymbol.url}
                      width="22px"
                      height="22px"
                    ></CImage>
                    <StyledText className="psy_info">
                      {formatNum(fromAmount)} {fromSymbol.symbol}
                    </StyledText>
                  </FlexItemsCenter>
                  <StyledContainer textAlign="right">
                    <FlexCenter className="chain_info_item">
                      <CImage
                        src={fromChain.url}
                        width="14px"
                        height="14px"
                      ></CImage>
                      <StyledText className="chain_info">
                        on {fromChain.fullName}
                      </StyledText>
                    </FlexCenter>
                  </StyledContainer>
                </StyledContainer>
                <LineWrapper></LineWrapper>
                <StyledContainer className="info_item">
                  <FlexItemsCenter>
                    <StyledText className="base_title">Into</StyledText>
                    <StyledText fontSize="12px" interRegular>
                      {toPrice}
                    </StyledText>
                  </FlexItemsCenter>
                  <FlexItemsCenter className="pay_info_item">
                    <CImage
                      src={toSymbol.url}
                      width="22px"
                      height="22px"
                    ></CImage>
                    <StyledText className="psy_info">
                      {formatNum(toAmount)} {toSymbol.symbol}
                    </StyledText>
                  </FlexItemsCenter>
                  <StyledContainer textAlign="right">
                    <FlexCenter className="chain_info_item">
                      <CImage
                        src={toChain.url}
                        width="14px"
                        height="14px"
                      ></CImage>
                      <StyledText className="chain_info">
                        on {toChain.fullName}
                      </StyledText>
                    </FlexCenter>
                  </StyledContainer>
                </StyledContainer>
              </InfoCard>
              <StyledContainer p="30px 20px">
                <BalanceRate
                  type="rate"
                  loading={false}
                  fromAmount={fromAmount}
                  fromSymbol={fromSymbol.symbol}
                  toAmount={toAmount}
                  toSymbol={toSymbol.symbol}
                  mt="0px"
                ></BalanceRate>
                <StyledContainer height="30px"></StyledContainer>
                <StatusBar isGas value={gas} suffix={` ${toSymbol?.symbol || ''}`}></StatusBar>
                <StyledContainer height="20px"></StyledContainer>
                <StatusBar isGas={false} value={slippage}></StatusBar>
                <StyledContainer height="30px"></StyledContainer>
              </StyledContainer>
            </StyledContainer>
          </FlexItemsCenter>
          <StyledContainer p="20px 20px 50px">
            <BtnBox onClick={fnExecute}>
              <BtnText>Swap now</BtnText>
              <SwapSvg></SwapSvg>
            </BtnBox>
          </StyledContainer>
        </BasePopup>
      )}
    </>
  );
};

const InfoCard = styled(Card)`
  padding: 0;
  margin: 0 10px;

  .info_item {
    padding: 15px 10px;

    .base_title {
      font-size: 14px;
      font-weight: 500;
    }

    .pay_info_item {
      justify-content: flex-start;
      margin-top: 15px;

      .psy_info {
        font-size: 16px;
        font-weight: 600;
        font-family: InterSemiBold;
        margin-left: 8px;
      }
    }

    .chain_info_item {
      background-color: ${({ theme }) => theme.basicGrey};
      padding: 6px 8px;
      border-radius: 6px;
      margin-top: 8px;
      display: inline-flex;
      .chain_info {
        font-size: 12px;
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }
`;

const BtnBox = styled(FlexItemsCenter)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 17px 15px;
  background-color: ${lightYellow};
`;

const BtnText = styled.span`
  font-family: InterSemiBold;
  font-size: 14px;
  line-height: 1.43;
  color: ${basicBlack};
`;

const LineWrapper = styled(StyledContainer)`
  background-color: ${({ theme }) => theme.basicGrey};
  height: 1px;
`;
const ContentBox = styled(StyledContainer)`
  overflow: auto;
`;

export default ConfirmOrder;
