import { appKey as appKeyForSys } from "utils/sdk";

const baseUrl = 'https://dapp.chainge.finance/api'
export const getHeaderInfo = async (requestParams = {}) => {
    let signature = "";
    let appKey = appKeyForSys;
    let _retryCount = 0;
    let timestamp = 0;
    const expireTime = 15000
    do {
      const response = await fetch(baseUrl + '/open/v1/base/getServerTime')
      const result = await response.json()
      timestamp = new Date().getTime();
      if (
        result.code === 200 && 
        result.data &&
        result.data.serverTimeStamp
      ) {
        timestamp = result.data.serverTimeStamp;
      }
      try {
        const signResponse = await fetch('https://dapp.chainge.finance/sign/getSign?appKey=' + appKey,  {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            expiretime: expireTime,
            timestamp
          } as any,
          body: JSON.stringify(requestParams),
        })
        const signResult = await signResponse.json()
        signature = signResult.sign;
        appKey = signResult.appKey;
        break;
      } catch (error) {
        _retryCount++;
      }
    } while (_retryCount <= 3);

    const headers = {
      expireTime: expireTime,
      timestamp,
      signature: signature,
      appKey,
    };
    return headers;
}

export const requestHttp = async (method: string, url: string, params: any) => {
  try {
    const header = await getHeaderInfo(params)
    const response = await await fetch(`${baseUrl}${url}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...header
      } as any,
      body: JSON.stringify(params),
    })
    const result = await response.json()
    return result
  } catch(error) {
    return ''
  }
}