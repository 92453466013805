import styled, { useTheme } from "styled-components";
import { Card, FlexCenter, PopupWinBg, PopupWinOutBox } from "style/preset";
import { ReactComponent as CloseSvg } from "assets/svgs/mobile/button-close.svg";
import { ReactComponent as LeftSvg } from "assets/svgs/icons-arrows-left-03.svg";
import { ReactNode, useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import StyledText from "style/styledText";
import StyledContainer from "style/styledContainer";

type Props = {
  title: string;
  children?: ReactNode;
  onClose?: () => void;
  onBack?: () => void;
  showBack?: boolean;
  extraNode?: ReactNode;
  extraStyle?: string;
};

const bgDefStyle = {
  opacity: "0",
  transition: `all linear 0.1s`,
};

const bgActiveStyle = {
  opacity: "1 !important",
};

const contentDefStyle = {
  opacity: "0",
  top: "100vh !important",
  transition: `all linear 0.1s`,
};

const contentActiveStyle = {
  opacity: "1 !important",
  top: "0 !important",
};

const BasePopup = ({
  title,
  children,
  showBack = false,
  onClose,
  onBack,
  extraNode = <></>,
  extraStyle,
}: Props) => {
  const nodeRef = useRef(null);
  const theme = useTheme()

  return (
    <PopupWinOutBox>
      <Transition nodeRef={nodeRef} in={true} appear={true} timeout={100}>
        {(state) => (
          <PopupWinBg
            ref={nodeRef}
            {...bgDefStyle}
            {...(state === "entered" ? bgActiveStyle : {})}
          ></PopupWinBg>
        )}
      </Transition>
      <Transition nodeRef={nodeRef} in={true} appear={true} timeout={100}>
        {(state) => (
          <PopupContent
            ref={nodeRef}
            {...contentDefStyle}
            {...(state === "entered" ? contentActiveStyle : {})}
          >
            <BaseHeader extraStyle={extraStyle}>
              <FlexCenter position="relative" width="100%">
                <StyledText interSemiBold fontWeight6 fontSize="18px">
                  {title}
                </StyledText>
                {showBack && (
                  <LeftSvgWrapper
                    fill={theme.basicBlack}
                    onClick={() => onBack && onBack()}
                  ></LeftSvgWrapper>
                )}
                <CloseWinBtn onClick={() => onClose && onClose()}></CloseWinBtn>
              </FlexCenter>
              {extraNode}
            </BaseHeader>
            <StyledContainer flex column flex1="1" overflow="auto">
              {children}
            </StyledContainer>
          </PopupContent>
        )}
      </Transition>
    </PopupWinOutBox>
  );
};

const PopupContent = styled(Card)`
  width: 100%;
  padding: 0px;
  border-radius: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const BaseHeader = styled(FlexCenter)<any>`
  flex-direction: column;
  padding: 0 10px;
  height: 78px;
  margin-bottom: 15px;

  ${({ extraStyle }) => extraStyle}
`;

const CloseWinBtn = styled(CloseSvg)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  right: 0;

  ${({ theme }) =>
    theme.mode === "day"
      ? `g {
  fill: none;
  circle {
    fill: #F0F3F9;
  }
  path {
    fill: #121217;
  }
}`
      : `g {
  fill: #1f1f1f;
  rect {
    fill: #1f1f1f;
  }
  path {
    fill: #ebebeb;
  }
}`}
`;

const LeftSvgWrapper = styled(LeftSvg)`
  cursor: pointer;
  position: absolute;
  left: 0;
`;

export default BasePopup;
