import { DefObj } from "types/baseType";
import { getStore } from "utils/store";

export const recordForInviteCode = async (certHash: string, data: DefObj) => {
    try {
        const inviteCode = getStore('chainge.inviteCode')
        const fromAddress = data ? data['fromAddress'] : ''
        const params = {
            user: fromAddress,
            code: inviteCode,
            certHash: certHash
        }
        fetch(`https://openadmin.chainge.finance/api/promotion_record`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            } as any,
            body: JSON.stringify(params),
        })
    } catch(error) {}
}