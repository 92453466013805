import { Link, useLocation } from "react-router-dom";
import {
  basicBlack,
  deepGrey,
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import styled, { DefaultTheme, useTheme } from "styled-components";

import { ReactComponent as PaySvg } from "assets/svgs/mobile/icon-03-menu-pay.svg";
import { ReactComponent as OrderSvg } from "assets/svgs/mobile/icons-misc-transactions.svg";
import { ReactComponent as PendingSvg } from "assets/svgs/mobile/icons-misc-pending.svg";
import { ReactComponent as MoreSvg } from "assets/svgs/mobile/icons-misc-expand.svg";
import StyledText from "style/styledText";
import StyledContainer from "style/styledContainer";
import { useOrder } from "hooks/useOrderProvider";
import { useSystemInfo } from "hooks/useSystemProvider";

const navList = [
  {
    text: "Swap",
    icon: 1,
    path: "/",
  },
  {
    text: "Orders",
    icon: 2,
    path: "/transactions",
  },
  {
    text: "Pending",
    icon: 3,
    path: "/pending",
  },
  {
    text: "More",
    icon: 4,
    path: "/more",
  },
];
const NavBar = () => {
  const theme = useTheme()
  const { isLoadingForExec } = useSystemInfo()
  const { pendingList } = useOrder()
  const location = useLocation();

  const getIcon = (index: number) => {
    switch (index) {
      case 1:
        return <PaySvg fill={location.pathname === '/' ? theme.basicBlack: theme.deepGrey}></PaySvg>;
      case 2:
        return <OrderSvg fill={location.pathname === '/transactions' ? theme.basicBlack: theme.deepGrey}></OrderSvg>;
      case 3:
        return <PendingSvg fill={location.pathname === '/pending' ? theme.basicBlack: theme.deepGrey}></PendingSvg>;
      default:
        return <MoreSvg fill={location.pathname === '/more' ? theme.basicBlack: theme.deepGrey}></MoreSvg>;
    }
  };
  
  return (
    <>
      <NavBarWrapper>
        {navList.map((item, index) => (
          <NavItem
            key={index}
            to={isLoadingForExec ? '#' : item.path}
            active={+(location.pathname === item.path)}
          >
            {getIcon(item.icon)}
            <StyledText mt="8px">{item.text}</StyledText>
            {
              index === 2 && pendingList.length > 0 && <SubNum>{pendingList.length}</SubNum>
            }
          </NavItem>
        ))}
      </NavBarWrapper>
      <StyledContainer height="62px"></StyledContainer>
    </>
  );
};

const getColor = ({ active, theme }: { active: boolean, theme: DefaultTheme }) => {
  return active ? theme.basicBlack : theme.deepGrey;
};

const getBgColor = ({ active, theme }: { active: boolean, theme: any }) => {
  return active ? theme.navBarActive : theme.basicWhite;
};

const NavBarWrapper = styled(FlexItemsCenter)`
  background-color: ${({theme}) => theme.basicWhite};
  height: 62px;
  padding: 0 8px;
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
`;

const NavItem = styled(Link)<any>`
  font-family: ${({ active }) => (active ? "InterMedium" : "InterRegular")};
  font-weight: ${({ active }) => (active ? 500 : "normal")};
  font-size: 12px;
  line-height: 1.17;
  color: ${getColor};
  background-color: ${getBgColor};
  cursor: pointer;
  border-radius: 9px;
  text-decoration: none;

  height: 54px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;

  position:relative;
`;

const SubNum = styled(FlexCenter)`
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  background-color: #2ec4b6;
  width: 16px;
  height: 16px;
  font-family: InterSemiBold;
  color: ${({theme}) => theme.basicWhite};
  border-radius: 50%;
  top: 3px;
  right: 20px;
`

export default NavBar;
