import { basicBlack, Card, FlexItemsCenter, lightYellow } from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import styled, { useTheme } from "styled-components";

import { ReactComponent as SwapSvg } from "assets/svgs/icon-btn-swap.svg";
import { ReactComponent as LockSvg } from "assets/svgs/icon-btn-lock.svg";
import { ReactNode } from "react";
import { useSystemInfo } from "hooks/useSystemProvider";

type Props = {
  url?: string;
  title?: string;
  des?: ReactNode;
  hasBtn?: boolean;
  btnText?: string;
  btnClick?: () => void;
  width?: string

  preset?: number;
};

const disconnectInfo = {
  title: `Looks like you haven't connected your wallets yet`,
  des: `No transaction history is available at this time because your wallet is not connected.`,
  hasBtn: true,
  btnText: `Connect wallets`,
};

const noTxInfo = {
    title: `No transactions yet`,
    des: `You haven't made any swapping or bridging transactions yet. Tap on the button to start.`,
    hasBtn: true,
    btnText: `Swap or Bridge`,
  };

const Placeholder = ({
  url,
  title,
  des,
  hasBtn,
  btnClick,
  btnText,
  preset = 1,
  width = '629px'

}: Props) => {
  const { isMobile } = useSystemInfo()
  const theme = useTheme()

  if (preset === 1) {
    url = theme.iconForEmptyIllustration;
    title = disconnectInfo.title;
    des = disconnectInfo.des;
    hasBtn = disconnectInfo.hasBtn;
    btnText = disconnectInfo.btnText;
  } else if(preset === 2) {
    url = theme.iconForNoTxIllustration;
    title = noTxInfo.title;
    des = noTxInfo.des;
    hasBtn = noTxInfo.hasBtn;
    btnText = noTxInfo.btnText;
  } else if(preset === 3) {
    url = theme.iconForNoTxIllustration;
    title = "No pending orders";
    des = "There are currently no pending orders";
    hasBtn = noTxInfo.hasBtn;
    btnText = noTxInfo.btnText;
  }

  return (
    <PlaceholderBox width={width}>
      <ImgBox src={url}></ImgBox>
      <StyledText
        textAlign="center"
        fontSize="18px"
        mt="36px"
        mb="20px"
        fontWeight5
        lineHeight={1.11}
      >
        {title}
      </StyledText>
      <StyledContainer
        textAlign="center"
        fontSize="14px"
        lineHeight={1.29}
        interRegular
        width={isMobile ? '100%' : '60%'}
      >
        {des}
      </StyledContainer>
      {hasBtn && (
        <BtnBox mt="40px !important" onClick={() => btnClick && btnClick()}>
          <BtnText>{btnText}</BtnText>
          {
            preset === 1 ? <LockSvg></LockSvg> : <SwapSvg></SwapSvg>
          }
        </BtnBox>
      )}
    </PlaceholderBox>
  );
};

const PlaceholderBox = styled(Card)`
  box-sizing: border-box;
  padding: 30px 35px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
`;

const ImgBox = styled.img`
  height: 105px;
`;

const BtnBox = styled(FlexItemsCenter)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 17px 15px;
  background-color: ${lightYellow};

  width: 210px;
`;

const BtnText = styled.span`
  font-family: InterSemiBold;
  font-size: 14px;
  line-height: 1.43;
  color: ${basicBlack};
`;

export default Placeholder;
