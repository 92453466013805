import styled from "styled-components";
import { BaseProps } from "types/baseType";
import defImgIcon from 'assets/images/icon-04-misc-placeholder@3x.png'

type Props = {
  width?: string;
  height?: string;
  src: string;
} & BaseProps

const CImage = ({
  width = "26px",
  height = "26px",
  ml,
  mr,
  mb,
  mt,
  m,
  src,
}: Props) => {
  const imgLoadFail = (event: any) => {
    event.target.src = defImgIcon
  }
  return (
    <Image
      width={width}
      height={height}
      src={src}
      ml={ml}
      mr={mr}
      mb={mb}
      mt={mt}
      m={m}
      onError={imgLoadFail}
    ></Image>
  );
};

const Image = styled.img<any>`
  width: ${({ width }) => width};
  width: ${({ height }) => height};
  border-radius: 50%;
  margin: ${({ m }) => m || null};
  margin-left: ${({ ml }) => ml || null};
  margin-right: ${({ mr }) => mr || null};
  margin-bottom: ${({ mb }) => mb || null};
  margin-top: ${({ mt }) => mt || null};
`;

export default CImage;
