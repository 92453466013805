import { useSystemInfo } from "hooks/useSystemProvider";
import { Link, useLocation } from "react-router-dom";
import { FlexCenter } from "style/preset";
import styled from "styled-components";

const NavBar = () => {
  const location = useLocation()
  const { isLoadingForExec } = useSystemInfo()
  return (
    <FlexCenter>
      <NavItem to={isLoadingForExec ? '#' : '/'} active={+(location.pathname === '/')}>Swap & Bridge</NavItem>
      <NavItem to={isLoadingForExec ? '#' : '/transactions'} active={+(location.pathname === '/transactions')}>Orders</NavItem>
      <NavItem to={'https://orchidexchange.xyz'} target="_blank" rel="noopener noreferrer" active={+(location.pathname === '/aboutus')}>About Us</NavItem>
      {/* <NavItem to={isLoadingForExec ? '#' : '/referral'} active={+(location.pathname === '/referral')}>Referral</NavItem> */}
    </FlexCenter>
  );
};


const getColor = ({active, theme}: {active: boolean, theme: any}) => {
  return active ? theme.basicGreen : theme.basicBlack
}
const NavItem = styled(Link)<any>`
  font-family: ${({ active }) => (active ? "InterSemiBold" : "InterRegular")};
  font-weight: ${({ active }) => (active ? 600 : 'normal')} ;
  font-size: 14px;
  line-height: 1.14;
  color: ${getColor};
  cursor: pointer;
  padding-left: 12.5px;
  padding-right: 12.5px;
  text-decoration: none;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background-color: ${getColor};
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%, 0);

    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: all linear 0.2s;
  }
  &:hover {
    &:after {
      content: "";
      opacity: 1;
      background-color: ${getColor};
    }
  }
`;

export default NavBar;
