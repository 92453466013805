import {
  BaseLayoutScreenSection,
  deepGrey,
  FlexCenter,
  PopupWinBg,
} from "style/preset";
import StyledContainer from "style/styledContainer";
import StyledText from "style/styledText";
import styled, { useTheme } from "styled-components";

import { ReactComponent as CopySvg } from "assets/svgs/icon-02-actions-copy.svg";
import { ReactComponent as ExplorerSvg } from "assets/svgs/icons-misc-explorer.svg";
import { ReactComponent as DeleteSvg } from "assets/svgs/icons-actions-delete.svg";
import { ReactComponent as SuccessSvg } from "assets/svgs/icons-misc-success.svg";
import { ReactComponent as FailedSvg } from "assets/svgs/icons-misc-failed.svg";
import CImage from "components/CImage";
import { useOrder } from "hooks/useOrderProvider";
import { CatchType } from "types/baseType";
import moment from "moment";
import { shortStr } from "utils";
import { useSystemInfo } from "hooks/useSystemProvider";
import { useCallback, useEffect, useState } from "react";
import { removeOrder } from "utils/store";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Placeholder from "components/Placeholder";
import { useAccount } from "wagmi";
import { useWalletInfo } from "hooks/useWalletProvider";
import { useNavigate } from "react-router-dom";

const Transactions = () => {
  const { orderList } = useOrder();
  const { isConnected } = useAccount();
  const { nearWalletInfo, openWalletWin } = useWalletInfo();

  const navigate  = useNavigate()
  const fnGoToSwap = () => {
    navigate('/')
  }

  const getIsConnected = () => {
    return (isConnected || nearWalletInfo?.isConnected)
  }
  return (
    <TransactionsBox>
      { getIsConnected() && !!orderList.length && (
        <>
          <StyledText interRegular fontSize="18px">
            All orders
          </StyledText>
          <TableHeader>
            <StyledContainer flex1="2" pl="20px">
              Status
            </StyledContainer>
            <StyledContainer flex1="2.5">Hash</StyledContainer>
            <StyledContainer flex1="3">Source</StyledContainer>
            <StyledContainer flex1="3">Destination</StyledContainer>
            <StyledContainer flex1="1" pr="15px"></StyledContainer>
          </TableHeader>
          <TableRowList>
            {orderList.map((item, index) => (
              <TableRowItem key={index} detail={item}></TableRowItem>
            ))}
          </TableRowList>
        </>
      )}
      {
       !getIsConnected() && <Placeholder btnClick={openWalletWin} preset={1}></Placeholder>
      }
      {
        getIsConnected() && !orderList.length && <Placeholder btnClick={fnGoToSwap} preset={2}></Placeholder>
      }
    </TransactionsBox>
  );
};

const TableRowItem = ({ detail }: { detail: CatchType }) => {
  const theme = useTheme()
  const { externalChainInfo } = useSystemInfo();
  const getOriginParams = () => {
    return detail.originParams;
  };
  const checkSuccessful = () => {
    return detail.status === "successful";
  };

  const openBrowser = useCallback(() => {
    let url = "";
    if (detail.originParams.fromChain.name === "NEAR") {
      url = `https://explorer.near.org/transactions/${detail.certHash}`;
    } else {
      const curObj = externalChainInfo.find(
        (item) => item.name === detail.originParams.fromChain.name
      );
      if (curObj) {
        const blockExplorerPathHash = "/tx/";
        url = `${curObj.blockBrowserUrls[0]}${blockExplorerPathHash}${detail.certHash}`;
      }
    }
    if (url) {
      (window as any).open(url, "_blank");
    }
  }, [detail.certHash, detail.originParams.fromChain.name, externalChainInfo]);

  const openBrowserForAddress = useCallback(
    (type: string) => {
      let url = "";
      if (type === "from") {
        const chainName = detail.originParams.fromChain.name;
        if (chainName === "NEAR") {
          url = `https://explorer.near.org/accounts/${detail.originParams.nearAddress}`;
        } else {
          const curObj = externalChainInfo.find(
            (item) => item.name === chainName
          );
          if (curObj) {
            url = `${curObj.blockBrowserUrls[0]}/address/${detail.originParams.evmAddress}`;
          }
        }
      } else {
        const chainName = detail.originParams.toChain.name;
        if (chainName === "NEAR") {
          url = `https://explorer.near.org/accounts/${detail.originParams.nearAddress}`;
        } else {
          const curObj = externalChainInfo.find(
            (item) => item.name === chainName
          );
          if (curObj) {
            url = `${curObj.blockBrowserUrls[0]}/address/${detail.originParams.evmAddress}`;
          }
        }
      }
      if (url) {
        (window as any).open(url, "_blank");
      }
    },
    [
      detail.originParams.evmAddress,
      detail.originParams.fromChain.name,
      detail.originParams.nearAddress,
      detail.originParams.toChain.name,
      externalChainInfo,
    ]
  );

  const fnRemoveCatch = () => {
    removeOrder(detail.uuid);
    fnCloseConfirm();
  };

  const [confirmOpen, setConfirmOpen] = useState(false)
  const fnOpenConfirm = () => {
    setConfirmOpen(true)
  }
  const fnCloseConfirm = () => {
    setConfirmOpen(false)
  }
  return (
    <TableRow>
      <StyledContainer
        flex1="2"
        pl="20px"
        fontSize="12px"
        fontWeight5
        flex
        alignItems="center"
      >
        {checkSuccessful() ? (
          <SuccessSvg></SuccessSvg>
        ) : (
          <FailedSvg fill={theme.orderPending}></FailedSvg>
        )}
        <StyledContainer ml="8px">
          <StyledText color={checkSuccessful() ? theme.basicGreen : theme.orderPending} lineHeight={1.17}>Swap</StyledText>
          <StyledText
            lineHeight={1.17}
            display="block"
            mb="6px"
            textTransform="capitalize"
            color={checkSuccessful() ? theme.basicGreen : theme.orderPending}
          >
            {detail.status}
          </StyledText>
          <TimeLabel interRegular fontWeight4>
            {moment(detail.date).format("DD MMM YY, HH:mm")}
          </TimeLabel>
        </StyledContainer>
      </StyledContainer>
      <StyledContainer flex1="2.5">
        <HashHandlerItem
          certHash={detail.certHash || ""}
          openBrowser={openBrowser}
        ></HashHandlerItem>
      </StyledContainer>
      <StyledContainer flex1="3">
        <FlexCenter justifyContent="flex-start !important">
          <StyledContainer
            fontSize="12px"
            fontWeight5
            color={deepGrey}
            width="60px"
          >
            Asset:
          </StyledContainer>
          <CImage
            src={getOriginParams().fromSymbol.url || ""}
            width="16px"
            height="16px"
            mr="8px"
          ></CImage>
          <StyledText fontSize="12px" fontWeight5>
            {getOriginParams().fromAmount} {getOriginParams().fromSymbol.symbol}
          </StyledText>
        </FlexCenter>
        <FlexCenter justifyContent="flex-start !important" mt="10px">
          <StyledContainer
            fontSize="12px"
            fontWeight5
            color={deepGrey}
            width="60px"
          >
            Network:
          </StyledContainer>
          <CImage
            src={getOriginParams().fromChain.url || ""}
            width="16px"
            height="16px"
            mr="8px"
          ></CImage>
          <StyledText fontSize="12px" fontWeight5 mr="5px">
            {getOriginParams().fromChain.fullName}
          </StyledText>
          <ExplorerSvg
            fill={theme.basicBlack}
            style={{ cursor: "pointer" }}
            onClick={() => openBrowserForAddress("from")}
          ></ExplorerSvg>
        </FlexCenter>
      </StyledContainer>
      <StyledContainer flex1="3">
        <FlexCenter justifyContent="flex-start !important">
          <StyledContainer
            fontSize="12px"
            fontWeight5
            color={deepGrey}
            width="60px"
          >
            Asset:
          </StyledContainer>
          <CImage
            src={getOriginParams().toSymbol.url || ""}
            width="16px"
            height="16px"
            mr="8px"
          ></CImage>
          <StyledText fontSize="12px" fontWeight5>
            {getOriginParams().toAmount} {getOriginParams().toSymbol.symbol}
          </StyledText>
        </FlexCenter>
        <FlexCenter justifyContent="flex-start !important" mt="10px">
          <StyledContainer
            fontSize="12px"
            fontWeight5
            color={deepGrey}
            width="60px"
          >
            Network:
          </StyledContainer>
          <CImage
            src={getOriginParams().toChain.url || ""}
            width="16px"
            height="16px"
            mr="8px"
          ></CImage>
          <StyledText fontSize="12px" fontWeight5 mr="5px">
            {getOriginParams().toChain.fullName}
          </StyledText>
          <ExplorerSvg
            fill={theme.basicBlack}
            style={{ cursor: "pointer" }}
            onClick={() => openBrowserForAddress("to")}
          ></ExplorerSvg>
        </FlexCenter>
      </StyledContainer>
      <StyledContainer flex1="1" flex justifyContent="flex-end" pr="15px">
        {/* {!checkSuccessful() && <HelpBtn>Help</HelpBtn>} */}
        <DeleteSvgWrapper onClick={fnOpenConfirm}></DeleteSvgWrapper>
      </StyledContainer>
      {
        confirmOpen && <ConfirmDelOrder onCancel={fnCloseConfirm} onConfirm={fnRemoveCatch}></ConfirmDelOrder>
      }
    </TableRow>
  );
};

export const HashHandlerItem = ({
  certHash,
  openBrowser,
}: {
  certHash: string;
  openBrowser: () => void;
}) => {
  const theme = useTheme()
  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  }, [isCopy]);

  return (
    <HashHandlerBox>
      <StyledContainer fontSize="12px" width="110px" ml="10px">
        {isCopy ? "Copied" : certHash ? shortStr(certHash, 4) : ""}
      </StyledContainer>
      <CopyToClipboard text={certHash || ""} onCopy={() => setIsCopy(true)}>
        <ImgBox>
          <CopySvg fill={theme.basicBlack}></CopySvg>
        </ImgBox>
      </CopyToClipboard>
      <ImgBox ml="3px" mr="4px" onClick={openBrowser}>
        <ExplorerSvg style={{width: '12px', height: '12px'}} fill={theme.basicBlack}></ExplorerSvg>
      </ImgBox>
    </HashHandlerBox>
  );
};

type ConfirmProps = {
  onConfirm: () => void;
  onCancel: () => void;
}
const ConfirmDelOrder = ({onConfirm, onCancel}:ConfirmProps) => {
  return <ConfirmBox>
    <StyledText fontSize="12px" interRegular mr="15px">Delete the order?</StyledText>
    <ConfirmBtn onClick={onConfirm}>Confirm</ConfirmBtn>
    <CnacelBtn onClick={onCancel}>Cancel</CnacelBtn>
  </ConfirmBox>
}

const TransactionsBox = styled(BaseLayoutScreenSection)`
  margin: 0 auto;
`;

const TableHeader = styled(StyledContainer)`
    margin: 15px 0 2px;
    padding: 18px 0;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 2px 4px 0 ${({theme}) => theme.orderListShadow};
    background-color: ${({theme}) => theme.basicWhite};

    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}
`;

const TableRowList = styled(StyledContainer)`
  overflow: auto;
  max-height: 57vh;
`;

const TableRow = styled(StyledContainer)`
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 ${({theme}) => theme.orderListShadow};
  background-color: ${({theme}) => theme.basicWhite};
  height: 90px;
  margin-bottom: 1px;

  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  position: relative;
  overflow: hidden;
`;

const HashHandlerBox = styled(StyledContainer)`
  background-color: ${({theme}) => theme.basicGrey};
  border-radius: 6px;
  box-sizing: border-box;
  height: 36px;

  display: inline-flex;
  align-items: center;
`;

const ImgBox = styled(FlexCenter)`
  box-sizing: border-box;
  padding: 8px;
  border-radius: 2px;
  background-color: ${({theme}) => theme.orderImgBox};
  cursor: pointer;
`;

const DeleteSvgWrapper = styled(DeleteSvg)`
  padding: 6px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.orderDelBox};
  cursor: pointer;
  fill: ${({theme}) => theme.orderDelFill};
`;

const HelpBtn = styled(StyledContainer)`
  padding: 8px 15px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.basicGrey};
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  cursor: pointer;
`;

const TimeLabel = styled(StyledText)`
  color:  ${({theme}) => theme.basicBlack};
`

const ConfirmBox = styled(PopupWinBg)`
  position: absolute;
  padding: 15px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({theme}) => theme.confirmBg};
`

const CnacelBtn = styled(HelpBtn)`
  margin-right: 0;
  padding: 8px 8.5px;
  margin-left: 5px;
`

const ConfirmBtn = styled(CnacelBtn)`
  padding: 8px 8.5px;
  margin-left: 0px;
  background-color: ${({theme}) => theme.orderDelBox};
  color: ${({theme}) => theme.orderDelFill};
`

export default Transactions;
