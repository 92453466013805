import { ethers } from "ethers";
import {
  AggregateParamsExtend,
  CrossChainParamsExtend,
  CurWalletObj,
  ExecuteCallBackType,
  ExecuteParamsExtend,
  OriginParams,
} from "types/baseType";
import chainge, { updateConfigByDefault } from "utils/sdk";
import {updateConfigByOshi} from "utils/sdk/oshiSdk";
import { getStore, setOrderStore, setStore } from "utils/store";
import { callMethod, signMsgByNear } from "./nearService";
import { v4 as uuidv4 } from "uuid";
import { b58ToHex, signMsgByEvm } from "utils";
import { requestHttp } from "utils/request";
import { Data, Order, OrderDetailByHashAndEvmAddressParams, Raw } from "@chainge/sdk";
// import { getCurProvider } from "utils/provider";
import { recordForInviteCode } from "./extraService";

export const allEvmExecute = async (
  provider: any,
  allParams: OriginParams,
  callbackOptions: ExecuteCallBackType
) => {
  try {
    let defaultFeeLevel = allParams.feeLevel
    // Oshi
    if(allParams.fromSymbol.symbol === 'OSHI' && allParams.toSymbol.symbol === 'OSHI') {
      defaultFeeLevel = 100;
    }
    if(allParams.fromSymbol.symbol === 'OSHI' && allParams.toSymbol.symbol !== 'OSHI') {
      defaultFeeLevel = 500;
    }

    const params = {
      evmAddress: allParams.evmAddress,
      feeLevel: defaultFeeLevel,
      fromAddress: allParams.evmAddress,
      fromAmount: allParams.fromAmount,
      fromChain: allParams.fromChain.name,
      fromToken: allParams.fromSymbol.symbol,
      toChain: allParams.toChain.name,
      toToken: allParams.toSymbol.symbol,
      referralCode: allParams.referralCode
    };
    const options = {
      mills: 3000,
      progressCallback: (data: any) => {
        if (data.status === "SUCCEDED") {
          callbackOptions.lockCb(false);
          callbackOptions.refreshBalance();
        }
        catchOrder(data, allParams);
      },
      orderCallback: (data: any) => {
        catchOrder(data, allParams);
      },
    };
    callbackOptions.lockCb(true);

    // oshi
    if(allParams.fromSymbol.symbol === 'OSHI' || allParams.toSymbol.symbol === 'OSHI') {
      updateConfigByOshi();
    } else {
      updateConfigByDefault();
    }

    // await chainge.execute(getCurProvider(), params, options);
    await chainge.execute(provider, params, options);
  } catch (error) {
    console.log("error:", error);
  } finally {
    callbackOptions.lockCb(false);
  }
};

export const evmForReSubmit = async (params: ExecuteParamsExtend, uuid: string, allParams: OriginParams) => {
  let result
  if(params.fromToken === params.toToken && params.fromChain !== params.toChain) {
    // Oshi
    let defaultFeeLevel = allParams.feeLevel
    if(params.fromToken === 'OSHI') {
      defaultFeeLevel = 100;
    }

    const tempParams: CrossChainParamsExtend = {
      certHash: params.certHash as string,
      evmAddress: params.evmAddress,
      feeLevel: defaultFeeLevel,
      fromAddress: params.fromAddress,
      fromAmount: params.fromAmount,
      fromChain: params.fromChain,
      fromToken: params.fromToken,
      toChain: params.toChain,
      referralCode: params.referralCode || '',
    }

    // oshi
    if(params.fromToken === 'OSHI') {
      updateConfigByOshi();
    } else {
      updateConfigByDefault();
    }
    result = await chainge.submitCrossChain(tempParams)
  } else {
    // Oshi
    let defaultFeeLevel = allParams.feeLevel
    if(params.fromToken === 'OSHI' && params.toToken !== 'OSHI') {
      defaultFeeLevel = 500;
    }

    const tempParams: AggregateParamsExtend = {
      certHash: params.certHash as string,
      evmAddress: params.evmAddress,
      feeLevel: defaultFeeLevel,
      fromAddress: params.fromAddress,
      fromAmount: params.fromAmount,
      fromChain: params.fromChain,
      fromToken: params.fromToken,
      toChain: params.toChain,
      toToken: params.toToken,
      referralCode: params.referralCode || '',
    }

    // oshi
    if(params.fromToken === 'OSHI' && params.toToken !== 'OSHI') {
      updateConfigByOshi()
    } else {
      updateConfigByDefault()
    }
    result = await chainge.submitAggregate(tempParams)
  }
  if(result.code === 200) {
    catchOrder(
      {
        type: "submitTransaction",
        status: "SUCCEDED",
        uuid: uuid,
        data: params,
      },
      allParams
    );
  }
}

export const formatCatchType = (
  resultData: any,
  originParams: OriginParams
) => {
  const { type, status, uuid, data = {} } = resultData;
  let statusStr = "";
  let certHash = data.certHash || data.params?.certHash || data?.retHash || "";
  let orderInfo = {};
  let realyParams = {};
  if (type === "submitTransaction") {
    realyParams = data;
    if (["SIGN_START"].includes(status)) {
      // signing
      statusStr = "signing";
    }
    if (["SIGN_PENDING"].includes(status)) {
      // validating
      statusStr = "validating";
    }
    if (["SIGN_BLOCK_DONE"].includes(status)) {
      // executing
      statusStr = "executing";
    }
    if (["FAILED"].includes(status)) {
      statusStr = "executing";
    }
    if (["SUCCEDED"].includes(status)) {
      if(getStore('chainge.inviteCode')) {
        recordForInviteCode(certHash, data)
      }
      // pending
      statusStr = "pending";
    }
    if (
      [
        "SIGN_ERROR",
        "SIGN_REJECTED",
        "SIGN_BLOCK_FAILED",
        "SIGN_BLOCK_ERROR",
      ].includes(status)
    ) {
      statusStr = "delete";
    }
  }
  if (type === "orderInfo") {
    orderInfo = data;
    if (["PENDING", "FAILED"].includes(status)) {
      // pending
      statusStr = "pending";
    }
    if (["DONE"].includes(status)) {
      // successful
      statusStr = "successful";
    }
    if (["REFUND"].includes(status)) {
      // successful
      statusStr = "refunded";
    }
  }

  return {
    status: statusStr,
    uuid,
    realyParams: realyParams,
    originParams,
    certHash,
    orderInfo: orderInfo,
  };
};

export const catchOrder = (data: any, allParams: OriginParams) => {
  const catchData = formatCatchType(data, allParams);
  setOrderStore(catchData);
};

export const RECEIVERID = "chaingefinance.near";
export const allNearExecute = async (
  allParams: OriginParams,
  nearWalletInfo: CurWalletObj,
  callbackOptions: ExecuteCallBackType
) => {
  callbackOptions.lockCb(true)
  try {
    const uuid = uuidv4();
    const amount = ethers.utils.parseUnits(allParams.fromAmount, 6).toString();
    const params = {
      receiver_id: RECEIVERID,
      amount,
    };
    catchOrder(
      {
        type: "submitTransaction",
        status: "SIGN_START",
        uuid: uuid,
        data: {},
      },
      allParams
    );
    const userAddress = nearWalletInfo.userAddress;
    let txResult = null;
  
    await browserWalletCatch(uuid, allParams, callbackOptions, nearWalletInfo)
    try {
      txResult = await callMethod(nearWalletInfo.wallet, userAddress, {
        contractId: allParams.fromSymbol.address,
        method: "ft_transfer",
        args: params,
        deposit: "1",
        flagHash: true,
      });
    } catch (error) {
      catchOrder(
        {
          type: "submitTransaction",
          status: "SIGN_ERROR", // SIGN_REJECTED
          uuid: uuid,
          data: {},
        },
        allParams
      );
    }
    if (txResult.status && txResult?.status?.SuccessValue === "") {
      const certHash = txResult.transaction.hash;
      await coreExecuteForAllNear(allParams, certHash, userAddress, uuid, nearWalletInfo, callbackOptions)
    } else {
      catchOrder(
        {
          type: "submitTransaction",
          status: "SIGN_BLOCK_FAILED",
          uuid: uuid,
          data: {},
        },
        allParams
      );
    }
  } catch(error) {

  } finally {
    callbackOptions.lockCb(false)
  }
};

export const checkTaskInAllNear = async (
  certHash: string,
  uuid: string,
  allParams: OriginParams
) => {
  try {
    const params = {
      certHash: certHash,
    };
    let result = await requestHttp(
      "post",
      "/open/v1/order/checkProxyTask",
      params
    );
    result = result.data;
    if (result.code === 0) {
      const { retStatus } = result.data;
      if (retStatus === "Succeeded") {
        catchOrder(
          {
            type: "orderInfo",
            status: "DONE",
            uuid: uuid,
            data: result.data,
          },
          allParams
        );
      } else {
        // pending
        setTimeout(() => {
          checkTaskInAllNear(certHash, uuid, allParams);
        }, 5000);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const coreExecuteForAllNear = async (allParams: OriginParams, certHash: string, userAddress: string, uuid: string, nearWalletInfo: CurWalletObj, callbackOptions: ExecuteCallBackType) => {
  let publicKeyHex = await getPublickHexForNear(nearWalletInfo)
  const amount = ethers.utils.parseUnits(allParams.fromAmount, 6).toString();
  const toAmountStr = ethers.utils
    .parseUnits((+allParams.toAmount).toFixed(6), 6)
    .toString();
  const objParams = {
    certHash: certHash,
    fromChain: allParams.fromChain.name,
    fromAddress: userAddress,
    fromPublicKey: publicKeyHex,
    fromSymbol: allParams.fromSymbol.symbol,
    fromAmount: amount,
    toChain: allParams.toChain.name,
    toAddress: userAddress,
    toSymbol: allParams.toSymbol.symbol,
    toAmount: toAmountStr,
  };
  catchOrder(
    {
      type: "submitTransaction",
      status: "SIGN_PENDING",
      uuid: uuid,
      data: objParams,
    },
    allParams
  );

  const paramsJsonStr = JSON.stringify(objParams);
  const signerStr = await signMsgByNear(
    paramsJsonStr,
    userAddress,
    "mainnet",
    nearWalletInfo.wallet
  );
  const requestParams = {
    params: objParams,
    signature: signerStr,
  };
  catchOrder(
    {
      type: "submitTransaction",
      status: "SIGN_BLOCK_DONE",
      uuid: uuid,
      data: requestParams,
    },
    allParams
  );
  let result = await requestHttp(
    "post",
    "/open/v1/order/submitProxyTask",
    requestParams
  );
  callbackOptions.lockCb(false);
  callbackOptions.refreshBalance();
  result = result.data;
  if (result.code === 0) {
    const { reject, status, msg = "" } = result.data;
    if (reject) {
      // error
      if (msg) {
        //   showToast(msg, "error");
      }
    } else {
      catchOrder(
        {
          type: "submitTransaction",
          status: "SUCCEDED",
          uuid: uuid,
          data: requestParams,
        },
        allParams
      );
      if (status === 0) {
        // pending
      } else if (status === 2) {
        checkTaskInAllNear(requestParams.params.certHash, uuid, allParams);
      } else {
        // pending
      }
    }
  } else {
    catchOrder(
      {
        type: "submitTransaction",
        status: "FAILED",
        uuid: uuid,
        data: requestParams,
      },
      allParams
    );
  }
}

export const allNearForReSubmit = async (requestParams: any, uuid: string, allParams: OriginParams) => {
  let result = await requestHttp(
    "post",
    "/open/v1/order/submitProxyTask",
    requestParams
  );
  result = result.data;
  if (result.code === 0) {
    const { reject, status, msg = "" } = result.data;
    if (reject) {
      // error
      if (msg) {
        //   showToast(msg, "error");
      }
    } else {
      catchOrder(
        {
          type: "submitTransaction",
          status: "SUCCEDED",
          uuid: uuid,
          data: requestParams,
        },
        allParams
      );
    }
  }
}

const getPublickHexForNear = async(nearWalletInfo: CurWalletObj) => {
  const account = await nearWalletInfo?.wallet.getAccounts();
  const publicKey = account[0].publicKey;
  const b58Str = publicKey.split(":")[1];
  let publicKeyHex = b58ToHex(b58Str);
  return publicKeyHex
}

export const RECEIVERID_ONLY_NEAR =
  "00919c23dd720e2bfae3071c0bea441b006e732f94b3972759252b9351768e81";
export const onlyNearExecute = async (
  curProvider: any,
  allParams: OriginParams,
  nearWalletInfo: CurWalletObj,
  callbackOptions: ExecuteCallBackType
) => {
  if (allParams.fromChain.name === "NEAR") {
    callbackOptions.lockCb(true);
    try {
      const uuid = uuidv4();
      const amount = ethers.utils
        .parseUnits(allParams.fromAmount, 6)
        .toString();
      const params = {
        receiver_id: RECEIVERID_ONLY_NEAR,
        amount,
      };
      catchOrder(
        {
          type: "submitTransaction",
          status: "SIGN_START",
          uuid: uuid,
          data: {},
        },
        allParams
      );
      const userAddress = nearWalletInfo.userAddress;
      let txResult = null;
      
      await browserWalletCatch(uuid, allParams, callbackOptions, nearWalletInfo)

      try {
        txResult = await callMethod(nearWalletInfo.wallet, userAddress, {
          contractId: allParams.fromSymbol.address,
          method: "ft_transfer",
          args: params,
          deposit: "1",
          flagHash: true,
        });
      } catch (error) {
        catchOrder(
          {
            type: "submitTransaction",
            status: "SIGN_ERROR", // SIGN_REJECTED
            uuid: uuid,
            data: {},
          },
          allParams
        );
        throw error;
      }
      if (txResult.status && txResult?.status?.SuccessValue === "") {
        const certHash = txResult.transaction.hash;
        await coreExecuteForOnlyNear(allParams, certHash, userAddress, uuid, nearWalletInfo, callbackOptions)
      } else {
        catchOrder(
          {
            type: "submitTransaction",
            status: "SIGN_BLOCK_FAILED",
            uuid: uuid,
            data: {},
          },
          allParams
        );
      }
    } catch (error) {
    } finally {
      callbackOptions.lockCb(false);
    }
  } else {
    // EVM 签名
    callbackOptions.lockCb(true)
    try {
      const uuid = uuidv4();
      const provider = new ethers.providers.Web3Provider(
        curProvider
      );
      const signer = provider.getSigner();
      const params = {
        amount: allParams.fromAmount,
        chain: allParams.fromChain.name,
        evmAddress: allParams.evmAddress,
        fromAddress: allParams.evmAddress,
        token: allParams.fromSymbol.symbol,
      };
      const result = await requestHttp(
        "post",
        "/open/v1/order/getTransferToMinterRaw",
        params
      );
      if (result.code !== 200) {
        return;
      }
      let raw = result.data.raw;
      raw = raw.split("_")[0];
      const transactionRequest = chainge.decodeRaw(raw);
      catchOrder(
        {
          type: "submitTransaction",
          status: "SIGN_START",
          uuid: uuid,
          data: {},
        },
        allParams
      );
      let transaction;
      try {
        transaction = await signer.sendTransaction(transactionRequest as Raw);
      } catch (error) {
        catchOrder(
          {
            type: "submitTransaction",
            status: "SIGN_ERROR",
            uuid: uuid,
            data: {},
          },
          allParams
        );
        throw error;
      }
      catchOrder(
        {
          type: "submitTransaction",
          status: "SIGN_PENDING",
          uuid: uuid,
          data: {
            certHash: transaction.hash,
          },
        },
        allParams
      );
      try {
        const transactionReceipt = await transaction.wait();
        catchOrder(
          {
            type: "submitTransaction",
            status: "SIGN_BLOCK_DONE",
            uuid: uuid,
            data: {
              certHash: transaction.hash,
            },
          },
          allParams
        );
        if (!transactionReceipt) {
          catchOrder(
            {
              type: "submitTransaction",
              status: "SIGN_BLOCK_FAILED",
              uuid: uuid,
              data: {
                certHash: transaction.hash,
              },
            },
            allParams
          );
          return;
        }
      } catch (error) {
        catchOrder(
          {
            type: "submitTransaction",
            status: "SIGN_BLOCK_ERROR",
            uuid: uuid,
            data: {
              certHash: transaction.hash,
            },
          },
          allParams
        );
        throw error;
      }
      const amount = ethers.utils
        .parseUnits(allParams.fromAmount, allParams.fromSymbol.decimals)
        .toString();
      const certHash = transaction.hash;
      const objParams = {
        certHash: certHash,
        fromChain: allParams.fromChain.name,
        fromAddress: allParams.evmAddress,
        fromPublicKey: "",
        fromSymbol: allParams.fromSymbol.symbol,
        fromAmount: amount,
        toChain: allParams.toChain.name,
        toAddress: allParams.nearAddress,
        toSymbol: allParams.toSymbol.symbol,
        toAmount: "0",
      };
      const paramsJsonStr = JSON.stringify(objParams);
      const signerStr = await signMsgByEvm(paramsJsonStr, curProvider);

      const evmNearParams = {
        certHash: certHash,
        evmAddress: allParams.evmAddress,
        feeLevel: allParams.feeLevel,
        fromAddress: allParams.evmAddress,
        fromAmount: allParams.fromAmount,
        fromChain: allParams.fromChain.name,
        fromPublicKey: "",
        fromToken: allParams.fromSymbol.symbol,
        toAddress: allParams.nearAddress,
        toAmount: "0",
        toChain: allParams.toChain.name,
        toToken: allParams.toSymbol.symbol,
      };

      const requestParams = {
        params: evmNearParams,
        signature: signerStr.substring(2),
      };

      let submitResult = await requestHttp(
        "post",
        "/open/v1/order/submitOrderExtend",
        requestParams
      );
      
      callbackOptions.lockCb(false)
      callbackOptions.refreshBalance()
      if (submitResult.code === 200) {
        catchOrder(
          {
            type: "submitTransaction",
            status: "SUCCEDED",
            uuid: uuid,
            data: requestParams,
          },
          allParams
        );
        getOrderDetail(submitResult.data.sn, uuid, allParams);
      } else {
        catchOrder(
          {
            type: "submitTransaction",
            status: "FAILED",
            uuid: uuid,
            data: requestParams,
          },
          allParams
        );
      }
    } catch (error) {
    } finally {
      callbackOptions.lockCb(false)
    }
  }
};

export const coreExecuteForOnlyNear = async (allParams: OriginParams, certHash: string, userAddress: string, uuid: string, nearWalletInfo: CurWalletObj, callbackOptions: ExecuteCallBackType) => {
  let publicKeyHex = await getPublickHexForNear(nearWalletInfo)
  const amount = ethers.utils
    .parseUnits(allParams.fromAmount, 6)
    .toString();

  const objParams = {
    certHash: certHash,
    fromChain: allParams.fromChain.name,
    fromAddress: userAddress,
    fromPublicKey: publicKeyHex,
    fromSymbol: allParams.fromSymbol.symbol,
    fromAmount: amount,
    toChain: allParams.toChain.name,
    toAddress: allParams.evmAddress,
    toSymbol: allParams.toSymbol.symbol,
    toAmount: "0",
  };
  catchOrder(
    {
      type: "submitTransaction",
      status: "SIGN_PENDING",
      uuid: uuid,
      data: objParams,
    },
    allParams
  );

  const paramsJsonStr = JSON.stringify(objParams);
  const signerStr = await signMsgByNear(
    paramsJsonStr,
    userAddress,
    "mainnet",
    nearWalletInfo.wallet
  );
  const evmNearParams = {
    certHash: certHash,
    evmAddress: userAddress,
    feeLevel: allParams.feeLevel,
    fromAddress: userAddress,
    fromAmount: allParams.fromAmount,
    fromChain: allParams.fromChain.name,
    fromPublicKey: publicKeyHex,
    fromToken: allParams.fromSymbol.symbol,
    toAddress: allParams.evmAddress,
    toAmount: "0",
    toChain: allParams.toChain.name,
    toToken: allParams.toSymbol.symbol,
  };
  const requestParams = {
    params: evmNearParams,
    signature: signerStr,
  };
  catchOrder(
    {
      type: "submitTransaction",
      status: "SIGN_BLOCK_DONE",
      uuid: uuid,
      data: requestParams,
    },
    allParams
  );

  let result = await requestHttp(
    "post",
    "/open/v1/order/submitOrderExtend",
    requestParams
  );
  callbackOptions.lockCb(false);
  callbackOptions.refreshBalance();
  if (result.code === 200) {
    catchOrder(
      {
        type: "submitTransaction",
        status: "SUCCEDED",
        uuid: uuid,
        data: requestParams,
      },
      allParams
    );
    getOrderDetail(result.data.sn, uuid, allParams);
  } else {
    catchOrder(
      {
        type: "submitTransaction",
        status: "FAILED",
        uuid: uuid,
        data: requestParams,
      },
      allParams
    );
  }
}

export const onlyNearForReSubmit = async (requestParams: any, uuid: string, allParams: OriginParams) => {
  let result = await requestHttp(
    "post",
    "/open/v1/order/submitOrderExtend",
    requestParams
  );
  if (result.code === 200) {
    catchOrder(
      {
        type: "submitTransaction",
        status: "SUCCEDED",
        uuid: uuid,
        data: requestParams,
      },
      allParams
    );
  }
}

export const browserWalletCatch = async (uuid: string, allParams: OriginParams, callbackOptions: ExecuteCallBackType, nearWalletInfo: CurWalletObj) => {
  if(['my-near-wallet', 'near-wallet'].includes(nearWalletInfo.wallet.id)) {
    setStore('chainge.originParams', {uuid: uuid, allParams: allParams})
    callbackOptions.updateUrlQuery(uuid)
    await sleep(1000)
  }
}

const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const getOrderDetail = async (
  sn: string,
  uuid: string,
  allParams: OriginParams
) => {
  try {
    const { code, data, msg } = await chainge.getOrderDetail(sn);
    if (code === 200) {
      const orderInfo = (data as Data).order as Order;
      if (orderInfo.status === 2) {
        catchOrder(
          {
            type: "orderInfo",
            status: "DONE",
            uuid: uuid,
            data: (data as Data).order,
          },
          allParams
        );
      } else {
        if (orderInfo.status > 2) {
        } else if (orderInfo.status < 2) {
          setTimeout(() => {
            getOrderDetail(sn, uuid, allParams);
          }, 5000);
        }
      }
    } else {
    }
  } catch (error) {}
};


export const getOrderDetailForRefresh = async (certHash: any, evmAddress: string, uuid:string, allParams: OriginParams, isOShi = false) => {
  const tempParam: OrderDetailByHashAndEvmAddressParams = {
    chain: allParams.fromChain.name,
    evmAddress: evmAddress,
    hash: certHash
  }

  // oshi
  let result;
  if(isOShi) {
    updateConfigByOshi();
  } else {
    updateConfigByDefault();
  }

  result =  await chainge.getOrderDetailByHashAndEvmAddress(tempParam);
  const {code, data, msg} = result
  
  if(code === 200) {
    const orderInfo = (data as Data).order as Order;
      if (orderInfo.status === 2) {
        catchOrder(
          {
            type: "orderInfo",
            status: "DONE",
            uuid: uuid,
            data: (data as Data).order,
          },
          allParams
        );
        return true
      } else if(orderInfo.status === 6 || orderInfo.status === 4) {
        catchOrder(
          {
            type: "orderInfo",
            status: "REFUND",
            uuid: uuid,
            data: (data as Data).order,
          },
          allParams
        );
        return true
      }
      
      return false
  }
  return false
}


export const checkTaskInAllNearFroRefresh = async (
  certHash: string,
  uuid: string,
  allParams: OriginParams
) => {
  try {
    const params = {
      certHash: certHash,
    };
    let result = await requestHttp(
      "post",
      "/open/v1/order/checkProxyTask",
      params
    );
    result = result.data;
    if (result.code === 0) {
      const { retStatus } = result.data;
      if (retStatus === "Succeeded") {
        catchOrder(
          {
            type: "orderInfo",
            status: "DONE",
            uuid: uuid,
            data: result.data,
          },
          allParams
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
};