import { Chain, ExternalChainInfo, TokenVo } from "@chainge/sdk";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { DefObj } from "types/baseType";
import chainge from "utils/sdk";
import { getLikeChainStore, getLikeTokenStore, getStore, setLikeChainStore, setLikeTokenStore, setStore } from "utils/store";
import useDebounce from "./useDebounce";
const curChains = ["ETH", "BSC", "MATIC", "AVAX", "ARB", "OP", "CANTO", "BASE", "LINEA", "POLYGONZKEVM", "ZKSYNC"];
const curTokens = ["USDT", "USDC", "DAI", "ETH", "MATIC", "AVAX", "OP"]
const curTokensForBSC = ["USDT", "USDC", "DAI", "ETH", "MATIC"]

interface SystemProps {
  feeLevel: number;
  chains: Chain[];
  allTokens: TokenVo[];
  tokens: DefObj<string, TokenVo[]>;
  externalChainInfo: ExternalChainInfo[];
  chainForId: DefObj<number, Chain>;
  chainForName: DefObj<string, Chain>;
  appKey: string;
  sortTokens: DefObj<string, string[]>;
  likeChains: string[];
  likeTokens: string[];
  chosedChainId: number;
  theme: string;
  isLoadingForExec: boolean,


  isMobile: boolean,
  
  updateChosedChainId: (chainId: number) => void;
  updateLikeChains: (chainName: string, clearAll?: boolean) => void
  updateLikeTokens: (symbol: string, clearAll?: boolean) => void
  updateMobile: (isMobile: boolean) => void
  updateTheme: () => void;
  updateLoadingForExec: (loading: boolean) => void;
}

const SystemContext = createContext<SystemProps | null>(null);

export const SystemProvider = ({ children }: { children: ReactNode }) => {
  const feeLevel = 7;
  const [configTest] = useState(chainge.getConfig())
  const [appKey, setAppKey] = useState<string>("");
  const [chains, setChains] = useState<Chain[]>([]);
  const [externalChainInfo, setExternalChainInfo] = useState<
    ExternalChainInfo[]
  >([]);

  const [allTokens, setAllTokens] = useState<TokenVo[]>([]);
  const [tokens, setTokens] = useState<DefObj<string, TokenVo[]>>({});
  const [chainForId, setChainForId] = useState<DefObj<number, Chain>>({});
  const [chainForName, setChainForName] = useState<DefObj<string, Chain>>({});

  const [sortTokens, setSortTokens] = useState<DefObj<string, string[]>>({});

  const [likeChains, setLikeChains] = useState<string[]>([]);
  const [likeTokens, setLikeTokens] = useState<string[]>([]);

  const [chosedChainId, setChosedChainId] = useState(-1)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800 ? true : false)
  const [theme, setTheme] = useState('day')
  const [isLoadingForExec, setIsLoadingForExec] = useState(false)

  // Gets the configuration information for the chain
  const getExternalChainInfo = () => {
    let array: ExternalChainInfo[] = [];
    const config = chainge.getConfig();
    if (config.externalChainInfo && config.externalChainInfo.length) {
      array = config.externalChainInfo;
    }
    setExternalChainInfo(array);
  };

  // Get the support chain
  const getChains = () => {
    let array: Chain[] = [];
    const config = chainge.getConfig();
    setAppKey(config.appKey as string);
    if (config.chains && config.chains.length) {
      array = config.chains;
    }
    // 过滤chain
    array = array.filter(item => curChains.includes(item.name))

    updateChains(array);
  };

  // Update chain information
  const updateChains = (chains: Chain[]) => {
    setChains(chains);
    const formatResult = formatChain(chains);
    setChainForId(formatResult[0]);
    setChainForName(formatResult[1]);
  };

  // Get the support tokens
  const getTokens = () => {
    let objs: { [key: string]: TokenVo[] } = {};
    const config = chainge.getConfig();
    if (config.tokens) {
      // objs = config.tokens;
      // 过滤 tokens
      const tokens = config.tokens;
      objs['FSN'] = tokens['FSN']
      curChains.forEach(chainItem => {
        let chainTokens = tokens[chainItem] || []
        let targetTokens = curTokens
        if(chainItem === 'BSC') {
          targetTokens = curTokensForBSC
        }
        chainTokens = chainTokens.filter(tokenItem => targetTokens.includes(tokenItem.symbol))
        objs[chainItem] = chainTokens
      })
    }
    setTokens(objs);

    const tokensAry = Object.values(objs);
    const tempAllTokens: TokenVo[] = [];
    tokensAry.forEach((item) => {
      tempAllTokens.push(...item);
    });
    setAllTokens(tempAllTokens);
  };

  // Get the sort tokens 
  const getSortTokens = async () => {
    try {
      const config = chainge.getConfig();
      const result = await chainge.getTokenSortByKey(config.appKey as string);
      if (result.code === 200) {
        const sortObj = result.data as any;
        const keyAry = Object.keys(sortObj);
        const formatObj: { [key: string]: string[] } = {};
        keyAry.forEach((item) => {
          formatObj[item] = sortObj[item].split(",");
        });
        setSortTokens(formatObj);
      } else {
        setSortTokens({});
      }
    } catch (error) {
      setSortTokens({});
    }
  };

  // Format the supported chain with chainId and chainName as keys
  const formatChain = (chains: Chain[]) => {
    const tempChainForId: { [key: string]: any } = {};
    const tempChainForName: { [key: string]: any } = {};
    chains.forEach((item) => {
      tempChainForId[item.chainId] = item;
      tempChainForName[item.name] = item;
    });
    return [tempChainForId, tempChainForName];
  };

  // Format the supported tokens with chainName as keys
  const formatTokens = (tokens: TokenVo[]) => {
    const tempObjs: { [key: string]: TokenVo[] } = {};
    tokens.forEach((item) => {
      if (tempObjs[item.chain]) {
        tempObjs[item.chain].push(item);
      } else {
        tempObjs[item.chain] = [item];
      }
    });
    return tempObjs;
  };

  const refreshData = () => {
    chainge.getSupportChains()
    chainge.getSupportTokens()
    chainge.getAllExternalChainInfo()
  }

  // timer
  const [count, setCount] = useState(1);
  useEffect(() => {
    const timer = setInterval(() => {
      setCount(count + 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  // Obtain resource data
  useEffect(() => {
    if (count % (10 * 60) === 0) {
      refreshData()
    }
  }, [count]);

  useDebounce(() => {
    initData()
  }, 500, [configTest.tokens, configTest.chains, configTest.externalChainInfo])

  const initData = () => {
    getChains();
    getExternalChainInfo();
    getTokens();
  }

  // get the sort tokens 
  useEffect(() => {
    getSortTokens();
    initData();

    const likeChain = getLikeChainStore()
    setLikeChains(likeChain)
    const likeToken = getLikeTokenStore()
    setLikeTokens(likeToken)

    const curTheme = getStore('chainge.theme')
    if(curTheme) {
      setTheme(curTheme)
    }
  }, []);

  // update like data 
  const updateLikeChains = (chainName: string, clearAll: boolean = false) => {
    let tempNewList = [...likeChains]
    if(clearAll) {
      tempNewList = []
    } else {
      const index = tempNewList.findIndex(item => item === chainName)
      if(index > -1) {
        tempNewList.splice(index, 1)
      } else {
        tempNewList = [...tempNewList, chainName]
      }
    }
    setLikeChainStore(tempNewList)
    setLikeChains(tempNewList)
  }

  const updateLikeTokens = (symbol: string, clearAll: boolean = false) => {
    let tempNewList = [...likeTokens]
    if(clearAll) {
      tempNewList = []
    } else {
      const index = tempNewList.findIndex(item => item === symbol)
      if(index > -1) {
        tempNewList.splice(index, 1)
      } else {
        if(tempNewList.length >= 20) return;
        tempNewList = [...tempNewList, symbol]
      }
    }
    setLikeTokenStore(tempNewList)
    setLikeTokens(tempNewList)
  }


  const updateChosedChainId = (chainId: number) => {
    setChosedChainId(chainId)
  }

  const updateMobile = (isMobile: boolean) => {
    setIsMobile(isMobile)
  }

  const updateTheme = () => {
    let tempMode = 'day'
    if(theme === 'day') {
      setTheme('dark')
      tempMode = 'dark'
    } else {
      setTheme('day')
      tempMode = 'day'
    }
    setStore('chainge.theme', tempMode)
  }

  const updateLoadingForExec = (loading: boolean) => {
    setIsLoadingForExec(loading)
  }

  return (
    <SystemContext.Provider
      value={{
        allTokens,
        chains,
        tokens,
        externalChainInfo,
        chainForId,
        chainForName,
        sortTokens,
        appKey,
        feeLevel,
        likeChains,
        likeTokens,
        chosedChainId,
        isMobile,
        theme,
        isLoadingForExec,

        updateChosedChainId,
        updateLikeChains,
        updateLikeTokens,
        updateMobile,
        updateTheme,
        updateLoadingForExec
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};

export const useSystemInfo = () => {
  return (
    useContext(SystemContext) || {
      allTokens: [],
      chains: [],
      tokens: {},
      externalChainInfo: [],
      chainForId: {},
      chainForName: {},
      sortTokens: {},
      appKey: "",
      feeLevel: 7,
      likeChains: [] as string[],
      likeTokens: [] as string[],
      chosedChainId: -1,
      isMobile: false,
      theme: 'day',
      isLoadingForExec: false,

      updateChosedChainId: (chainId: number) => {},
      updateLikeChains: (name: string, clearAll: boolean = false) => {},
      updateLikeTokens: (name: string, clearAll: boolean = false) => {},
      updateMobile: (isMobile: boolean) => {},
      updateTheme: () => {},
      updateLoadingForExec: (loading: boolean) => {}
    } as SystemProps
  );
};
