import styled, { useTheme } from "styled-components";
import {
  BaseLayoutScreenSection,
  FlexCenter,
} from "style/preset";

import { ReactComponent as TgSvg } from "assets/svgs/icons-social-telegram.svg";
import { ReactComponent as TwitterSvg } from "assets/svgs/icons-social-twitter.svg";
import { ReactComponent as MediumSvg } from "assets/svgs/icons-social-medium.svg";

import DownloadAppBtn from "components/DownloadAppBtn";
import StyledContainer from "style/styledContainer";
const Footer = () => {
  const theme = useTheme()

  const openWinLink = (url: string) => {
    window.open(url, '_blank');
  }
  return (
    <FooterBox>
      <StyledContainer flex1="1" fontSize="12px" interRegular color={theme.deepGrey}>Version 1.0.0</StyledContainer>
      {/* <DownloadAppBtn></DownloadAppBtn> */}
      <FlexCenter flex1="1" justifyContent="flex-end !important;">
        <TgSvg onClick={() => openWinLink('https://t.me/Orchid_DEX_Aggregator')} fill={theme.basicBlack} style={{marginRight: '20px', cursor: 'pointer'}}></TgSvg>
        <TwitterSvg onClick={() => openWinLink('https://twitter.com/Orchid_Exchange')} fill={theme.basicBlack} style={{marginRight: '20px', cursor: 'pointer'}}></TwitterSvg>
        {/* <MediumSvg onClick={() => openWinLink('https://chainge-finance.medium.com/')} fill={theme.basicBlack} style={{cursor: 'pointer'}}></MediumSvg> */}
      </FlexCenter>
    </FooterBox>
  );
};

const FooterBox = styled(BaseLayoutScreenSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 33px;
  margin-top: 20px;
`;

export default Footer;