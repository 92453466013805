import { useState } from "react";
import StyledContainer from "style/styledContainer";
import styled from "styled-components";
import { ReactComponent as SearchSvg } from "assets/svgs/icons-actions-search.svg";

type Props = {
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchHeader = ({ onFilterChange }: Props) => {
  const [focus, setFocus] = useState(false);
  return (
    <StyledContainer position="relative" mt="18px" width="100%">
      <SearchSvgIcon focus={+focus}></SearchSvgIcon>
      <SearchInput
        placeholder="Search by asset name"
        onChange={onFilterChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      ></SearchInput>
    </StyledContainer>
  );
};

const SearchSvgIcon = styled(SearchSvg)<any>`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);
  fill: ${({ theme, focus }) => (focus ? theme.basicGreen : theme.basicBlack)};
`;

const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.basicGrey};
  border: 1px solid transparent;
  border-radius: 9px;

  width: 100%;
  height: 50px;

  font-size: 14px;
  font-family: InterRegular;
  color: ${({ theme }) => theme.basicBlack};
  padding: 0 15px;
  padding-left: 45px;
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => theme.basicBlack};
  }

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.basicGreen};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.basicGreen};
  }
`;

export default SearchHeader;
