import "./App.css";
import Routers from "../../router";
import { ThemeProvider } from "styled-components";
import { useSystemInfo } from "hooks/useSystemProvider";
import { darkTheme, defaultTheme } from "style/preset";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getStore, setStore } from "utils/store";
import { Toaster } from "react-hot-toast";

const App = () => {
  const { theme } = useSystemInfo();

  let [searchParams] = useSearchParams();
  useEffect(() => {
    let inviteCode = searchParams.get("invite_code");
    if(inviteCode) {
      const oldInviteCode = getStore('chainge.inviteCode');
      if(!oldInviteCode || oldInviteCode === inviteCode ) {
        setStore('chainge.inviteCode', inviteCode)
      }
    }
  }, [searchParams])
  
  return (
    <ThemeProvider
      theme={() => {
        return theme === "day" ? defaultTheme : darkTheme;
      }}
    >
      <Routers></Routers>
      <Toaster  toastOptions={{
        duration: 1000
      }}/>
    </ThemeProvider>
  );
};

export default App;
