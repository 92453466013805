import { useSystemInfo } from "hooks/useSystemProvider";
import { NumericFormat, OnValueChange } from "react-number-format";
import StyledContainer from "style/styledContainer";
import styled from "styled-components";
import { BaseProps } from "types/baseType";

type Props = {
  symbolName?: string;
  type?: string;
  value?: string;
  decimalScale?: number;
  disabled?: boolean;

  onValueChange?: OnValueChange
} & BaseProps;

const CInputNumber = ({ symbolName = "", type = "input", value= '', decimalScale = 4, disabled = false, onValueChange }: Props) => {
  const { isMobile } = useSystemInfo()
  return (
    <StyledContainer position="relative">
      {type === "input" ? (
        <NumberInput disabled={disabled} value={value} ismobile={+isMobile} placeholder="0.00" valueIsNumericString={true} thousandSeparator="," onValueChange={onValueChange} />
      ) : (
        <TextInput
          value={value}
          placeholder="0.00"
          thousandSeparator=","
          decimalScale={decimalScale}
          disabled
          ismobile={+isMobile}
        ></TextInput>
      )}
      {symbolName && <AssetToken isMobile={isMobile} right={type === "input" ? '10px': '0px'}>{symbolName}</AssetToken>}
    </StyledContainer>
  );
};

const baseInputStyle = `
    width: 100%;
    height: 60px;
    background-color: transparent;
    border-radius: 9px;
    padding: 0 15px;
    font-family: InterSemiBold;
    font-size: 26px;
    font-weight: 600;
    padding-right: 20%;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;

const baseInputStyleForMobile = `
    width: 100%;
    height: 54px;
    background-color: transparent;
    border-radius: 9px;
    padding: 0 15px;
    font-family: InterMedium;
    font-size: 22px;
    font-weight: 600;
    padding-right: 20%;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;

const NumberInput = styled(NumericFormat)<any>`
  ${({ismobile}) => ismobile ? baseInputStyleForMobile : baseInputStyle}
  border: solid 0.5px ${({theme}) => theme.inputBorder};
  color: ${({theme}) => theme.basicBlack};
  &:focus {
    outline: none;
    color: ${({theme}) => theme.basicGreen};
    border-color: ${({theme}) => theme.basicGreen};
  }
  ::placeholder {
    color: ${({theme}) => theme.inputPlaceholder};
  }
`;

const TextInput = styled(NumericFormat)<any>`
  ${({ismobile}) => ismobile ? baseInputStyleForMobile : baseInputStyle}
  border-color: transparent;
  padding-left: 0;
  font-size: ${({ismobile}) => ismobile ? '26px' : '40px'};
  &:disabled {
    color: ${({theme}) => theme.basicBlack};
    -webkit-text-fill-color: ${({theme}) => theme.basicBlack};
    opacity: 1;
  }
  ::placeholder {
    color: ${({theme}) => theme.inputPlaceholder};
  }
`;

const AssetToken = styled.span<any>`
  font-size: 12px;
  font-weight: 500;
  padding: 10px 9px;
  background-color: ${({theme}) => theme.basicGrey};
  border-radius: 15px;

  position: absolute;
  right: ${({right}) => right};
  top:  ${({isMobile}) => isMobile ? '11px' : '15px'};
  line-height: 1;
`;

export default CInputNumber;
