import { Route, Routes } from "react-router-dom"
import Home from "../pages/Home"
import BaseLayout from "../pages/Layouts/BaseLayout"
import Transactions from "../pages/Transactions"
// import Referral from "pages/Referral"

const Routers = () => {
    return <Routes>
        <Route path="" element={<BaseLayout></BaseLayout>}>
            <Route path="/" element={<Home></Home>}></Route>
            <Route path="/transactions" element={<Transactions></Transactions>}></Route>
            {/* <Route path="/referral" element={<Referral></Referral>}></Route> */}
        </Route>
    </Routes>
}

export default Routers