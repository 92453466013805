import {
  BaseLayoutScreenSection,
  FlexCenter,
  FlexItemsCenter,
} from "style/preset";
import styled, { useTheme } from "styled-components";

import { ReactComponent as Top01Svg } from "assets/svgs/icons-arrows-top-01.svg";
import { ReactComponent as PendingSvg } from "assets/svgs/icons-misc-pending.svg";
import { ReactComponent as IntoArrowSVG } from "assets/svgs/into-arrow.svg";
import { ReactComponent as CopySvg } from "assets/svgs/icon-02-actions-copy.svg";

import StyledText from "style/styledText";
import StyledContainer from "style/styledContainer";
import CImage from "components/CImage";
import ProgressPopupWin from "components/ProgressPopupWin";
import { useCallback, useEffect, useState } from "react";
import { shortStr } from "utils";
import { CatchType } from "types/baseType";
import { useOrder } from "hooks/useOrderProvider";
import CopyToClipboard from "react-copy-to-clipboard";
import StatusBar from "components/ProgressPopupWin/components/StatusBar";

const ProgressBar = () => {
  const theme = useTheme()
  const [progressOpen, setProgressOpen] = useState(false);
  const fnOpenChangeForProgress = (isOpen: boolean) => {
    setProgressOpen(isOpen);
  };
  const {pendingList} = useOrder()

  const getFirstPending = useCallback(() => {
    return pendingList[0] as CatchType
  }, [pendingList])

  const getAllParams = useCallback(() => {
    if(pendingList.length) {
      return getFirstPending().originParams
    } else {
      return null
    }
  }, [getFirstPending, pendingList.length])

  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  }, [isCopy]);
  return (
    <>
      {
        getAllParams() && <>
          <ProgressBarBox>
            <ProgressBarContent>
              <FlexItemsCenter>
                <FlexCenter mr="30px">
                  <ExecutingBox>
                    <PendingSvg fill={theme.progressLabel}></PendingSvg>
                    <ProgressLabel ml="8px">In progress</ProgressLabel>
                  </ExecutingBox>
                  <ExecutingNumberBox>1/{pendingList.length}</ExecutingNumberBox>
                </FlexCenter>
                <ExecutingDetailBox>
                  <CImage src={getAllParams()?.fromSymbol.url || ''} width="16px" height="16px"></CImage>
                  <StyledText ml="8px">{getAllParams()?.fromAmount} {getAllParams()?.fromSymbol.symbol} on {getAllParams()?.fromChain.name}</StyledText>
                  <IntoArrowSVGWrapper></IntoArrowSVGWrapper>
                  <CImage src={getAllParams()?.toSymbol.url || ''} width="16px" height="16px"></CImage>
                  <StyledText ml="8px">{getAllParams()?.toAmount} {getAllParams()?.toSymbol.symbol} on {getAllParams()?.toChain.name}</StyledText>
                  {
                    getFirstPending().certHash && <CopyToClipboard
                    text={getFirstPending()?.certHash || ""}
                    onCopy={() => setIsCopy(true)}
                  >
                    <HxBar ml="44px" mr="10px" pointer>
                      <StyledContainer fontSize="12px" interRegular mr="11px" width="100px">
                        {isCopy ? 'Copied' : shortStr(getFirstPending()?.certHash || '', 6)}
                      </StyledContainer>
                      <CopySvg fill={theme.basicBlack}></CopySvg>
                    </HxBar>
                  </CopyToClipboard>

                  }
                  

                  <StatusBar status={getFirstPending()?.status}></StatusBar>
                </ExecutingDetailBox>
              </FlexItemsCenter>
              <ExpandBtn pointer onClick={() => setProgressOpen(true)}>
                <span>Expand</span>
                <Top01Svg fill={theme.basicBlack} style={{ marginLeft: "8px" }}></Top01Svg>
              </ExpandBtn>
            </ProgressBarContent>
            {
              pendingList.length >= 2 && <SliderBar></SliderBar>
            }
          </ProgressBarBox>
          <ProgressPopupWin
            orderList={pendingList}
            open={progressOpen}
            onOpenChange={fnOpenChangeForProgress}
          ></ProgressPopupWin>
        </>
      }
    </>
  );
};

const GrayBgStyle = `
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
`;

const FontStyle = `
  font-size: 12px;
  font-weight: 600;
  font-family: InterSemiBold;
`;

const ProgressBarBox = styled.div`
  background-color: ${({theme}) => theme.basicWhite};
  height: 100px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 25px 0;
  box-sizing: border-box;
  z-index: 10;
`;

const ProgressBarContent = styled(BaseLayoutScreenSection)`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExecutingBox = styled(FlexItemsCenter)`
  background-color: ${({theme}) => theme.progressBg};
  padding: 9px 10px;
  border-radius: 6px 0 0 6px;
`;

const ProgressLabel = styled(StyledText)`
  ${FontStyle};
  color: ${({theme}) => theme.progressLabel};
`

const ExecutingNumberBox = styled(StyledContainer)`
  background-color: ${({theme}) => theme.progressNumBg};
  padding: 11px 10px;
  ${FontStyle}
  border-radius: 0 6px 6px 0;
  color: ${({theme}) => theme.progressLabel};
`;

const ExecutingDetailBox = styled(FlexItemsCenter)`
  ${GrayBgStyle}
  padding: 14px 15px;
  box-shadow: 0 2px 4px 0 ${({theme}) => theme.porgressShadow};
  background-color: ${({theme}) => theme.basicWhite};
  border-radius: 9px;
  ${({theme}) => theme.cardBorder};
`;

const HxBar = styled(FlexItemsCenter)`
  background-color: ${({theme}) => theme.basicGrey};
  padding: 7px 10px;
  border-radius: 6px;
`;

const ExpandBtn = styled(FlexItemsCenter)`
  ${GrayBgStyle}
  padding: 5px 5px 5px 11px;
`;

const SliderBar = styled(StyledContainer)`
  position: absolute;
  height: 1px;
  background-color: ${({theme}) => theme.basicGreen};
  top: -5px;
  left: 0;
  width: 100%;

  animation: changeWidth 7s infinite;
  @keyframes changeWidth {
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }
`;

const IntoArrowSVGWrapper = styled(IntoArrowSVG)`
  width: 26px;
  height: 26px;
  margin: 0 20px;

  ${({ theme }) =>
    theme.mode === "day"
      ? `g {
    fill: none;
    circle {
      fill: #FFF;
      stroke: #95A3BE;
    }
    path {
      fill: #95A3BE;
    }
  }`
      : `g {
    fill: #1f1f1f;
    circle {
      fill: #1f1f1f;
      stroke: #1f1f1f;
    }
    path {
      fill: #ebebeb;
    }
  }`}
`;

export default ProgressBar;
