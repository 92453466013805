import styled, { css } from "styled-components";

interface StyledProps {
   interBlank?: boolean
   interBold?: boolean
   interExtraBold?: boolean
   interExtraLight?: boolean
   interMedium?: boolean
   interRegular?: boolean
   interSemiBold?: boolean
   interThin?: boolean
   fontFamily?: string

   fontWeight1?: boolean
   fontWeight2?: boolean
   fontWeight3?: boolean
   fontWeight4?: boolean
   fontWeight5?: boolean
   fontWeight6?: boolean
   fontWeight7?: boolean
   fontWeight8?: boolean
   fontWeight9?: boolean
   bold?: boolean
   fontWeight?: string | number

   fontSize?: string
   background?: string
   backgroundColor?: string
   filter?: string
   mt?: string
   mb?: string
   ml?: string
   mr?: string
   pt?: string
   pb?: string
   pl?: string
   pr?: string
   p?: string
   m?: string
   boxShadow?: string
   nowrap?: boolean
   lineHeight?: string | number
   border?: boolean
   borderBottom?: boolean
   borderTop?: string
   borderLeft?: string
   borderRadius?: string
   textAlign?: string
   pointer?: boolean
   display?: string
   flex1?: string
   textTransform?: string
   hover?: string
}

const StyledText = styled.span<StyledProps>`
      ${({ fontFamily }) => fontFamily ? css`font-family: ${fontFamily as string};` : `font-family: InterMedium;`}
      ${({ interBlank }) => interBlank ? css`font-family: InterBlank;` : null}
      ${({ interBold }) => interBold ? css`font-family: InterBold;` : null}
      ${({ interExtraBold }) => interExtraBold ? css`font-family: InterExtraBold;` : null}
      ${({ interExtraLight }) => interExtraLight ? css`font-family: InterExtraLight;` : null}
      ${({ interMedium }) => interMedium ? css`font-family: InterMedium;` : null}
      ${({ interRegular }) => interRegular ? css`font-family: InterRegular;` : null}
      ${({ interSemiBold }) => interSemiBold ? css`font-family: InterSemiBold;` : null}
      ${({ interThin }) => interThin ? css`font-family: InterThin;` : null}

      ${({ fontWeight1 }) => fontWeight1 ? css`font-weight: 100;` : null}
      ${({ fontWeight2 }) => fontWeight2 ? css`font-weight: 200;` : null}
      ${({ fontWeight3 }) => fontWeight3 ? css`font-weight: 300;` : null}
      ${({ fontWeight4 }) => fontWeight4 ? css`font-weight: 400;` : null}
      ${({ fontWeight5 }) => fontWeight5 ? css`font-weight: 500;` : null}
      ${({ fontWeight6 }) => fontWeight6 ? css`font-weight: 600;` : null}
      ${({ fontWeight7 }) => fontWeight7 ? css`font-weight: 700;` : null}
      ${({ fontWeight8 }) => fontWeight8 ? css`font-weight: 800;` : null}
      ${({ fontWeight9 }) => fontWeight9 ? css`font-weight: 900;` : null}
      ${({ bold }) => bold ? css`font-weight: bold;` : null} 
      ${({ fontWeight }) => fontWeight ? css`font-weight: ${fontWeight};` : null}
      ${({ pointer }) => pointer ? css`cursor: pointer;` : null} 

      ${({ fontSize }) => fontSize ? css`font-size: ${fontSize};` : null} 
      ${({ background }) => background ? css`background: ${background};` : null} 
      ${({ backgroundColor }) => backgroundColor ? css`background-color: ${backgroundColor};` : null} 
      ${({ filter }) => filter ? css`filter: ${filter};` : null} 
      ${({ mt }) => mt ? css`margin-top: ${mt};` : null} 
      ${({ mb }) => mb ? css`margin-bottom: ${mb};` : null} 
      ${({ ml }) => ml ? css`margin-left: ${ml};` : null} 
      ${({ mr }) => mr ? css`margin-right: ${mr};` : null} 
      ${({ pt }) => pt ? css`padding-top: ${pt};` : null} 
      ${({ pb }) => pb ? css`padding-bottom: ${pb};` : null} 
      ${({ pl }) => pl ? css`padding-left: ${pl};` : null} 
      ${({ pr }) => pr ? css`padding-right: ${pr};` : null} 
      ${({ boxShadow }) => boxShadow ? css`box-shadow: ${boxShadow};` : null} 
      ${({ nowrap }) => nowrap ? css`white-space: nowrap;text-overflow: ellipsis; overflow: hidden;` : null} 
      ${({ color, theme }) => color ? css`color: ${color};` : `color: ${theme.defaultContentColor};`} 
      ${({ lineHeight }) => lineHeight ? css`line-height: ${lineHeight};` : null} 
      ${({ border }) => border ? css`border: 1px solid #ced6e7;` : null} 
      ${({ borderBottom }) => borderBottom ? css`border-bottom: 1px solid #ced6e7;` : null} 
      ${({ borderTop }) => borderTop ? css`border-top: ${borderTop};` : null} 
      ${({ borderLeft }) => borderLeft ? css`border-left: ${borderLeft};` : null} 
      ${({ borderRadius }) => borderRadius ? css`border-radius: ${borderRadius};` : null} 
      ${({ textAlign }) => textAlign ? css`text-align: ${textAlign};` : null} 
      ${({ m }) => m ? css`margin: ${m};` : null} 
      ${({ p }) => p ? css`padding: ${p};` : null} 
      ${({flex1}) => flex1 ? css`flex: ${flex1};` : null}
      ${({ display }) => display ? css`display: ${display};` : null} 
      ${({ textTransform }) => textTransform ? css`text-transform: ${textTransform};` : null} 
     ${({ hover }) => hover ? css`
     :hover{
        ${hover}
     };
     ` : null} 
`
export default StyledText;